
<template>
    <AppPermission :permission="this.$permissions.returns.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>
                            {{$t('message.headers.returns')}}
                        </span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                                <div class="col-8 text-title">
                                    <label for="">{{$t('message.common.numberRecord')}}:</label>
                                </div>
                                <div class="col-4">
                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{$t('message.common.branch')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <q-select v-model="filterQuery.branches" 
                                    :modelValue="filterQuery.branches" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.common.placeholder.branch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                                    <!-- <SelectSearch :placeholder="$t('message.common.placeholder.branch')" :modelValue="filterQuery.branches" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branches'"/> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.common.time')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>

                    <!-- <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.common.status')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.listStatus" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{status.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.common.created_by')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('creatorBody', 'creatorDown', 'creatorUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="creatorDown"></i>
                                        <i class="fa fa-chevron-up" id="creatorUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="creatorBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.created_by')" :modelValue="filterQuery.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.InvoiceIndex.tabs.table.history_return.receive_by')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('orderBody', 'orderDown', 'orderUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="orderDown"></i>
                                        <i class="fa fa-chevron-up" id="orderUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="orderBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.ReturnIndex.selectReturnBy')" :modelValue="filterQuery.receiverBy" :defaultOption="this.receiverBy.all" @update:modelValue="doFilterSearch" :label="'receiverBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">{{$t('message.headers.sale_channels')}}</label>
                                </div>
                                <!-- <div class="col-1" @click="showModalAddInfoSaleChannels()">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon">
                                        <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                    </a>
                                </div> -->
                                <AppPermission :permission="this.$permissions.saleChannels.create?? '' ">
                                    <div class="col-1" @click="showModalAddInfoSaleChannels()">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon">
                                            <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                        </a>
                                    </div>
                                </AppPermission> 
                                <div class="col-2 pl-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('saleChannelBody', 'saleChannelDown', 'saleChannelUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="saleChannelDown"></i>
                                        <i class="fa fa-chevron-up" id="saleChannelUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="saleChannelBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.saleChannel')" :modelValue="filterQuery.saleChannel" :defaultOption="this.saleChannel.all" @update:modelValue="doFilterSearch" :label="'saleChannel'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">Loại phiếu</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('orderBody', 'orderDown', 'orderUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="orderDown"></i>
                                        <i class="fa fa-chevron-up" id="orderUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="orderBody">
                            <div class="row" v-for="tR in this.listTypeReturn" :key="tR.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{tR.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('type', tR.id)" :checked="isExistInArrayChecked(tR, this.filterQuery.type)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!-- right -->
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.InputQuickSearch.placeholder.returns_code_return_search')" @filterSearch="changeQuickSearch" :returnsSearch="true" />
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter mr-1"></i>
                                    {{$t('message.button.removeFilter')}}
                                </button>
                                <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v px-2"></i>
                                        {{$t('message.button.action')}}
                                    </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <AppPermission :permission="this.$permissions.returns.export?? '' ">
                                                <a class="dropdown-item" @click="exSelectOverview()">
                                                    <i class="fa fa-share" ></i>
                                                    {{$t('message.button.export_all')}}
                                                </a>
                                                <a class="dropdown-item"  @click="exSelectDetails()">
                                                    <i class="fas fa-file-export"></i>
                                                    {{$t('message.button.export_detail')}}
                                                </a>
                                            </AppPermission>
                                            <a class="dropdown-item"  @click="printMultiple()">
                                                <i class="fas fa-file-export"></i>
                                                {{$t('message.button.print.name')}}
                                            </a>
                                            <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                                <i class="fa fa-share"></i>
                                                {{ $t('message.button.sendMailText') }}
                                            </a>
                                            <a class="dropdown-item" @click="sendZalo()">
                                                <i class="fa fa-share"></i>
                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                            </a>
                                    </div>
                                </div>
                                 <AppPermission :permission="this.$permissions.returns.create?? '' ">
                                    <button class="btn btn-save ml-1" @click="createReturns()">
                                        <i class="fa fa-plus px-1"></i>
                                        {{$t('message.InvoiceIndex.button.return')}}
                                    </button>
                                </AppPermission>
                                <AppPermission :permission="this.$permissions.returns.export?? '' ">
                                    <div class="dropdown">                                        
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-file-export px-1"></i>
                                            {{$t('message.button.export.name')}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <!-- <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-sign-in-alt mr-1"></i>Import</a> -->
                                            <a class="dropdown-item" href="javascript:void(0);" @click="exportOverview()">
                                                <i class="fas fa-file-export mr-1"></i>
                                                {{$t('message.button.export_all')}}
                                            </a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="exportDetail()">
                                                <i class="fas fa-external-link-alt mr-1"></i>
                                                {{$t('message.button.export_detail')}}
                                            </a>
                                        </div>
                                    </div>
                                </AppPermission>
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="returns"></SelectField>
                                </div> 
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped lstProduct" v-show="isShow()">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table" v-if="fields.length > 0"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                                    <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :class="entry.id === this.returnActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailReturn(entry.id, entry)">
                                                                    <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="returnActive === entry.id" :class="[ entry.id === returnActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                           <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2"  @click="showContent(0)">
                                                                    <span :class=" this.tabActive == this.$constant.tabInformation ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                                        {{ $t('message.InvoiceIndex.tabs.info') }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-2 mt-2" @click="showContent(1)" v-if="(entry.cash_book && entry.cash_book.length > 0)  || (entry.bank_book && entry.bank_book.length > 0)" >                                                      
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryPayment ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                                        {{ $t('message.InvoiceIndex.tabs.history_payment') }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Hiển thị tab Thông tin -->
                                                     <template v-if="this.tabActive == this.$constant.tabInformation">
                                                        <!-- Chi tiết phiếu trả hàng -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="returnActive === entry.id">
                                                            <td></td>
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="mt-2 pb-4 detail__user">
                                                                    <div class="row mt-2 detail-prd-row">
                                                                        <div class="col-4">
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{ $t('message.InvoiceIndex.tabs.table.history_return.code') }}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">                                                            
                                                                                    <b>{{ entry.code }}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5 lableOS">
                                                                                    {{$t('message.common.time')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                                    {{ dt(entry.returnDate) }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.headers.customers')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    {{ entry.customerName }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.PriceListDetailIndex.priceList')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    {{  entry.priceListName }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.InvoiceIndex.code')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    <a :href="'/invoices/index?code=' + entry.invoiceName" target="_blank" class="text-decoration-none text-primary">{{ entry.invoiceName }}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="mx-1">
                                                                                <div class="col-12">
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5 lableOS">
                                                                                            {{$t('message.common.status')}}:
                                                                                        </div>
                                                                                        <div class="col-7 ow-break-word">
                                                                                            {{ entry.status }}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.common.branch')}}:
                                                                                        </div>
                                                                                        <div class="col-7 ow-break-word">
                                                                                            {{ entry.branchName }}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1" :class="entry.statusOrigin == this.$constant.status_complete_return ? 'pt-2 pb-1' : 'mt-2 py-2'">
                                                                                        <div class="col-5 d-flex align-items-center">
                                                                                            {{$t('message.InvoiceIndex.tabs.table.history_return.receive_by')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">
                                                                                            <InputSelectSearch :defaultValue="$t('message.ReturnIndex.selectReturnBy')" :modelValue="entry.receivedById" :defaultOption="users" @update:modelValue="setReceiverBy" v-if="entry.statusOrigin == this.$constant.status_complete_return"></InputSelectSearch>
                                                                                            <span v-else>{{ entry.receiverName }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            Mã phiếu chi:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word"> 
                                                                                                                                                            
                                                                                        </div>
                                                                                    </div> -->
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.headers.sale_channels')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">
                                                                                            {{entry.saleChannelName}}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.common.created_by')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">                                                                    
                                                                                            {{entry.createdByName}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4" id="noteRO">  
                                                                            <div class="row p-0 font-italic">
                                                                                <div class="col-12" v-if="entry.statusOrigin == this.$constant.status_complete_return">
                                                                                    <textarea v-model="entry.description" :id="'note_'+ entry.id" :placeholder="$t('message.InvoiceIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                </div>
                                                                                <div class="col-12 text-gray" v-else>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                    <span class="noteStyle px-4 text-gray font-italic">
                                                                                        {{ entry.description ? entry.description : $t('message.InvoiceIndex.note')}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- Chi tiet san pham va gia -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="returnActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="mt-2 pb-4 detail__user">
                                                                    <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                                        <div class="col-12 pl-0">
                                                                            <table class="w-100">
                                                                                <thead>
                                                                                    <tr class="backgroup__detail">
                                                                                        <template v-for="fieldDetail in this.fieldInvoiceDetail" :key="fieldDetail">
                                                                                            <!-- HsCode   -->
                                                                                            <th scope="col" class="pl-4"  v-if="fieldDetail.field =='hsCode' && entry.isHsCode == this.$constant.hsCodeShow" :style="fieldDetail.style ? fieldDetail.style : ''" v-text="$t(fieldDetail.name)" ></th>
                                                                                            <!-- Thuế suất --> 
                                                                                            <th scope="col" class="pl-4"  v-else-if="fieldDetail.field =='taxRate' && entry.isTaxRate == this.$constant.taxRateShow" :style="fieldDetail.style ? fieldDetail.style : ''">
                                                                                                <span>{{fieldDetail.name? $t(fieldDetail.name) : ''}}</span>
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4"  v-text="$t(fieldDetail.name)" v-else-if="fieldDetail.selected" :style="fieldDetail.style ? fieldDetail.style : ''"></th>
                                                                                        </template>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="bg-white">
                                                                                        <th scope="col" class="pl-4"> 
                                                                                            <input type="text" v-model="this.defaultReturn.searchCode" @input="onInputFilterReturnDetail('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                                        </th>
                                                                                        <th scope="col" class="pl-4"> 
                                                                                            <input type="text" v-model="this.defaultReturn.searchName" @input="onInputFilterReturnDetail('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                                        </th>
                                                                                        <th scope="col" class="pl-4" :colspan="fieldInvoiceDetail.length - 2"> 
                                                                                        </th>
                                                                                    </tr>
                                                                                    <template v-if="this.defaultReturn.filter?.length > 0">
                                                                                        <template v-for="(returnDetail, index) in this.defaultReturn.filter" :key="returnDetail.id">
                                                                                            <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                                                <tr :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                                                    <td class="pl-4">
                                                                                                        <div class="row m-0 d-flex">
                                                                                                            <div class="col-1 p-0">
                                                                                                                <i class="fa fa-chevron-down" v-if="returnDetail.children_combo && returnDetail.children_combo.length > 0 && !returnDetail.showCombo" @click="showCombo(returnDetail)"></i>
                                                                                                                <i class="fa fa-chevron-up" v-if="returnDetail.children_combo && returnDetail.children_combo.length > 0 && returnDetail.showCombo" @click="showCombo(returnDetail)"></i>
                                                                                                            </div>
                                                                                                            <div class="col-10 p-0">
                                                                                                                <a :href="'/products/index?code=' + returnDetail.productCode" target="_blank" class="text-decoration-none text-primary">{{returnDetail.productCode}}</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td class="pl-4">
                                                                                                        <span v-text="returnDetail.productName"></span>
                                                                                                        <!-- <template v-if="returnDetail.consignments">
                                                                                                            <div v-for="(value, index) in returnDetail.consignments" :key="index" class="mr-1 mt-1 mb-2">
                                                                                                                <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                                            </div>
                                                                                                        </template> -->
                                                                                                        <span class="description font-italic text-gray" v-text="returnDetail.note" :title="returnDetail.note"></span>
                                                                                                    </td>
                                                                                                    <td v-text="returnDetail.unitName" class="text-center"></td>
                                                                                                    <td v-text="returnDetail.attributeName" class="text-center"></td>
                                                                                                    <!-- Hiển thị taxRate HsCode -->
                                                                                                    <td v-if="entry.isHsCode == this.$constant.hsCodeShow" v-text="returnDetail.hsCode" class="text-center"></td>
                                                                                                    <td v-if="entry.isTaxRate == this.$constant.taxRateShow" class="text-center">
                                                                                                        <span>{{returnDetail.taxRate}}%</span>
                                                                                                    </td>

                                                                                                    <td class="pl-4 text-right">{{n(returnDetail.quantity)}}</td>
                                                                                                    <td v-text="n(returnDetail.price)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(returnDetail.priceReturn)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(returnDetail.subTotal)" class="pl-4 text-right"></td>
                                                                                                </tr>
                                                                                                <template v-if="returnDetail.consignments">
                                                                                                    <tr class="bg-white" v-for="(value, csmIndex) in returnDetail.consignments" :key="csmIndex">
                                                                                                        <td class="pl-4"></td>
                                                                                                        <td class="pl-4">
                                                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                                        </td>
                                                                                                        <td class="pl-4"></td>
                                                                                                        <td class="pl-4"></td>
                                                                                                        <td :colspan="4"></td>
                                                                                                    </tr>
                                                                                                </template>
                                                                                                <template v-if="returnDetail.children_combo && returnDetail.children_combo.length > 0 && returnDetail.showCombo">
                                                                                                    <template v-for="(productInCombo, key) in returnDetail.children_combo" :key="key">
                                                                                                        <tr class="backgroup__detail">
                                                                                                            <td class="pl-4">
                                                                                                                <div class="row m-0 d-flex">
                                                                                                                    <div class="col-1 p-0">
                                                                                                                    </div>
                                                                                                                    <div class="col-10 p-0 pl-2">
                                                                                                                        <span v-text="(key + 1) + '. '"></span>
                                                                                                                        <a :href="'/products/index?code=' + productInCombo.product.code" target="_blank" class="text-decoration-none text-primary">{{productInCombo.product.code}}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td class="pl-4">{{ productInCombo.product.name }}</td>
                                                                                                            <td class="text-center">{{ productInCombo.unit.unitName }}</td>
                                                                                                            <td class="pl-4 text-right">{{ productInCombo.quantity }}</td>
                                                                                                            <td :colspan="4"></td>
                                                                                                        </tr>
                                                                                                        <template v-if="productInCombo.consignments">
                                                                                                            <tr class="backgroup__detail" v-for="(return_detail_consignments, keyDetail) in productInCombo.consignments" :key="keyDetail">
                                                                                                                <td class="pl-4"></td>
                                                                                                                <td class="pl-4">
                                                                                                                    <span class="consignment_name mr-2 text-white">{{ return_detail_consignments.name + ' - ' + d(return_detail_consignments.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + return_detail_consignments.quantity}}</span>
                                                                                                                </td>
                                                                                                                <td class="pl-4"></td>
                                                                                                                <td class="pl-4"></td>
                                                                                                                <td :colspan="4"></td>
                                                                                                            </tr>
                                                                                                        </template>
                                                                                                    </template>
                                                                                                </template>
                                                                                            </template>

                                                                                        </template>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <tr class="text-center bg-light">
                                                                                            <td :colspan="fieldInvoiceDetail.length">
                                                                                                {{$t('message.common.notFound')}}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </template>
                                                                                </tbody>
                                                                            </table>
                                                                            <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-4 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.total_quantity') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.totalProduct)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{$t('message.ReturnIndex.returnTotal')}}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.returnTotal)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{$t('message.ReturnIndex.returnDiscount')}}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.returnDiscount)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{$t('message.ReturnIndex.returnFee')}}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.returnFee)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{$t('message.ReturnIndex.returnCustomer')}}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.returnCustomer)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{$t('message.ReturnIndex.totalPayment')}}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.totalPayment)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                                        <button type="button" class="btn btn-save mx-1" @click="sendZalo(entry)">
                                                                            <i class="fa fa-share"></i>
                                                                            {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                                                        </button>
                                                                        <!-- <button type="button" class="btn btn-save mx-1" v-if="entry.statusOrigin == this.$constant.status_complete_return && entry.branchId == currentBranchId">
                                                                            <i class="fa fa-share"></i>
                                                                            Cập nhật
                                                                        </button> -->
                                                                        <AppPermission :permission="this.$permissions.returns.update?? '' ">
                                                                        <button type="button" class="btn btn-save mx-1" @click="quickSave(entry)" v-if="entry.statusOrigin == this.$constant.status_complete_return && entry.branchId == currentBranchId">
                                                                            <i class="fa fa-save"></i>
                                                                            {{$t('message.button.save')}}
                                                                        </button>
                                                                        </AppPermission>
                                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="getTemplatePrint(entry)">
                                                                            <i class="fa fa-print" aria-hidden="true"></i>
                                                                            {{$t('message.button.print.name')}}
                                                                        </button>
                                                                        <AppPermission :permission="this.$permissions.returns.export?? '' ">
                                                                            <button type="button" class="btn btn-secondary text-white mx-1" @click="exportRecord(entry.id)">
                                                                                <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                                {{$t('message.common.export')}}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <!-- <button type="button" class="btn btn-save mx-1">
                                                                            <i class="fa fa-copy" aria-hidden="true"></i>
                                                                            Sao chép
                                                                        </button> -->
                                                                        <!-- <button type="button" class="btn btn-danger mx-1" v-if="entry.statusOrigin == this.$constant.status_complete_return && entry.branchId == currentBranchId">
                                                                            <i class="fa fa-times"></i>
                                                                            Hủy bỏ
                                                                        </button> -->
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Hiển thị tab Lịch sử thanh toán -->
                                                     <template v-if="this.tabActive == this.$constant.tabHistoryPayment">                                                    
                                                        <tr class="detail-row bg-white border__body-detail" v-if="returnActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 pr-4 detail-prd-row" v-if="entries.length > 0">
                                                                    <div class="col-12">
                                                                        <table class="w-100">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.InvoiceIndex.tabs.table.history_payment.code')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table pl-3">
                                                                                        {{$t('message.common.time')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.common.created_by')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.InvoiceIndex.tabs.table.history_payment.method')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.common.status')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table text-center">
                                                                                        {{$t('message.OrderSupplierIndex.tabs.table.history_payment.total_price')}}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                           <tbody> 
                                                                                <template v-if="entry.historyPayment">                                                                                                                                       
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.historyPayment" :key="index">                                                                 
                                                                                        <td scope="col" class="checkbox-table">                                                                                            
                                                                                            <a href="#" class="text-decoration-none text-primary" @click.stop="showModalDataPayment(history.id, history.type, this.$constant.typeReturn)">
                                                                                                <span>{{history?.code ? history.code : ''}}</span>
                                                                                            </a>
                                                                                        </td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdAt ? dt(history.createdAt) : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdByName ? history.createdByName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.methodName ? history.methodName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table text-center"><span>{{history?.payment ? n(history.payment) : ''}}</span></td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="6" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <tr class="detail-row bg-white border__footer-detail" v-if="returnActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @updateContent="updateContent"/>
        <PreviewTemplate ref="PreviewTemplate" :editorDataClone="this.editorDataClone"/>
        <ModalDataPayment ref="ModalDataPayment"  @updated="this.load()" />
        <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
        <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
    </AppPermission>
</template>

<script>
    import {$alert, $get, $post, clone, setArray, getSearchQuery, explode, removeVietnameseTones, numberFormat} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import http from '@/services/api';
    import TableSortField from "@/components/TableSortField";
    import CreateReturn from "@/views/returns/CreateReturn";
    import emitter from "@/lib/emitter";
    import store from "../../store";
    import SelectSearch from "@/components/SelectSearch.vue";
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import router from "@/router/index";
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import SelectField from "../../components/SelectField";    
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';    
    import AppPermission from '@/components/AppPermission.vue';
    import ModalDataPayment from '@/components/ModalDataPayment.vue';    
    import moment from 'moment';
    import PreviewTemplate from '@/components/printTemplate/PreviewTemplate.vue';
    // import printTransactionMixin from "@/printTransactionMixin";
    import printOrdersMixin from '@/printOrdersMixin';
    import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
    import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
    import { mapGetters } from "vuex";
    import QSelect from "@/components/QSelect";
    import exportMixin from '@/exportMixin';
    export default {
        name: "ReturnIndex",
        mixins: [printOrdersMixin, exportMixin],
        components: {
            CreateReturn, 
            TableSortField, 
            Pagination, 
            SelectSearch, 
            SelectDateTime, 
            InputQuickSearch, 
            InputSelectSearch, 
            ModalAddInfoSaleChannels, 
            SelectField,
            ModalSelectPrintTemplate,
            PreviewTemplate,
            AppPermission,
            ModalDataPayment,
            ModalSelectSendMailAndZaloTemplate,
            ModalSelectSendZaloGroupTemplate,
            QSelect,
        },
        mounted() {
            document.title = this.$t('message.headers.returns') + ' | OmiPos';
            const fields = localStorage.getItem('returnsSelectedFields_' + this.user.id);
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            const query = clone(this.$route.query);
            if(query['code'] != null){
                this.filterQuery['returns_code'] = query['code'];
                this.loadDetail();
            }else{
                this.clearFilter();
            }
        },
        data() {
            const query = getSearchQuery();            
            const globalConfig = store.state.GlobalConfig;
            return {
                tabActive: this.$constant.tabInformation,
                exEntries:[],
                filter: {
                    product_code_name: '',
                    code_name_phone: '',
                    code_invoice: '',
                    note: '',
                    note_product: '',
                    returns_code: '',
                    delivery_code: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth ? store.state.Auth.user : {},
                numberRecord: 15,
                filterQuery: {
                    status: [],
                    type: [],
                    branches: [],
                    createdBy: '',
                    receiverBy: '',
                    saleChannel: '',
                    paymentMethod: '',
                    priceList: '',
                },
                currentBranchId: store.state.CurrentBranch.id,
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                receiverBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                createdBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                priceLists: [],
                saleChannel: {
                    filter: globalConfig.saleChannels,
                    all: globalConfig.saleChannels,
                    search: null,
                },
                users: globalConfig.users,
                arrayChecked: [],
                loading: false,
                returnActive: null,
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                defaultReturn:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                },
                fieldInvoiceDetail : [
                    { name: 'message.DetailOrdersCard.prodCode', field: 'code', selected: true, },
                    { name: 'message.DetailOrdersCard.prodName', field: 'code', selected: true, },
                    { name: 'message.DetailOrdersCard.dvt', field: 'unitName', selected: true, },
                    { name: 'message.DetailOrdersCard.attribute', field: 'attributeName', selected: true, },
                    { name: 'message.ModalProductFormTabMain.hs_code', field: 'hsCode', },
                    { name: 'message.ModalProductFormTabMain.tax_rate', field: 'taxRate', },     
                    { name: 'message.DetailOrdersCard.prodQuantity', field: 'quantity', style: 'text-align: right !important; ', selected: true, },
                    { name: 'message.ProductIndex.price', field: 'price', style: 'text-align: right !important; ', selected: true, },
                    { name: 'message.ReturnIndex.reImportPrice', field: 'returnPrice', style: 'text-align: right !important; ', selected: true, },
                    { name: 'message.DetailOrdersCard.totalPrice', field: 'subTotal', style: 'text-align: right !important; ', selected: true, },
                ],
                listStatus: [],
                listTypeReturn: [],
                receiverByEdit: null,
                headerActive: null,
                isFilter: false,
            }
        },
        methods: {
            async sendMailAndZalo() {
                this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabReturns, this.$constant.typeSellProducts, this.arrayChecked);
            },
            async sendZalo (entry = null) {
                if (entry) {
                    this.arrayChecked = [entry.id];
                }
                const typeSend = entry ? this.$constant.typeSendZaloZNS : this.$constant.typeSendZaloGroup;
                const phone = entry && entry.customer ? (entry.customer.contactNumber ?? entry.customer.contactNumber1) : null;
                this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabReturns, this.$constant.typeSellProducts, this.arrayChecked, typeSend, phone, true);
            },
            showModalDataPayment(id, method, input){
             this.$refs.ModalDataPayment.show(id, method, input);
            },   
            showContent(tab){            
                switch (tab) {
                    case 0:                    
                        this.tabActive = this.$constant.tabInformation;
                        break;
                    case 1:                    
                        this.tabActive = this.$constant.tabHistoryPayment;                    
                        break;   
                }   
            },             
            async getTemplatePrint(entry) {  
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: 'TabReturns'});               
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            showModalAddInfoSaleChannels(){
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            async printMultiple(ids = null) {
                this.editorDataClone = '';
                const res = await $post('/v1/returns/data', {
                    ids: ids ?? this.arrayChecked,
                });
                if (res.result && res.result.length) {
                    await this.getTemplatePrint(res.result);
                }
            },
            async print(entry, status = true) { 
                entry.typePrint = this.$constant.TypePrintReturn;
                let dataPrint = this.dataPrint(entry,status);  
                this.editorDataClone = await this.renderData(dataPrint, entry.typePrint);
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
            },
            
            async updateContent(data){
                this.content = data.content;
                let status = this.content.includes('{Lo}');
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {
                    await this.startPrintMultiple(data.dataPrint);
                } else {
                    await this.print(data.dataPrint, status);          
                }           
            },
            async startPrintMultiple(returns) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < returns.length; index++) {
                    let order = returns[index];
                    order.typePrint = this.$constant.TypePrintReturn;
                    let dataPrint = await this.dataPrint(order);
                    let orderItem = await this.renderData(dataPrint, order.typePrint);
                    if (index != returns.length -1 ) {
                        this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + orderItem;
                    }
                }
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone); 
            },

            async clearFilter() {
                await this.$router.replace({
                    path: '/returns/index',                                        
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'type'){
                        this.filterQuery[key] = [0];
                    }else if(key == 'export'){
                        this.filterQuery[key] = false;
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branches = [this.currentBranchId];
                this.returnActive = null;
                this.isFilter = false;
                await this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            detailReturn(id, entry){
                if (id === this.returnActive) {
                    this.returnActive = null;
                } else {
                    this.returnActive = id;
                }
                this.headerActive = 'info';
                this.defaultReturn.filter = this.defaultReturn.all = entry.details;
                this.setPaginate();
                this.tabActive = this.$constant.tabInformation;
            },
            setPaginate(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.defaultReturn.filter.length / 10);
                this.paginateDetail.totalRecord = this.defaultReturn.filter.length;
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                }else if(field == 'type'){
                    if(query['type'].includes(value)){
                        query['type'] = query['type'].filter(item => item !== value)
                    }else{
                        query['type'].push(value)
                    }
                }else{
                    query[field] = value;
                }
                this.isFilter = true;
                query['page'] = 1;
                this.filterQuery = {...query};
                await this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();


            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/returns/data', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});
                
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }

                this.exEntries = [];  
                    // Bổ sung lịch sử thanh toán                   
                
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                // this.paginate = res.result.data.paginate;
                // this.entries = res.result.data.entries;
                this.entries = res.result.data.data.data;
                this.entries.forEach((entry) => {
                        if(entry.historyPayment){
                            entry.historyPayment.sort(function(a, b) {                                
                                return new Date(b.createdAt) - new Date(a.createdAt) 
                        });                                              
                    }
                    else { entry.historyPayment = null;}
                });
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                if (res.result.data.listStatus) {
                    this.listStatus = res.result.data.listStatus;
                }
                if (res.result.data.listTypeReturn) {
                    this.listTypeReturn = res.result.data.listTypeReturn;
                }
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async loadDetail(){
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branches = [this.currentBranchId];
                this.returnActive = null;
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailReturn(entry.id, entry);                    
                });
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },          
            setReceiverBy(receivedById){
                this.receiverByEdit = receivedById;
            },
            async quickSave(entry){
                if(entry.description?.length > 255){
                    $alert({
                        code: 422,
                        message: this.$t('message.CashBookIndex.alerts.validate_error_note'),
                    });
                }else{
                    const res = await $post('/v1/returns/quick-save', {
                        id: entry.id, 
                        description: entry.description, 
                        receivedById: this.receiverByEdit != null ? this.receiverByEdit : entry.receivedById,
                    });
                    if(res.result.status == true){
                        $alert({
                            code: 200,
                            message: this.$t('message.ReturnIndex.alertUpdateSuccess', {code: entry.code}),
                        });
                        this.load();
                    }else{
                        $alert({
                            code: 403,
                            message: this.$t('message.ReturnIndex.alertUpdateError', {code: entry.code}),
                        });
                    }
                }
            },
            async exSelectOverview() {
                emitter.emit("onLoading");                    
                let id = this.arrayChecked;  
                let branchId = store.state.CurrentBranch.id;                                
                const res = await http.download('/v1/returns/export-overview', { fields: this.fields, id: id, branch: branchId });
                this.returnDataExport(res);        
            },  
            async exSelectDetails() {
                emitter.emit("onLoading");
                let id = this.arrayChecked;
                let branchId = store.state.CurrentBranch.id;           
                const result = await http.download('/v1/returns/export-detail', { fields: this.fields, id: id, branch: branchId });
                this.returnDataExport(result);       
            },
            async exportOverview() {
                emitter.emit("onLoading");
                let branchId = store.state.CurrentBranch.id;
                const res = await http.download('/v1/returns/export-overview', { 
                    fields: this.fields, 
                    // id: id, 
                    branch: branchId,
                    ...this.filterQuery
                });
                this.returnDataExport(res);
            },
            async exportDetail() {
                // emitter.emit("onLoading");
                let branchId = store.state.CurrentBranch.id;
                // const result = await http.download('/v1/returns/export-detail', { 
                //     fields: this.fields, 
                //     // id: id, 
                //     branch: branchId,
                //     ...this.filterQuery
                // });

                await $post('/v1/returns/export-detail', {
                    fields: this.fields,
                    // id: id, 
                    branch: branchId,
                    ...this.filterQuery
                });


                emitter.emit('showModalExport');
                // this.returnDataExport(result);
            },
            async exportRecord(id) {
                emitter.emit("onLoading");
                const res = await http.download('/v1/returns/export-record/' + id, { ...this.fields });
                this.returnDataExport(res);
            },
            getOSByID(entries) {
                // let id = [];
                // entries.forEach(item => {
                //     id.push(item.id);
                // });
                // return id;

                return entries;
            },
            async createReturns(){
                await this.$router.replace({
                    path: '/sale/create-order',
                    query: {
                        type: 'Return',
                    }
                });
            },
            onInputFilterReturnDetail(field){
                this.paginateDetail.currentPage = 1;
                if(this.defaultReturn.searchCode.length == 0 && this.defaultReturn.searchName.length == 0){
                    this.defaultReturn.filter = this.defaultReturn.all;
                    this.setPaginate();
                    return;
                }
                let arr = [];
                switch (field) {
                    case 'searchCode':
                        for (let index = 0; index < this.defaultReturn.all.length; index++) {
                            if (this.defaultReturn.all[index] != null && removeVietnameseTones(this.defaultReturn.all[index].productCode.toLowerCase()).indexOf(removeVietnameseTones(this.defaultReturn.searchCode.toLowerCase())) > -1){
                                arr.push(this.defaultReturn.all[index]);
                            }
                        }
                        break;
                    case 'searchName':
                        for (let index = 0; index < this.defaultReturn.all.length; index++) {
                            if (this.defaultReturn.all[index] != null && removeVietnameseTones(this.defaultReturn.all[index].productName.toLowerCase()).indexOf(removeVietnameseTones(this.defaultReturn.searchName.toLowerCase())) > -1){
                                arr.push(this.defaultReturn.all[index]);
                            }
                        }
                        break;
                }
                this.defaultReturn.filter = arr;
                this.setPaginate();
            },
            async updateFilterBranch(values) {
                this.filterQuery.branches = [...values];
                this.filterQuery.page = 1;
                this.isFilter = true;
                await this.load();
            }
        },
        computed: {
            ...mapGetters({
                statusEmail: 'settingStore/email',
            }),
        },

    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
</style>