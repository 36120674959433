<template>
     <AppPermission :permission="this.$permissions.purchaseOrders.data?? '' ">     
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                    <span>{{ $t('message.PurchaseOrderIndex.title') }}</span>
                    </div>
                    <div>
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row">
                        <div class="col-8 text-title">
                            <label>{{ $t('message.common.numberRecord') }}:</label>
                        </div>
                        <div class="col-4">
                            <select :value="numberRecord" style="border: none;outline: none;" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
                    <!-- Chi nhánh -->
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PurchaseOrderIndex.filter.branch') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <!-- <SelectSearch :placeholder="$t('message.PurchaseOrderIndex.filter.placeholder.branch')" :modelValue="filter.branches" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branches'"></SelectSearch> -->
                                    <q-select v-model="filter.branches" 
                                        :modelValue="filter.branches" 
                                        :default-options="this.branches.all" 
                                        :multiple="true" 
                                        :placeholder="$t('message.PurchaseOrderIndex.filter.placeholder.branch')" 
                                        :isFilter="true"
                                        @update:modelValue="updateFilterBranch"
                                        class="border-bottom-1"/>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Thời gian -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-12">
                                    <label class="typeTitle">{{ $t('message.PurchaseOrderIndex.filter.time') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="time">
                            <div class="row mb-2">
                                <div class="col-1 p-0 d-flex align-items-center">
                                    <label class="container-radio ml-2 min-vw-100">
                                        <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios1" :value="0" class="mr-2" @click="refreshDatePicker(0)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-11">
                                    <DateRangePicker :formatType="'YYYY-MM-DD'" @picker="filterTime"></DateRangePicker>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-1 p-0 d-flex align-items-center">
                                    <label class="container-radio ml-2 min-vw-100">
                                        <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios2" :value="1" class="mr-2" @click="refreshDatePicker(1)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-11">
                                    <InputDateRangePicker :sortTimeHeight="true" :defaultDate="true" :placeholder="$t('message.SelectDateTime.otherOption')" class="w-100 mb-0 custom-input"
                                    @input="filterTime(filter.createdPicker, 1)" v-model="filter.createdPicker"/>
                                    <!-- <i class="far fa-calendar fa-md icon-calendar" aria-hidden="true"></i> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Trạng thái -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PurchaseOrderIndex.filter.status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.statusList" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{$t(status.name)}}
                                    <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :id="`key${status.id}`" :checked="isExistInArrayChecked(status, this.filter.status)">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>

                    <!-- Trạng thái hóa đơn-->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PurchaseOrderIndex.filter.status_invoice') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusInvoiceBody', 'statusInvoiceDown', 'statusInvoiceUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusInvoiceDown"></i>
                                        <i class="fa fa-chevron-up" id="statusInvoiceUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusInvoiceBody">
                            <div class="row" v-for="status in this.statusInvoiceList" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{$t(status.name)}}
                                    <input type="checkbox" class="chkType" @click="doFilter('statusInvoice', status.id)" :id="`keyStatus${status.id}`" :checked="isExistInArrayChecked(status, this.filter.statusInvoice)">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>

                    <!-- Người tạo -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PurchaseOrderIndex.filter.created_by') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('creatorBody', 'creatorDown', 'creatorUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="creatorDown"></i>
                                        <i class="fa fa-chevron-up" id="creatorUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="creatorBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.PurchaseOrderIndex.filter.placeholder.created_by')" :modelValue="filter.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Người nhập -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PurchaseOrderIndex.filter.import_by') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('inputBody', 'inputDown', 'inputUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="inputDown"></i>
                                        <i class="fa fa-chevron-up" id="inputUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="inputBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.PurchaseOrderIndex.filter.placeholder.import_by')" :modelValue="filter.purchaseBy" :defaultOption="this.purchaseBy.all" @update:modelValue="doFilterSearch" :label="'purchaseBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.PurchaseOrderIndex.filter.placeholder.search')" @filterSearch="changeQuickSearch" :purchaseOrderSearch="true"></InputQuickSearch>
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v px-2"></i>{{$t('message.PurchaseOrderIndex.button.action')}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" @click="printMultiple(this.arrayChecked)">
                                            <i class="fa fa-print"></i>
                                            {{$t('message.PurchaseOrderIndex.button.print')}}
                                        </a>
                                        <AppPermission :permission="this.$permissions.purchaseOrders.export?? '' ">
                                            <a class="dropdown-item" @click="exSelectOverview()">
                                                <i class="fa fa-share"></i>
                                                {{$t('message.PurchaseOrderIndex.button.export_all')}}
                                            </a>
                                            <a class="dropdown-item" @click="exSelectDetails()">
                                                <i class="fas fa-file-export"></i>
                                                {{$t('message.PurchaseOrderIndex.button.export_detail')}}
                                            </a>
                                        </AppPermission>
                                        <!-- <a class="dropdown-item" @click="receiveInvoiceSupplier(this.arrayChecked)">
                                            <i class="fas fa-file"></i>
                                            {{$t('message.PurchaseOrderIndex.button.import_invoice_supplier')}}
                                        </a> -->
                                        <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.button.sendMailText') }}
                                        </a>

                                        <a class="dropdown-item" @click="sendZalo()" v-if="this.statusEmail">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                        </a>
                                    </div>
                                </div>
                                <AppPermission :permission="this.$permissions.purchaseOrders.create?? '' ">
                                    <button @click="showModalPurchaseOrderCreateUpdate()" class="btn btn-save ml-1"><i class="fa fa-plus"/> {{$t('message.PurchaseOrderIndex.button.add')}}</button>                        
                                </AppPermission>
                                  <AppPermission :permission="this.$permissions.purchaseOrders.export?? '' ">
                                    <div class="dropdown">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-file-export px-2"></i>
                                            {{$t('message.PurchaseOrderIndex.button.export')}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" href="#" @click="exOverview()">{{$t('message.PurchaseOrderIndex.button.file_all')}}</a>
                                            <a class="dropdown-item" href="javascript:;" @click="exDetails()">{{$t('message.PurchaseOrderIndex.button.file_detail')}}</a>
                                        </div>
                                    </div>
                                </AppPermission>
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="purchaseOrder"></SelectField>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                <a href="javascript:;" @click="removeChecked()">
                                    <i class="fa fa-times text-danger" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="row mx-0 my-2" v-if="entries.length > 0" id="divTable">
                            <table class="table table-index table-striped w-100" >
                                <thead>
                                    <tr>
                                        <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                        <template v-for="field in fields" :key="field.field">                                    
                                            <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(entry, index) in entries" :key="entry.id" >
                                        <tr class="master-row" :class="entry.id === this.purchaseOrderDetail ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                            <td> <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                        :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)"></td>
                                            <template v-for="field in fields" :key="field.field">
                                                <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                                    <a :href="'#row_' + index" class="description text-dark tagA" @click="detailPurchaseOrder(entry.id, entry)">
                                                    <div v-if="field.field==='totalQuantity'" :style="field.style" :class="field.class"><span v-text="n(entry.totalQuantity)"></span></div>
                                                    <div v-else-if="field.field==='totalProduct'" :style="field.style" :class="field.class"><span v-text="n(entry.totalProduct)"></span></div>
                                                    <div v-else-if="field.field==='paymentForSupplier'" :style="field.style" :class="field.class"><span v-text="n(entry.paymentForSupplier)"></span></div>
                                                    <div v-else-if="field.field==='priceDiscount'" :style="field.style" :class="field.class"><span v-text="n(entry.priceDiscount)"></span></div>
                                                    <div v-else-if="field.field==='totalPrice'" :style="field.style" :class="field.class"><span v-text="n(entry.totalPrice)"></span></div>
                                                    <div v-else-if="field.field==='returnSupplier'" :style="field.style" :class="field.class"><span v-text="n(entry.returnSupplier)"></span></div>
                                                    <div v-else-if="field.field==='created_at'" :style="field.style" :class="field.class"><span v-text="dt(entry.created_at)"></span></div>
                                                    <div v-else-if="field.field==='updated_at'" :style="field.style" :class="field.class"><span v-text="dt(entry.updated_at)"></span></div>
                                                    <div v-else-if="field.field==='importDate'" :style="field.style" :class="field.class"><span v-text="dt(entry.importDate)"></span></div>
                                                    <div v-else-if="field.field==='userId'" :style="field.style" :class="field.class"><span v-text="entry.userName"></span></div>
                                                    <div v-else-if="field.field==='note'" :style="field.style" :class="field.class" class="description" :title="entry.note"><span v-text="entry.note"></span></div>
                                                    <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class">
                                                    </div>
                                                    </a>
                                                </td>
                                            </template>
                                        </tr>
                                        <tr v-if="purchaseOrderDetail === entry.id" :class="[ entry.id === purchaseOrderDetail ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                            <td></td>
                                            <td :colspan="fields.length" class="pb-0">
                                                <div class="row detail-prd-row">
                                                    <div class="col-2 mt-2"  @click="showContent(this.$constant.tabInformation)">
                                                        <span :class=" this.tabActive == this.$constant.tabInformation ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                            {{$t('message.PurchaseOrderIndex.tabs.info')}}
                                                        </span>
                                                    </div>
                                                    <!-- v-if="entry.statusId !== this.$constant.STATUS_TEMPORARY" -->
                                                    <div class="col-2 mt-2" @click="showContent(this.$constant.tabHistoryPayment)" v-if="(entry.cash_book && entry.cash_book.length > 0)  || (entry.bank_book && entry.bank_book.length > 0)">
                                                        <span :class=" this.tabActive == this.$constant.tabHistoryPayment ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                            {{$t('message.PurchaseOrderIndex.tabs.history_payment')}}
                                                        </span>
                                                    </div>
                                                    <div class="col-2 mt-2" @click="showContent(this.$constant.tabHistoryReturn)" v-if="(entry.return_imports && entry.return_imports.length > 0)">
                                                        <span :class=" this.tabActive == this.$constant.tabHistoryReturn ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                            {{$t('message.PurchaseOrderIndex.tabs.history_return')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>  

                                        <!-- Hiển thị tab thông tin -->
                                        <template v-if="this.tabActive == this.$constant.tabInformation">
                                            <!-- Thông tin chi tiết -->                                                                                
                                            <tr class="detail-row bg-white border__body-detail" v-if="purchaseOrderDetail === entry.id">
                                                <td></td>
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4 detail__user">
                                                        <div class="row mt-2 detail-prd-row">
                                                            <div class="col-4">
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.PurchaseOrderIndex.code')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">                                                            
                                                                        {{ entry.code }}
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 py-1">
                                                                    <div class="col-5 d-flex align-items-center">
                                                                        {{$t('message.PurchaseOrderIndex.time')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word d-flex align-items-center">
                                                                        <InputDateTimePicker :modelValue="entry.dateTimePicker" @update:modelValue="changeDateTimePicker" v-if="entry.statusId !== this.$constant.statusPurchaseCancelled"/>
                                                                        <span class="py-2" v-else>
                                                                            {{dt(entry.dateTimePicker)}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.PurchaseOrderIndex.supplier')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        <a :href="'/suppliers/index?keyword=' + (entry.supplier ? entry.supplier.code : '')" target="_blank" class="text-decoration-none text-primary" v-if="entry.supplier">{{ (entry.supplier ? entry.supplier.code : '') }}</a>
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.PurchaseOrderIndex.created_by')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ entry.createBy }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class="mx-1">
                                                                    <div class="col-12">
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 d-flex align-items-center">
                                                                                {{$t('message.PurchaseOrderIndex.order_code')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                <a :href="'/order-suppliers/index?order_supplier=' + entry.orderForSupplierId" target="_blank" class="text-decoration-none text-primary">{{ entry.orderForSupplierId }}</a>
                                                                                <!-- {{ entry.orderForSupplierId }}                                                                                                                                  -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 d-flex align-items-center">
                                                                                {{$t('message.PurchaseOrderIndex.status')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                {{ entry.status }}                                                                                                                                 
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.PurchaseOrderIndex.branch')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                {{ entry.branchId }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.PurchaseOrderIndex.import_by')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                <InputSelectSearch :defaultValue="$t('message.PurchaseOrderIndex.filter.placeholder.import_by')" :modelValue="entry.userId" :defaultOption="this.purchaseBy.all" @update:modelValue="resetValue"
                                                                                v-if="entry.statusId !== this.$constant.statusPurchaseCancelled"></InputSelectSearch>
                                                                                <template v-else>
                                                                                    <span v-for="n in this.purchaseBy.all" :key="n.id">
                                                                                        {{ n.id == entry.userId ? n.name : '' }}
                                                                                    </span> 
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4" :id="entry.statusId == this.$constant.statusPurchaseCancelled ? 'notePO' : ''">
                                                                <textarea v-model="entry.note" :id="'note_'+ entry.id" :placeholder="$t('message.PurchaseOrderIndex.note')" class="noteStyle px-4 text-gray font-italic" 
                                                                v-if="entry.statusId !== this.$constant.statusPurchaseCancelled">                                                            
                                                                </textarea>
                                                                <span class="px-4 text-gray font-italic" v-else style="color:#6c757d" >
                                                                {{ entry.note ? entry.note : $t('message.PurchaseOrderIndex.note')}}
                                                                </span>
                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Hiển thị thông tin sản phẩm -->
                                            <tr class="detail-row bg-white border__body-detail" v-if="purchaseOrderDetail === entry.id">
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4 detail__user">
                                                        <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                            <div class="col-12 pl-0">
                                                                <table class="w-100">
                                                                    <thead>
                                                                        <tr class="backgroup__detail">
                                                                            <th scope="col" class="pl-4" v-for="fieldDetail in this.fieldOrderSupplierDetail" :key="fieldDetail" v-text="$t(fieldDetail.name)"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr class="bg-white">
                                                                            <th scope="col" class="pl-4"> 
                                                                                <input type="text" v-model="this.defaultPurchaseOrder.searchCode" @input="onInputFilterDetailOrderSupplier('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                            </th>
                                                                            <th scope="col" :colspan="fieldOrderSupplierDetail.length - 1" class="pl-4"> 
                                                                                <input type="text" v-model="this.defaultPurchaseOrder.searchName" @input="onInputFilterDetailOrderSupplier('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                            </th>
                                                                        </tr>
                                                                        <template v-if="this.defaultPurchaseOrder.filter?.length > 0">
                                                                            <template v-for="(purchaseOrderDetail, indexDetail) in this.defaultPurchaseOrder.filter" :key="purchaseOrderDetail.id">
                                                                                <template v-if="indexDetail >= (this.paginateDetail.currentPage - 1)*10 && indexDetail < this.paginateDetail.currentPage*10">
                                                                                    <tr :class="indexDetail % 2 ? 'bg-white' : 'bg-light'">
                                                                                        <td class="pl-4">
                                                                                            <a :href="'/products/index?code=' + purchaseOrderDetail.product?.code" target="_blank" class="text-decoration-none text-primary">{{purchaseOrderDetail.product?.code}}</a>
                                                                                        </td>
                                                                                        <td class="pl-4">
                                                                                            <span v-text="purchaseOrderDetail.product?.name"></span>
                                                                                            <span class="col-12 description font-italic text-gray" v-text="purchaseOrderDetail.note" :title="purchaseOrderDetail.note"></span>
                                                                                        </td>
                                                                                        <td v-text="purchaseOrderDetail.unit ? purchaseOrderDetail.unit.unitName : ''" class="pl-4 text-left"></td>
                                                                                        <td v-text="purchaseOrderDetail?.attributeName ? purchaseOrderDetail.attributeName : '' " class="pl-4"></td>
                                                                                        <td v-text="n(purchaseOrderDetail.quantity)" class="pl-4"></td>
                                                                                        <td v-text="n(purchaseOrderDetail.price)" class="pl-4"></td>
                                                                                        <td v-text="n(purchaseOrderDetail.discount / purchaseOrderDetail.quantity)" class="pl-4"></td>
                                                                                        <td v-text="n(purchaseOrderDetail.price - (purchaseOrderDetail.discount / purchaseOrderDetail.quantity))" class="pl-4"></td>
                                                                                        <td v-text="n(purchaseOrderDetail.subTotal)" class="pl-4"></td>
                                                                                    </tr>
                                                                                    <template v-if="purchaseOrderDetail.consignments">
                                                                                        <tr class="bg-white" v-for="(value, indexConsignment) in purchaseOrderDetail.consignments" :key="indexConsignment">
                                                                                            <td class="pl-4"></td>
                                                                                            <td class="pl-4">
                                                                                                <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                            </td>
                                                                                            <td class="pl-4"></td>
                                                                                            <td :colspan="6"></td>
                                                                                        </tr>
                                                                                    </template>
                                                                                    <!-- Plus -->
                                                                                    <template v-if="purchaseOrderDetail.plus && purchaseOrderDetail.plus?.length > 0">
                                                                                        <template v-for="(plusItem, indexPlus) in purchaseOrderDetail.plus" :key="indexPlus">
                                                                                            <tr :class="indexPlus % 2 ? 'bg-white' : 'bg-light'">
                                                                                                <td class="pl-4">
                                                                                                    <a :href="'/products/index?code=' + plusItem.product?.code" target="_blank" class="text-decoration-none text-primary">{{plusItem.product?.code}}</a>
                                                                                                </td>
        
                                                                                                <td class="pl-4">
                                                                                                    <span v-text="plusItem.product?.name"></span>
                                                                                                    <span class="col-12 description font-italic text-gray" v-text="plusItem.note" :title="plusItem.note"></span>
                                                                                                </td>
                                                                                                <td v-text="plusItem.unit ? plusItem.unit.unitName : ''" class="pl-4 text-left"></td>
                                                                                                <td v-text="plusItem?.attributeName ? plusItem.attributeName : '' " class="pl-4"></td>
                                                                                                <td v-text="n(plusItem.quantity)" class="pl-4"></td>
                                                                                                <td v-text="n(plusItem.price)" class="pl-4"></td>
                                                                                                <td v-text="n(plusItem.discount / plusItem.quantity)" class="pl-4"></td>
                                                                                                <td v-text="n(plusItem.price - (plusItem.discount / plusItem.quantity))" class="pl-4"></td>
                                                                                                <td v-text="n(plusItem.subTotal)" class="pl-4"></td>
                                                                                            </tr>
                                                                                            <template v-if="plusItem.consignments">
                                                                                                <tr class="bg-white" v-for="(value, indexConsignment) in plusItem.consignments" :key="indexConsignment">
                                                                                                    <td class="pl-4"></td>
                                                                                                    <td class="pl-4">
                                                                                                        <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                                    </td>
                                                                                                    <td class="pl-4"></td>
                                                                                                    <td :colspan="6"></td>
                                                                                                </tr>
                                                                                            </template>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                            </template>
                                                                        </template>

                                                                        <template v-else>
                                                                            <tr class="text-center bg-light">
                                                                                <td :colspan="fieldOrderSupplierDetail.length"> {{$t('message.common.notFound')}}</td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                                <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                            </div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-4 pr-4">
                                                            <div class="col-10 flex-end">{{ $t('message.PurchaseOrderIndex.total_quantity') }}:</div>
                                                            <div class="col-2 flex-end">{{n(entry.totalQuantity ?? 0)}}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4">
                                                            <div class="col-10 flex-end">{{ $t('message.PurchaseOrderIndex.total_products') }}:</div>
                                                            <div class="col-2 flex-end">{{n(entry.totalProduct ?? 0)}}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4">
                                                            <div class="col-10 flex-end">{{ $t('message.PurchaseOrderIndex.total_origin') }}:</div>
                                                            <div class="col-2 flex-end">{{n(entry.totalPrice ?? 0)}}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4">
                                                            <div class="col-10 flex-end">{{ $t('message.PurchaseOrderIndex.discount') }}:</div>
                                                            <div class="col-2 flex-end">{{n(entry.priceDiscount ?? 0)}}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4">
                                                            <div class="col-10 flex-end">{{ $t('message.PurchaseOrderIndex.total') }}:</div>
                                                            <div class="col-2 flex-end">{{n(entry.returnSupplier ?? 0)}}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4">
                                                            <div class="col-10 flex-end">{{ $t('message.PurchaseOrderIndex.total_payment') }}:</div>
                                                            <div class="col-2 flex-end">{{n(entry.paymentForSupplier ?? 0)}}</div>
                                                        </div>                                                 
                                                        <div class="row detail-prd-row flex-end mt-4 pr-4"> 
                                                            <button type="button" class="btn btn-save mx-1" v-if="this.statusEmail" @click="sendZalo(entry)">
                                                                <i class="fas fa-sms"></i>
                                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                                            </button>
                                                            <template v-if="entry.statusId !== this.$constant.status_cancel_import_order">
                                                                
                                                                <!-- Xử lý phiếu - Xếp vào vị trí -->
                                                                <AppPermission :permission="this.$permissions.ticketImports.update?? '' ">
                                                                    <button type="button" class="btn btn-save mx-1" v-if="entry.ticket_import && entry.ticket_import.code" @click="processTicket(entry.ticket_import.code)">
                                                                        <i class="fa fa-share"></i>
                                                                        {{ $t('message.PurchaseOrderIndex.button.process_ticket') }}
                                                                    </button>
                                                                </AppPermission>
                                                                <AppPermission :permission="this.$permissions.purchaseOrders.update?? '' ">
                                                                    <button type="button" class="btn btn-save mx-1" @click="updateChange(entry.id, entry.code)">
                                                                        <i class="fa fa-save"></i>
                                                                        {{ $t('message.PurchaseOrderIndex.button.save') }}
                                                                    </button>
                                                                </AppPermission>
                                                                <AppPermission :permission="this.$permissions.purchaseOrders.update?? '' ">
                                                                    <button type="button" class="btn btn-save mx-1" @click="editPurchaseOrderById(entry.id)"  v-if="entry.statusId == this.$constant.statusOptionsTicketTemporary">
                                                                        <i class="fa fa-share"></i>
                                                                        {{ $t('message.PurchaseOrderIndex.button.show') }}
                                                                    </button>
                                                                </AppPermission>
                                                                
                                                                <AppPermission :permission="this.$permissions.returnImportForSupplier.create ? this.$permissions.returnImportForSupplier.create : ''">
                                                                    <AppPermission :permission="this.$permissions.returnImportForSupplier.create ? this.$permissions.purchaseOrders.returnImportSupplier : ''">
                                                                        <button type="button" class="btn btn-save mx-1" @click="returnPurchaseOrderById(entry.code, entry.return_imports_count)" v-if="entry.statusId == this.$constant.status_imported_order">
                                                                            <i class="fa fa-share"></i>
                                                                            {{ $t('message.PurchaseOrderIndex.button.return') }}
                                                                        </button>
                                                                    </AppPermission>
                                                                </AppPermission>                                                            
                                                                    <button type="button" class="btn btn-secondary text-white mx-1" @click="showModalMultipleProductsBarcode('purchaseOrders',entry)">
                                                                        <i class="fa fa-barcode"></i>
                                                                        {{ $t('message.PurchaseOrderIndex.button.print_barcode') }}
                                                                    </button>
                                                                <AppPermission :permission="this.$permissions.purchaseOrders.cancel?? '' ">
                                                                    <button type="button" class="btn btn-danger mx-1" @click="showModalPurchaseOrderAbort(entry)">
                                                                        <i class="fa fa-trash-alt"></i>
                                                                        {{ $t('message.PurchaseOrderIndex.button.cancel') }}
                                                                    </button>
                                                                </AppPermission>
                                                            </template>
                                                        <div class="dropdown">
                                                                    <button class="btn btn-default " type="button" id="dropdownMenuButton"
                                                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i class="fa fa-ellipsis-v px-2" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div class="dropdown-menu drp-menu" aria-labelledby="dropdownMenuButton">
                                                                        <AppPermission :permission="this.$permissions.purchaseOrders.export?? '' ">
                                                                            <a class="dropdown-item" href="#" @click="exportPurchaseOrderById(entry.id)" >
                                                                                <i class="fa fa-file-download px-2" aria-hidden="true"></i>
                                                                                {{ $t('message.PurchaseOrderIndex.button.export') }}
                                                                            </a>
                                                                        </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.purchaseOrders.create?? '' ">
                                                                            <a class="dropdown-item" href="#" @click="copyPurchaseOrderById(entry.id)">
                                                                                <i class="fa fa-copy px-2" aria-hidden="true"></i>
                                                                                {{ $t('message.PurchaseOrderIndex.button.copy') }}
                                                                            </a>
                                                                        </AppPermission>
                                                                        <a class="dropdown-item" href="#" @click="print(entry)">
                                                                            <i class="fa fa-print px-2" aria-hidden="true"></i>
                                                                            {{ $t('message.PurchaseOrderIndex.button.print') }}
                                                                        </a>
                                                                    </div>
                                                            </div>                                                
                
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>                                            
                                        </template>
                                        <!-- Hiển thị tab Lịch sử thanh toán -->
                                        <template v-if="this.tabActive == this.$constant.tabHistoryPayment">                                            
                                            <tr class="detail-row bg-white border__body-detail" v-if="purchaseOrderDetail === entry.id">
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="row mx-0 my-4 pr-4 detail-prd-row" v-if="entries.length > 0">
                                                        <div class="col-12">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail table-header border-1">
                                                                        <th scope="col" class="checkbox-table">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_payment.code') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table pl-3">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_payment.time') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_payment.created_by') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_payment.method') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_payment.status') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table text-center">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_payment.total_price') }}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody> 
                                                                    <template v-if="entry.historyPayment.length > 0">                                                                                                                                       
                                                                        <tr class="table-header border-1" v-for="(history, index) in entry.historyPayment" :key="index">                                                                 
                                                                            <td scope="col" class="checkbox-table">
                                                                                <a href="#" class="text-decoration-none text-primary" @click.stop="showModalDataPayment(history.id, history.type, this.$constant.typeImportForSupplier)">
                                                                                    <span>{{history?.code ? history.code : ''}}</span>
                                                                                </a>
                                                                            </td>
                                                                            <td scope="col" class="checkbox-table"><span>{{history?.createdAt ? history.createdAt : ''}}</span></td>
                                                                            <td scope="col" class="checkbox-table"><span>{{history?.createdByName ? history.createdByName : ''}}</span></td>
                                                                            <td scope="col" class="checkbox-table"><span>{{history?.methodName ? history.methodName : ''}}</span></td>
                                                                            <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                            <td scope="col" class="checkbox-table text-center"><span>{{history?.payment ? history.payment : ''}}</span></td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr >
                                                                            <td colspan="6" class="text-center">
                                                                                {{$t('message.common.notFound')}}
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                            </tr>                                            
                                        </template>
                                        <template v-if="this.tabActive == this.$constant.tabHistoryReturn">                                                    
                                            <tr class="detail-row bg-white border__body-detail" v-if="purchaseOrderDetail === entry.id">
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="row mx-0 my-4 pr-4 detail-prd-row">
                                                        <div class="col-12">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail table-header border-1">
                                                                        <th scope="col" class="" style="width:30px !important;">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_return.code') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table pl-3">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_return.time') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_return.receive_by') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table text-center">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_return.total_price') }}
                                                                        </th>
                                                                        <th scope="col" class="checkbox-table">
                                                                            {{ $t('message.PurchaseOrderIndex.tabs.table.history_return.status') }}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody> 
                                                                    <template v-if="entry.return_imports?.length > 0">                                                                                                                                       
                                                                        <tr class="table-header border-1" v-for="(history, index) in entry.return_imports" :key="index">                                                                 
                                                                            <td scope="col" class="" style="width:50px !important;">
                                                                                <span class="text-primary" @click="linkToReturnPurchaseOrder(history.code)">{{history?.code ? history.code : ''}}</span>
                                                                            </td>
                                                                            <td scope="col" class="checkbox-table"><span>{{history?.returnDate ? dt(history.returnDate) : ''}}</span></td>
                                                                            <td scope="col" class="checkbox-table"><span>{{history?.created_by ? history.created_by.name : ''}}</span></td>
                                                                            <td scope="col" class="checkbox-table text-center"><span>{{history?.totalPrice ? n(history.totalPrice) : ''}}</span></td>
                                                                            <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr >
                                                                            <td colspan="5" class="text-center">
                                                                                {{$t('message.common.notFound')}}
                                                                            </td>
                                                                        </tr>
                                                                    </template>                                                                                
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>                                                                
                                                </td>
                                            </tr>
                                        </template>
                                        <tr class="detail-row bg-white border__footer-detail" v-if="purchaseOrderDetail === entry.id"></tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="row ml-auto mr-auto" v-if="entries.length == 0 && !this.loading" id="notFound">
                            <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPurchaseOrderAbort ref="ModalPurchaseOrderAbort" @inputData="load()"/>
            <ModalMultipleProductsBarcode ref="ModalMultipleProductsBarcode" />
            <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
            <PrintTemplate ref="PrintTemplate" v-show="false" />    
            <ModalReceiveInvoiceSupplier ref="ModalReceiveInvoiceSupplier" @updated="load()"/>    
            <ModalDataPayment ref="ModalDataPayment"  @updated="load()" />
            <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
            <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
        </div>   
    </AppPermission>
</template>
<script>
import {$alert, $get, $post, clone, setArray, getSearchQuery, explode, removeVietnameseTones, isEmpty} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
import DateRangePicker from "@/components/DateRangePicker";
// import QSelect from "../../components/QSelect";
import store from "../../store";
import InputDatePicker from "@/components/forms/InputDatePicker";
import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import InputDateTimePicker from "@/components/forms/InputDateTimePicker";
import SelectField from "../../components/SelectField";
import emitter from "@/lib/emitter";
import router from "@/router/index";
import http from '@/services/api';
import moment from 'moment';
import ModalPurchaseOrderAbort from './tabs/ModalPurchaseOrderAbort';
import ModalReceiveInvoiceSupplier from './ModalReceiveInvoiceSupplier';
import toastr from 'toastr';
import SelectSearch from "@/components/SelectSearch.vue";
import ModalMultipleProductsBarcode from '../products/tabs/ModalMultipleProductsBarcode';
import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';
import {numberFormat} from "@/lib/utils";
import orderForSupplierMixin from "@/orderForSupplierMixin";
import PrintTemplate from '@/components/PrintTemplate';
import AppPermission from '@/components/AppPermission.vue';
import ModalDataPayment from '@/components/ModalDataPayment.vue';
import exportMixin from "@/exportMixin";
import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue'; 
import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
import QSelect from '@/components/QSelect';
import { mapGetters } from "vuex";
export default {
    name: "PurchaseOrderIndex",
    mixins: [orderForSupplierMixin, exportMixin],
    components: {
        ModalSelectSendZaloGroupTemplate,
        QSelect, InputDateRangePicker, SelectSearch, TableSortField, Pagination, InputDatePicker, InputQuickSearch, DateRangePicker, SelectField, ModalPurchaseOrderAbort, InputSelectSearch, InputDateTimePicker,AppPermission,
        ModalMultipleProductsBarcode, ModalSelectPrintTemplate,PrintTemplate, ModalReceiveInvoiceSupplier,ModalDataPayment, ModalSelectSendMailAndZaloTemplate,
    },
    mounted() {
        document.title = this.$t('message.PurchaseOrderIndex.document_title');
        const fields = localStorage.getItem('purchaseOrderSelectedFields_' + this.user.id);
        if (fields) {
            this.fields = JSON.parse(fields);
        }
        const query = clone(this.$route.query);
        if(query['purchase_order'] != null){
            this.loadDetail();
        }else{
            this.clearFilter();
        }
        window.addEventListener('scroll', this.onScroll);
    },
    data() {
        const query = getSearchQuery();       
        const globalConfig = store.state.GlobalConfig;
        return {
            currentBranchID: store.state.CurrentBranch.id,  
            tabActive: this.$constant.tabInformation,           
            loading: false,
            user: store.state.Auth.user,
            actionType: query.action,
            filter: {
                keyword: query.keyword,
                created: null,
                createdPicker: null,
                status: [],
                statusInvoice: [],
                branches: [store.state.CurrentBranch.id],
                suppliers: explode(query.suppliers),
                createdBy: explode(query.createdBy),
                purchaseBy: explode(query.purchaseBy),
                action: explode(query.action),
                order_supplier: '',
                purchase_order: '',
                product_code_name: '',
                supplier_code_name: '',
                note: '',
                note_product: '',
                invoice_supplier_code: '',
            },
            statusList: this.$constant.listStatusImportOrder,
            statusInvoiceList: this.$constant.listStatusInvoice,
            checkedAll: false,
            fields: [],
            entries: [],
            sortData: {
                field: 'id',
                direction: 'desc',
            },
            paginate: {
                currentPage: parseInt(query.page) || 1,
                lastPage: 1
            },
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
                totalRecord: 1,
            },
            numberRecord: 15,
            branches: {
                filter: globalConfig.branches,
                all: globalConfig.branches,
                search: null,
            },
            purchaseBy: {
                filter: globalConfig.users,
                all: globalConfig.users,
                search: null,
            },
            createdBy: {
                filter: globalConfig.users,
                all: globalConfig.users,
                search: null,
            },
            fixed: false,
            arrayChecked: [],
            purchaseOrderDetail: null,
            fieldOrderSupplierDetail : [
                { name: 'message.DetailOrdersCard.prodCode', field: 'code', },
                { name: 'message.DetailOrdersCard.prodName', field: 'name', },
                { name: 'message.DetailOrdersCard.prodUnit', field: 'unitName', },
                { name: 'message.DetailOrdersCard.attribute', field: 'attributeName', },
                { name: 'message.DetailOrdersCard.prodQuantity', field: 'number', },
                { name: 'message.DetailOrdersCard.prodPrice', field: 'price', },
                { name: 'message.DetailOrdersCard.prodDiscount', field: 'discount', },
                { name: 'message.DetailOrdersCard.prodImportPrice', field: 'orderPrice', },
                { name: 'message.DetailOrdersCard.totalPrice', field: 'subTotal', },
            ],
            defaultPurchaseOrder:{
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            },
            sortTime: 0,
            content: null,
            isFilter: false,  
        }
    },
    computed: {
        userBranchList: () => store.state.GlobalConfig?.userBranches ?? [],
        ...mapGetters({
            statusEmail: 'settingStore/email',
        })
    },
    methods: {
        async updateFilterBranch(values) {
            this.doFilterSearch({
                label: 'branches',
                model: (values && values.length) ? values.toString() : '',
            });
        },
        async sendMailAndZalo() {
            this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabImports, this.$constant.typeImportProducts, this.arrayChecked);
        },
        async sendZalo (entry = null) {
            if (entry) {
                this.arrayChecked = [entry.id];
            }
            const typeSend = entry ? this.$constant.typeSendZaloZNS : this.$constant.typeSendZaloGroup;
            const phone = entry && entry.supplier ? entry.supplier.contactNumber : null;
            this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabImports, this.$constant.typeImportProducts, this.arrayChecked, typeSend, phone);
        },
        showModalDataPayment(id, method, input){                         
             this.$refs.ModalDataPayment.show(id, method, input);
        },   
        showContent(tab){            
            this.tabActive = tab;
        },       
        async showModalMultipleProductsBarcode(obj, entry){
            
             let purchaseOrders  = [];
                entry.details.forEach(detail => {
                    
                    let item = {
                        id: detail.product.id,
                        code: detail.product.code,
                        name: detail.product.name,                        
                        quantity: detail.quantity,
                        sendPrice:detail.subTotal,
                        unitName: detail.unit.unitName,
                    };
                    purchaseOrders.push(item);
                });
                
                this.$refs.ModalMultipleProductsBarcode.show(obj, purchaseOrders);
        },
        // -----------------------------------------------------------------
        returnPurchaseOrderById(code, returnImportsCountTemporary) {
            if (returnImportsCountTemporary > 0) {
                $alert({code: 500 , message: this.$t('message.common.haveReturnPODraft')});
                return;
            }
            this.$router.push({name: 'ReturnSupplierCreate', query: {codeImport: code}})
        },
        async changeQuickSearch(object){
            const query = clone(this.$route.query);
            Object.keys(this.filter).forEach(key => {
                object.forEach(obj => {
                    Object.keys(obj).forEach(o => {
                        if(key == o && obj[o] != ''){
                            this.filter[key] = obj[o];
                            query[o] = obj[o];
                        }else if(key == o && obj[o] == ''){
                            delete query[o];
                        }
                    });
                })
            });
            query['page'] = 1;
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/purchase-orders/index',
                query: {
                    action: 'import',
                    status: '0,1',
                    branches: store.state.CurrentBranch.id,
                }
            });
            
            Object.keys(this.filter).forEach(key => {
                if(key == 'status'){
                    this.filter[key] = [0,1];
                }else if(key == 'statusInvoice'){
                    this.filter[key] = [1,2];
                }else if(key == 'branches'){
                    this.filter[key] = [store.state.CurrentBranch.id];
                }else{
                    this.filter[key] = '';
                }
            });
            emitter.emit("clearQuickSearch");
            emitter.emit("clearDateRangePicker");
            emitter.emit("clearSelectSearchValue");
            this.arrayChecked = [];
            this.branches.search = null;
            this.purchaseBy.search = null;
            this.createdBy.search = null;
            this.sortTime = 0;
            this.numberRecord = 15;
            this.purchaseOrderDetail = null;
            this.isFilter = false;
            this.load();
        },
        getCheckedIDs() {
            return this.entries.filter(e => e.checked).map(e => e.id);
        },
        async filterTime(time = null, option){
            const query = clone(this.$route.query);
            delete query['created'];
            if(time){
                query['created'] = time;
                query['page'] = 1;
                if(option == 1){
                    this.sortTime = 1;
                    this.filter.createdPicker = time;
                }else{
                    this.sortTime = 0;
                    this.filter.created = time;
                }
            }
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },
        async doFilterSearch(v){
            const query = clone(this.$route.query);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
            }
            query['page'] = 1;
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },
        async doFilter(field, value, name) {
            const query = clone(this.$route.query);
            if(field == 'status'){
                let arr = this.filter.status;
                if(arr.includes(value)){
                    arr = arr.filter(item => item !== value)
                }else{
                    arr.push(value)
                }
                this.filter.status = arr;
                if (Object.keys(arr).length === 0) {
                    delete query[field];
                }else{
                    query[field] = arr.toString();
                }
            }else if(field == 'statusInvoice'){
                let arr = this.filter.statusInvoice;
                if(arr.includes(value)){
                    arr = arr.filter(item => item !== value)
                }else{
                    arr.push(value)
                }
                this.filter.statusInvoice = arr;
                if (Object.keys(arr).length === 0) {
                    delete query[field];
                }else{
                    query[field] = arr.toString();
                }
            }else{
                // if(query[field] == value){
                //     delete query[field];
                //     name = null;
                // }else{
                    query[field] = value;
                // }
            }
            query['page'] = 1;
            await this.$router.push({query: query});
            switch (field) {
                case 'branches':
                    this.branches.search = name;
                    break;
                case 'purchaseBy':
                    this.purchaseBy.search = name;
                    break;
                case 'createdBy':
                    this.createdBy.search = name;
                    break;
                default:
                    break;
            }
            this.isFilter = true;
            this.load();
            this.purchaseOrderDetail = null;
        },
        async sort(field, direction) {
            this.sortData = {
                field, direction
            };

            const query = clone(this.$route.query);
            query.sort_field = field;
            query.sort_direction = direction;

            await this.$router.push({query: query});
            this.load();


        },
        async pageChange(page) {
            const query = clone(this.$route.query);
            query['page'] = page;
            query['action'] = this.actionType;
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.$route.query);
            query['page']=1;
            query.numberRecord = v.target.value;
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },

        convertArrayToString(array){
            if(array && Array.isArray(array)){
                return array.toString();
            }
            return array
        },

        async load() {
            this.loading = true;            
            emitter.emit("onLoading");
            const res = await $post('/v1/purchase-orders/data', {...this.$route.query, numberRecord: this.numberRecord});               
            if (res.status.code != 200) {
                $alert(res);
                return;
            }
            //Phục vụ cho export tổng quang không qua phân trang
            setArray(res.result.data.data, {
                checked: false
            });
            if (!this.fields || !this.fields.length) {
                this.fields = res.result.fields;
            }
            // this.pageTitle = res.result.title;
            // document.title = this.pageTitle;

            this.paginate.totalRecord = res.result.data.total;
            this.paginate.currentPage = res.result.data.current_page;
            this.paginate.lastPage = res.result.data.last_page;
            this.entries = res.result.data.data;
            this.entries.forEach(item => {
                item.dateTimePicker = item.importDate;
                item.time =  item.created_at ? moment(item.created_at).format('YYYY-MM-DD'): null;
                item.orderTime = item.created_at ? moment(item.created_at).format('HH:mm'): null;
                item.historyPayment.forEach(history => {
                    history.createdAt = history?.createdAt ? moment(history.createdAt).format('DD/MM/YYYY HH:mm'): null;    
                    history.payment = history?.payment ? numberFormat(history.payment): 0;  
                });
                item.historyPayment = item.historyPayment.sort(function(a, b) { return b.id - a.id});
            });             
            // if(res.result && res.result.statusInvoiceList){
            //     this.statusInvoiceList = res.result.statusInvoiceList;
            // }
            this.isCheckedAll();
            emitter.emit("offLoading");
            this.loading = false;
        },
        async loadDetail(){
            emitter.emit("clearQuickSearch");
            emitter.emit("clearDateRangePicker");
            emitter.emit("clearSelectSearchValue");
            this.arrayChecked = [];
            this.branches.search = null;
            this.purchaseBy.search = null;
            this.createdBy.search = null;
            this.sortTime = 0;
            this.numberRecord = 15;
            await this.load();
            this.entries.forEach((entry) => {
                this.detailPurchaseOrder(entry.id, entry);
            });
        },
        detailPurchaseOrder(id, entry){            
            if (id === this.purchaseOrderDetail) {
                this.purchaseOrderDetail = null;
            } else {
                this.purchaseOrderDetail = id;
            }
            this.defaultPurchaseOrder.filter = this.defaultPurchaseOrder.all = entry.details;
            this.setPaginate();
            this.tabActive = this.$constant.tabInformation;
        },
        onInputFilterDetailOrderSupplier(field){
            this.paginateDetail.currentPage = 1;
            if(this.defaultPurchaseOrder.searchCode.length == 0 && this.defaultPurchaseOrder.searchName.length == 0){
                this.defaultPurchaseOrder.filter = this.defaultPurchaseOrder.all;
                this.setPaginate();
                return;
            }
            let arr = [];
            switch (field) {
                case 'searchCode':
                    for (let index = 0; index < this.defaultPurchaseOrder.all.length; index++) {
                        if (this.defaultPurchaseOrder.all[index] != null && removeVietnameseTones(this.defaultPurchaseOrder.all[index].product?.code.toLowerCase()).indexOf(removeVietnameseTones(this.defaultPurchaseOrder.searchCode.toLowerCase())) > -1){
                            arr.push(this.defaultPurchaseOrder.all[index]);
                        }
                    }
                    break;
                case 'searchName':
                    for (let index = 0; index < this.defaultPurchaseOrder.all.length; index++) {
                        if (this.defaultPurchaseOrder.all[index] != null && removeVietnameseTones(this.defaultPurchaseOrder.all[index].product?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultPurchaseOrder.searchName.toLowerCase())) > -1){
                            arr.push(this.defaultPurchaseOrder.all[index]);
                        }
                    }
                    break;
            }
            this.defaultPurchaseOrder.filter = arr;
            this.setPaginate();
        },
        setPaginate(){
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(this.defaultPurchaseOrder.filter.length / 10);
            this.paginateDetail.totalRecord = this.defaultPurchaseOrder.filter.length;
        },
        isCheckedAll(){
            let getChecked = 0;
            this.entries.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.entries.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
        onCheckedAll(value) {
            setArray(this.entries, {
                checked: value
            });
            if(value == true){
                this.entries.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.entries.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){            
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        removeChecked(){
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        showHide(name,iDown, iUp){
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        async clearSearch(field){
            const query = clone(this.$route.query);
            delete query[field];
            query['page'] = 1;
            await this.$router.push({query: query});
            switch (field) {
                case 'branches':
                    this.branches.search = null;
                    this.branches.filter = this.branches.all;
                    break;
                case 'purchaseBy':
                    this.purchaseBy.search = null;
                    this.purchaseBy.filter = this.purchaseBy.all;
                    break;
                case 'createdBy':
                    this.createdBy.search = null;
                    this.createdBy.filter = this.createdBy.all;
                    break;
                default:
                    break;
            }
            this.load();
        },
        onScroll() {
            let height = window.scrollY;
            if(height > 120){
                //Giữ vị trí cho thông báo lỗi    
                this.fixed = true;
            }else{
                this.fixed = false;
            }
            emitter.emit("position", 0);
        },
        onMouseLeave(){
            emitter.emit("mouseleave");
        },
        showModalPurchaseOrderCreateUpdate(){
            router.push({ name: 'ImportSupplierCreate'});
        },
        timeChange(time, entry){
            entry.orderTime = time;
            this.orderTime = time;
        }, 
        //Export
        getOSByID(entries){              
                return entries
        },
        async exSelectOverview() {
            await $post('/v1/purchase-orders/exOverview', {fields:this.fields, id: this.arrayChecked, branchID: this.currentBranchID});            
            emitter.emit('showModalExport');
        },
        async exSelectDetails() {
            // await $post('/v1/purchase-orders/exDetails', {id: this.arrayChecked, branchID: this.currentBranchID});
            // emitter.emit('showModalExport');
            emitter.emit('showModalExport');
            emitter.emit('changeUploadDriver');
            const res = await $post('/v1/purchase-orders/exDetails', {id: this.arrayChecked, branchID: this.currentBranchID});            
            if(res?.result && res?.result?.status){
                emitter.emit('changeFileExport', res?.result?.url);
            }
        },
        async exOverview() {
            let query = {...this.$route.query};
            await $post('/v1/purchase-orders/exOverview', {fields:this.fields, query: query, branchID: this.currentBranchID});            
            emitter.emit('showModalExport');
        },
        async exDetails() {
            emitter.emit('showModalExport');
            emitter.emit('changeUploadDriver');
            const res = await $post('/v1/purchase-orders/exDetails', {...this.$route.query, branchID: this.currentBranchID});            
            if(res?.result && res?.result?.status){
                emitter.emit('changeFileExport', res?.result?.url);
            }
        },
        showModalPurchaseOrderAbort(entry){            
            this.$refs.ModalPurchaseOrderAbort.show(entry)
        },
        changeDateTimePicker(time){
            this.entries.forEach(entry => {
                if(entry.id == this.purchaseOrderDetail){
                    entry.dateTimePicker = time;
                }
            });
        },
        resetValue(uid){
            this.entries.forEach(entry => {
                if(entry.id === this.purchaseOrderDetail){
                    entry.userId = uid;
                }
            })
        },
        async updateChange(id,code){
            let uid, dateTime;
            this.entries.forEach(entry => {
                if(entry.id === id){
                    uid = entry.userId;
                    dateTime = entry.dateTimePicker;
                }
            })                
            let note = document.getElementById('note_' + id).value;
            let data = {
                importDate : dateTime,                    
                userId   : uid,                    
                note      : note,
            }         
            const res = await $post('/v1/purchase-orders/updateList/' + id, {...data});
            if(res.status.code == 200) {                    
                toastr.success(this.$t('message.common.updatePOSuccess', {code: code}));
                this.load();
            }
            else {
                if(res.code == 422){
                    if(res.messages.errors.orderDate) toastr.error(res.messages.errors.orderDate);
                    if(res.messages.errors.expectedAt) toastr.error(res.messages.errors.expectedAt);
                    if(res.messages.errors.importDate) toastr.error(res.messages.errors.importDate);
                     if(res.messages.errors.note) toastr.error(res.messages.errors.note);
                }                 
            }
        },
        async exportPurchaseOrderById(id){
            emitter.emit("onLoading");   
            const res = await http.download('/v1/purchase-orders/exPOById/' + id, {...this.fields, branchID: this.currentBranchID});                
            this.returnDataExport(res);   
        },
        async copyPurchaseOrderById(id){            
            router.push({ name: 'ImportSupplierCopy', query: { id: id, type: 'copy' }});               
        },
        async editPurchaseOrderById(id){
            router.push({ name: 'ImportSupplierCopy', query: { id: id}});               
        },
        refreshDatePicker(option){
            switch (option) {
                case 0:
                    this.filterTime(this.filter.created);
                    break;
                case 1:
                    this.filterTime(this.filter.createdPicker, 1);
                    break;
            }
        },
        // In phiếu
        async print(entry){                 
                entry.typePrint = this.$constant.TypePurchaseOrder;               
                // Chọn mẫu in                
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: 'TabImports'});                        
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }                                                             
        },        
        async printMultiple(ids = null){
                //Lấy data theo mang ids
                const res = await $post('/v1/purchase-orders/data-by-ids', {
                        ids: ids ?? this.arrayChecked,
                });
                if(res && res.status.code == 200 && res.result.length > 0) {
                    await this.print(res.result);                
                }
        },
        async updateContent(data){   
                this.editorDataClone = '';
                this.content = data.content;                                                 
                if(data.dataPrint && Array.isArray(data.dataPrint)){
                    let breakPage = `<div style="page-break-before: always"></div>`
                    for (let index = 0; index < data.dataPrint.length; index++) {
                            const print = this.formatproductPrintCommon(data.dataPrint[index], this.$constant.type_print_multiple); 
                            print.typePrint = this.$constant.TypePurchaseOrder;                            
                            let dataPrint = await this.dataPrint(print);
                            let orderItem = await this.renderData(dataPrint);                            
                            if (index != data.dataPrint.length - 1) {
                                this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                            } else {
                                this.editorDataClone = this.editorDataClone + orderItem;
                            }
                        }             
                    if(this.editorDataClone !== null) await this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);         
                }
                else{        
                    let dataPrint = await this.formatproductPrintCommon(data.dataPrint, this.$constant.type_print_single);
                    await this.printTemplate(dataPrint);
                }
        },
        async printTemplate(entry) {    
            entry.typePrint = this.$constant.TypePurchaseOrder;                              
            let dataPrint = await this.dataPrint(entry);            
            this.editorDataClone = await this.renderData(dataPrint);            
            await this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);
        },
        receiveInvoiceSupplier(arrayChecked){
            this.$refs.ModalReceiveInvoiceSupplier.show(arrayChecked);
        },
        linkToReturnPurchaseOrder(code){
            var route = null;
            route = this.$router.resolve({
                name: 'ReturnPurchaseOrder',
                query: {
                    'return_purchase_order': code,
                }
            });
            window.open(route.href, '_blank');
        },
        async processTicket(code){
            await this.$router.replace({
                name: 'CreateTickerImportScreen',
                query: {
                    type: 'Import',
                    importCode: code,
                }
            });
        },
    }
}
</script>
<style scoped>

 #fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
 }
 .noteStyle {
        width: 280px;
        height: 100% !important;
        border: 0;
        border-left: 1px solid #ccc;
        border-radius: 0;
        
    }
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.drp-menu{
    position: absolute;
    left:-125px;
    top:-102px;
}
select option {
    border-color: none !important;
    box-shadow: none !important;
    outline: 0 none !important;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.far, .fa-clock {     
    position: absolute;
    right: 16px;     
    top: 10px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;     
    -moz-osx-font-smoothing: grayscale;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
#notePO{
    border-left: 1px solid #ccc;
    color:#000;    
}
</style>
