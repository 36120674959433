
<template>
    <AppPermission :permission="this.$permissions.transfers.data?? '' ">
    <div class="container">
        <div class="row m-0 p-0">
            <div class="mainLeft pt-3">
                <div class="main-title">
                    <span>{{$t('message.TransferIndex.title')}}</span>
                </div>
                <div>
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row">
                        <div class="col-8 text-title">
                            <label>{{ $t('message.common.numberRecord') }}:</label>
                        </div>
                        <div class="col-4">
                            <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.TransferIndex.filter_time') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                    <i class="fa fa-chevron-up" id="timeUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="timeBody">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{ $t('message.TransferIndex.transfer_date') }}
                                    <input type="checkbox" class="chkType" @click="changeSelect('deliveryDate')" :checked="this.optionSelect.deliveryDate">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{ $t('message.TransferIndex.receive_date') }}
                                    <input type="checkbox" class="chkType" @click="changeSelect('receivedDate')" :checked="this.optionSelect.receivedDate">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-2 pl-2 d-flex align-items-center">
                                <label class="container-radio ml-2">
                                    <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios1" :value="0" class="mr-2" @click="refreshDatePicker(0)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-10 pl-0">
                                <DateRangePicker :formatType="'YYYY-MM-DD'" @picker="filterTime"></DateRangePicker>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-2 pl-2 d-flex align-items-center">
                                <label class="container-radio ml-2">
                                    <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios2" :value="1" class="mr-2" @click="refreshDatePicker(1)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-10 pl-0">
                                <InputDateRangePicker :sortTimeHeight="true" :defaultDate="true" :placeholder="$t('message.SelectDateTime.otherOption')" class="w-100 mb-0 custom-input"
                                @input="filterTime(filter.timePicker, 1)" v-model="filter.timePicker"/>
                                <!-- <i class="far fa-calendar fa-md icon-calendar" aria-hidden="true"></i> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.TransferIndex.type') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('typeBody', 'typeDown', 'typeUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="typeDown"></i>
                                    <i class="fa fa-chevron-up" id="typeUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="typeBody">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{ $t('message.TransferIndex.type_transfer') }}
                                    <input type="checkbox" class="chkType" @click="changeSelect('transferBranch')" :checked="this.optionSelect.transferBranch">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.TransferIndex.to_branch')" :disabled="!this.optionSelect.transferBranch" :modelValue="filter.transferToBranch" :defaultOption="this.branches" @update:modelValue="doFilterSearch" :label="'toBranch'"></SelectSearch>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{ $t('message.TransferIndex.type_receive') }}
                                    <input type="checkbox" class="chkType" @click="changeSelect('requestBranch')" :checked="this.optionSelect.requestBranch">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.TransferIndex.from_branch')" :disabled="!this.optionSelect.requestBranch" :modelValue="filter.requestToBranch" :defaultOption="this.branches" @update:modelValue="doFilterSearch" :label="'fromBranch'"></SelectSearch>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.TransferIndex.status') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                    <i class="fa fa-chevron-up" id="statusUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                        <div class="row" v-for="status in this.listStatusTransfer" :key="status.id">
                            <div class="col-12 d-flex align-items-center">
                            <label class="container-checkbox">{{status.name}}
                                <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.TransferIndex.status_receive') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('status', 'sttDown', 'sttUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="sttDown"></i>
                                    <i class="fa fa-chevron-up" id="sttUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="status">
                        <div class="row ml-0" v-for="option in this.optionSttGive" :key="option.id">
                            <label class="container-radio ml-0">{{option.label}}
                                <input type="radio" name="sttGive" v-model="this.filterQuery.receiveStatus" :checked="this.filterQuery.receiveStatus === option.value" :value="option.value" class="cheker mr-2" @change="doFilter('receiveStatus', option.value)">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mainRight">
                <div class="sticky-top pt-3">
                    <div class="row">
                        <div class="col-lg-5">
                            <InputQuickSearch :placeholder="$t('message.TransferIndex.placeholder.search')" @filterSearch="changeQuickSearch" :transferSearch="true"></InputQuickSearch>
                        </div>
                        <div class="col-lg-7 d-flex justify-content-end">
                           
                            <button type="button" @click="clearFilter()" class="btn btn-default">
                                <i class="fa fa-filter mr-1"></i>
                                {{ $t('message.button.removeFilter') }}
                            </button>
                            <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                    style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-ellipsis-v px-2"></i>
                                    {{ $t('message.TransferIndex.button.action') }}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <AppPermission :permission="this.$permissions.transfers.export?? '' ">
                                        <a class="dropdown-item" @click="exSelectOverview()">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.TransferIndex.button.export_all') }}
                                        </a>
                                        <a class="dropdown-item" @click="exSelectDetails()">
                                            <i class="fas fa-file-export"></i>
                                            {{ $t('message.TransferIndex.button.export_detail') }}
                                        </a>
                                    </AppPermission>
                                    <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                        <i class="fa fa-share"></i>
                                        {{ $t('message.button.sendMailText') }}
                                    </a>
                                    <a class="dropdown-item" @click="sendZalo()">
                                        <i class="fa fa-share"></i>
                                        {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                    </a>
                                </div>
                            </div>
                            <AppPermission :permission="this.$permissions.transfers.create?? '' ">
                                    <button class="btn btn-save ml-1" @click="createTicket">
                                        <i class="fa fa-plus"></i> 
                                        {{ $t('message.TransferIndex.button.create') }}
                                    </button>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.transfers.export?? '' ">
                                <div class="dropdown">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-file-export px-2"></i>
                                        {{ $t('message.TransferIndex.button.export') }}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#" @click="selectOptionExport('exOverview')">
                                            {{ $t('message.TransferIndex.button.file_all') }}
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" @click="selectOptionExport('exDetails', 0, 4000)">
                                            {{ $t('message.TransferIndex.button.file_detail') }}
                                        </a>
                                    </div>
                                </div>
                            </AppPermission>
                            <div id="loc" v-on:mouseleave="onMouseLeave">
                                <SelectField :fields="fields" label="transfer"></SelectField>
                            </div>                            
                        </div>
                    </div>
                    <div>
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                <a href="javascript:;" @click="removeChecked()">
                                    <i class="fa fa-times text-danger" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div class="row mx-0 my-2" v-if="entries.length > 0" id="divTable">
                                <table class="table table-index table-striped lstProduct">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                            <template v-for="field in fields" :key="field.field">
                                                <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                    <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                    :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                                                </th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(entry, index) in entries" :key="entry.id" >
                                            <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :class="entry.id === this.transferActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                    <td>
                                                        <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                    </td>
                                                    <template v-for="field in fields" :key="field.field">
                                                        <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                            <a :href="'#row_' + index" class="description text-dark tagA" @click="detailTransfer(entry.id, entry)">
                                                            <div v-if="field.field==='fromBranch'" :style="field.style" class="description"><span v-text="entry.from_branch?.name"></span></div>      
                                                            <div v-else-if="field.field==='toBranch'" :style="field.style" class="description"><span v-text="entry.to_branch?.name"></span></div>
                                                            <div v-else-if="field.field==='createdByName'" :style="field.style" class="description"><span v-text="entry.created_by_user?.name"></span></div>
                                                            <div v-else-if="field.field==='receiver'" :style="field.style" class="description"><span v-text="entry.received_by_user?.name"></span></div>
                                                            <div v-else-if="field.field==='status'" :style="field.style" class="description"><span v-text="entry.statusName"></span></div>
                                                            <div v-else-if="field.field==='totalSendQuantity'" :style="field.style" class="description"><span v-text="n(entry.totalSendQuantity)"></span></div>
                                                            <div v-else-if="field.field==='sendPrice'" :style="field.style" class="description"><span v-text="n(entry.sendPrice)"></span></div>
                                                            <div v-else-if="field.field==='totalReceiveQuantity'" :style="field.style" class="description"><span v-text="n(entry.totalReceiveQuantity)"></span></div>
                                                            <div v-else-if="field.field==='receivePrice'" :style="field.style" class="description"><span v-text="n(entry.receivePrice)"></span></div>
                                                            <div v-else-if="field.field==='totalProduct'" :style="field.style" class="description"><span v-text="n(entry.totalProduct)"></span></div>
                                                            <div v-else-if="field.field==='deliveryDate'" :style="field.style" class="description"><span v-text="dt(entry.deliveryDate)"></span></div>
                                                            <div v-else-if="field.field==='receivedDate'" :style="field.style" class="description"><span v-text="dt(entry.receivedDate)"></span></div>
                                                            <div v-else-if="field.field==='createdAt'" :style="field.style" class="description"><span v-text="dt(entry.createdAt)"></span></div>
                                                            <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                            </a>
                                                        </td>
                                                    </template>
                                            </tr>
                                            <tr v-if="transferActive === entry.id" :class="[ entry.id === transferActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                <td></td>
                                                <td :colspan="fields.length" class="pb-0">
                                                    <div class="row detail-prd-row">
                                                        <div class="col-2 mt-2">
                                                            <span class="backgroup__detail-active head-detail">
                                                                <span v-if="this.branchId === entry.fromBranchId">
                                                                    {{ $t('message.TransferIndex.tabs.info') }}
                                                                </span>
                                                                <span v-else>
                                                                    {{ $t('message.TransferIndex.tabs.detail_receive') }}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Chi tiết phiếu chuyển hàng -->
                                            <tr class="detail-row bg-white border__body-detail" v-if="transferActive === entry.id">
                                                <td></td>
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4 detail__user">
                                                        <div class="row mt-2 detail-prd-row">
                                                            <div class="col-4">
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.TransferIndex.transfer_code')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">                                                            
                                                                        <b>{{ entry.code }}</b>
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5 lableOS">
                                                                        {{$t('message.TransferIndex.from_branch')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                        {{ entry.from_branch?.name }}
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.TransferIndex.transfer_date')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ dt(entry.deliveryDate) }}
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.TransferIndex.filter_user')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ entry.created_by_user?.name }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class="mx-1">
                                                                    <div class="col-12">
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 lableOS">
                                                                                {{$t('message.TransferIndex.status')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                {{ entry.statusName }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.TransferIndex.to_branch')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                <!-- <InputSelectSearch :defaultValue="'Chọn chi nhánh'" :modelValue="entry.toBranchId" :defaultOption="branches" @update:modelValue="resetValue"
                                                                                v-if="entry.statusOrigin == this.$constant.statusTransferDraft"></InputSelectSearch>
                                                                                <span v-else>{{ entry.to_branch?.name }}</span> -->
                                                                                {{ entry.to_branch?.name }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.TransferIndex.receive_date')}}:
                                                                            </div>                                                                
                                                                            <div class="col-7 ow-break-word">                                                                    
                                                                                {{ dt(entry.receivedDate) }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.TransferIndex.request_code')}}:
                                                                            </div>                                                                
                                                                            <div class="col-7 ow-break-word"> 
                                                                                <template v-if="this.hasPermission(this.$permissions.requests.data ?? '')">
                                                                                    <a :href="'/request/index?code=' + entry.refCode" target="_blank" class="text-decoration-none text-primary">{{entry.refCode}}</a>                                                                 
                                                                                </template>
                                                                                <template v-else>{{entry.refCode}}</template>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 font-italic" id="noteRO">  
                                                                <div class="row p-0 h-50">
                                                                    <div class="col-12" v-if="this.branchId === entry.fromBranchId && (entry.statusOrigin == $constant.statusTransferDraft || entry.statusOrigin == $constant.statusTicketTransferTransported)">
                                                                        <textarea v-model="entry.sendDescription" :id="'note_'+ entry.id" :placeholder="$t('message.TransferIndex.from_branch_note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                        <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                    </div>
                                                                    <div class="col-12" v-else>
                                                                        <span :class="this.branchId === entry.fromBranchId ? 'text-gray' : ''">
                                                                            {{ entry.sendDescription ? entry.sendDescription : $t('message.TransferIndex.from_branch_note')}}
                                                                        </span>
                                                                    </div>
                                                                </div>                                                     
                                                                <div class="row p-0 h-50">
                                                                    <div class="col-12" v-if="this.branchId === entry.toBranchId && (entry.statusOrigin == $constant.statusTransferDraft || entry.statusOrigin == $constant.statusTicketTransferTransported)">
                                                                        <textarea v-model="entry.receiveDescription" :id="'note_'+ entry.id" :placeholder="$t('message.TransferIndex.to_branch_note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                        <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                    </div>
                                                                    <div class="col-12" v-else>
                                                                        <span :class="this.branchId === entry.toBranchId ? 'text-gray' : ''">
                                                                            {{ entry.receiveDescription ? entry.receiveDescription : $t('message.TransferIndex.to_branch_note')}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Chi tiet san pham va gia -->
                                            <tr class="detail-row bg-white border__body-detail" v-if="transferActive === entry.id">
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4 detail__user">
                                                        <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                            <div class="col-12 pl-0">
                                                                <table class="w-100">
                                                                    <thead>
                                                                        <tr class="backgroup__detail">
                                                                            <template v-for="fieldDetail in this.fieldTransferDetail" :key="fieldDetail">
                                                                                <th scope="col" class="pl-4"  v-text="fieldDetail.name" v-if="fieldDetail.selected || (entry.statusOrigin != $constant.statusTransferDraft && entry.statusOrigin != $constant.statusTicketTransferTransported)" :style="fieldDetail.style ? fieldDetail.style : ''"></th>
                                                                            </template>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr class="bg-white">
                                                                            <th scope="col" class="pl-4"> 
                                                                                <input type="text" v-model="this.defaultTransfer.searchCode" @input="onInputFilterTransferDetail('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                            </th>
                                                                            <th scope="col" class="pl-4"> 
                                                                                <input type="text" v-model="this.defaultTransfer.searchName" @input="onInputFilterTransferDetail('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                            </th>
                                                                            <th scope="col" class="pl-4" :colspan="fieldTransferDetail.length - 2"> 
                                                                            </th>
                                                                        </tr>
                                                                        <template v-if="this.defaultTransfer.filter?.length > 0">
                                                                            <template v-for="(transferDetail, index) in this.defaultTransfer.filter"  :key="transferDetail.id">
                                                                                <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                                    <tr>
                                                                                        <td class="pl-4"><a :href="'/products/index?code=' + transferDetail.productCode" target="_blank" class="text-decoration-none text-primary">{{transferDetail.productCode}}</a></td>
                                                                                        <td class="pl-4">
                                                                                            <span v-text="transferDetail.productName"></span>
                                                                                            <!-- <span class="col-12 description font-italic text-gray" v-text="purchaseOrderDetail.note" :title="purchaseOrderDetail.note"></span> -->
                                                                                        </td>
                                                                                        <!-- <td v-text="transferDetail.variant ? transferDetail.variant?.name : ''" class="pl-4 text-left"></td> -->
                                                                                        <td v-text="transferDetail?.attributeName ? transferDetail.attributeName : '' " class="pl-4"></td>
                                                                                        <td v-text="transferDetail.product_unit ? transferDetail.product_unit?.unitName : ''" class="pl-4 text-left"></td>
                                                                                        <td v-text="n(transferDetail.sendQuantity)" class="pl-4 text-right"></td>
                                                                                        <td v-text="n(transferDetail.receiveQuantity)" class="pl-4 text-right" v-if="(entry.statusOrigin != $constant.statusTransferDraft && entry.statusOrigin != $constant.statusTicketTransferTransported)"></td>
                                                                                        <td v-text="n(transferDetail.price)" class="pl-4 text-right"></td>
                                                                                        <td class="pl-4 text-right">
                                                                                            {{ n(transferDetail.sendPrice) }}
                                                                                        </td>
                                                                                        <td class="pl-4 text-right" v-if="(entry.statusOrigin != $constant.statusTransferDraft && entry.statusOrigin != $constant.statusTicketTransferTransported)">
                                                                                            {{ n(transferDetail.receivePrice) }}
                                                                                        </td>
                                                                                    </tr>
    
                                                                                    <template v-if="transferDetail.consignments">
                                                                                        <tr class="bg-white" v-for="(value, indexConsignment) in transferDetail.consignments" :key="indexConsignment">
                                                                                            <td class="pl-4"></td>
                                                                                            <td class="pl-4">
                                                                                                <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                            </td>
                                                                                            <td class="pl-4"></td>
                                                                                            <td :colspan="6"></td>
                                                                                        </tr>
                                                                                    </template>
                                                                                    <!-- Plus -->
                                                                                    <template v-if="transferDetail.plus && transferDetail.plus?.length > 0">
                                                                                        <template v-for="(plusItem, indexPlus) in transferDetail.plus" :key="indexPlus">
    
                                                                                        <tr>
                                                                                            <td class="pl-4">
                                                                                                <a :href="'/products/index?code=' + plusItem.product?.code" target="_blank" class="text-decoration-none text-primary">{{transferDetail.product?.code}}</a>
                                                                                            </td>
                                                                                            <td class="pl-4">
                                                                                                <span v-text="plusItem.product?.name"></span>
                                                                                                <!-- <span class="col-12 description font-italic text-gray" v-text="plusItem.note" :title="transferDetail.note"></span> -->
                                                                                            </td>
                                                                                            <!-- <td v-text="plusItem.variant ? plusItem.variant?.name : ''" class="pl-4 text-left"></td> -->
                                                                                            <td v-text="plusItem?.attributeName ? plusItem.attributeName : '' " class="pl-4"></td>
                                                                                            <td v-text="plusItem.product_unit ? plusItem.product_unit?.unitName : ''" class="pl-4 text-left"></td>
                                                                                            <td v-text="n(plusItem.sendQuantity)" class="pl-4 text-right"></td>
                                                                                            <td v-text="n(plusItem.receiveQuantity)" class="pl-4 text-right" v-if="(entry.statusOrigin != $constant.statusTransferDraft && entry.statusOrigin != $constant.statusTicketTransferTransported)"></td>
                                                                                            <td v-text="n(plusItem.price)" class="pl-4 text-right"></td>
                                                                                            <td class="pl-4 text-right">
                                                                                                {{ n(plusItem.sendPrice) }}
                                                                                            </td>
                                                                                            <td class="pl-4 text-right" v-if="(entry.statusOrigin != $constant.statusTransferDraft && entry.statusOrigin != $constant.statusTicketTransferTransported)">
                                                                                                {{ n(plusItem.receivePrice) }}
                                                                                            </td>
                                                                                        </tr>
    
                                                                                        <template v-if="plusItem.consignments">
                                                                                            <tr class="bg-white" v-for="(value, indexConsignment) in plusItem.consignments" :key="indexConsignment">
                                                                                                <td class="pl-4"></td>
                                                                                                <td class="pl-4">
                                                                                                    <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                                </td>
                                                                                                <td class="pl-4"></td>
                                                                                                <td :colspan="6"></td>
                                                                                            </tr>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                </template>
                                                                            </template>      
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr class="text-center bg-light">
                                                                                <td :colspan="fieldTransferDetail.length">{{$t('message.common.notFound')}}</td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                                <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                            </div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-4 pr-4">
                                                            <div class="col-10 flex-end">{{$t('message.TransferIndex.total_products')}}:</div>
                                                            <div class="col-2 flex-end">{{ entry.totalProduct }}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4">
                                                            <div class="col-10 flex-end">{{$t('message.TransferIndex.total_send_quantity')}}:</div>
                                                            <div class="col-2 flex-end">{{ entry.totalSendQuantity }}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4">
                                                            <div class="col-10 flex-end">{{$t('message.TransferIndex.total_send_price')}}:</div>
                                                            <div class="col-2 flex-end">{{ n(entry.sendPrice) }}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4" v-if="(entry.statusOrigin != $constant.statusTransferDraft && entry.statusOrigin != $constant.statusTicketTransferTransported)">
                                                            <div class="col-10 flex-end">{{$t('message.TransferIndex.total_receive_quantity')}}:</div>
                                                            <div class="col-2 flex-end">{{ entry.totalReceiveQuantity }}</div>
                                                        </div>
                                                        <div class="row detail-prd-row mt-2 pr-4" v-if="(entry.statusOrigin != $constant.statusTransferDraft && entry.statusOrigin != $constant.statusTicketTransferTransported)">
                                                            <div class="col-10 flex-end">{{$t('message.TransferIndex.total_receive_price')}}:</div>
                                                            <div class="col-2 flex-end">{{ n(entry.receivePrice) }}</div>
                                                        </div>
                                                        <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                            <button type="button" class="btn btn-save mx-1" @click="sendZalo(entry)">
                                                                <i class="fa fa-share"></i>
                                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                                            </button>
                                                             <AppPermission :permission="this.$permissions.transfers.update?? '' ">
                                                                <button v-if="entry.statusOrigin == $constant.statusTransferDraft || entry.statusOrigin == $constant.statusTicketTransferTransported" type="button" class="btn btn-save mx-1" @click="quickSaveTransfer(entry)">
                                                                    <i class="fa fa-save"></i>
                                                                    {{$t('message.TransferIndex.button.save')}}
                                                                </button>
                                                            </AppPermission>
                                                             <AppPermission :permission="this.$permissions.transfers.update?? '' ">
                                                                <button v-if="entry.statusOrigin == $constant.statusTransferDraft && branchId === entry.fromBranchId" type="button" class="btn btn-save mx-1" @click="editTicket(entry.id)">
                                                                    <i class="fa fa-share"></i>
                                                                    {{$t('message.TransferIndex.button.open')}}
                                                                </button>
                                                            </AppPermission>
                                                             <AppPermission :permission="this.$permissions.transfers.update?? '' ">
                                                                <button v-if="entry.statusOrigin == $constant.statusTicketTransferTransported && branchId === entry.toBranchId" type="button" class="btn btn-save mx-1" @click="confirmTicket(entry.id)">
                                                                    <i class="fa fa-share"></i>
                                                                    {{$t('message.TransferIndex.button.process')}}
                                                                </button>
                                                                  </AppPermission>
                                                            <button type="button" class="btn btn-secondary text-white mx-1" @click="getTemplatePrint(entry)">
                                                                <i class="fa fa-print" aria-hidden="true"></i>
                                                                {{$t('message.TransferIndex.button.print')}}
                                                            </button>
                                                            <button type="button" class="btn btn-secondary text-white mx-1"  @click="showModalMultipleProductsBarcode('transfer', entry)">
                                                                <i class="fa fa-barcode"></i>
                                                                {{$t('message.TransferIndex.button.print_barcode')}}
                                                            </button> 
                                                            <AppPermission :permission="this.$permissions.transfers.export?? '' ">
                                                                <button type="button" class="btn btn-secondary text-white mx-1"  @click="exTransfersById(entry.id)">
                                                                    <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                    {{$t('message.TransferIndex.button.export')}}
                                                                </button>
                                                            </AppPermission>
                                                            
                                                             <AppPermission :permission="this.$permissions.transfers.create?? '' ">
                                                            <button type="button" class="btn btn-save mx-1" @click="copyTransferById(entry.id)" v-if="this.branchId == entry.fromBranchId">
                                                                <i class="fa fa-copy" aria-hidden="true"></i>
                                                                {{$t('message.TransferIndex.button.copy')}}
                                                            </button>
                                                            </AppPermission>
                                                             <AppPermission :permission="this.$permissions.transfers.cancel?? '' ">
                                                            <button v-if="entry.statusOrigin == $constant.statusTransferDraft || entry.statusOrigin == $constant.statusTransferInProgress" type="button" class="btn btn-danger mx-1"  @click="ShowModalTransferAbort(entry.id, entry.code)">
                                                                <i class="fa fa-trash-alt"></i>
                                                                {{$t('message.TransferIndex.button.cancel')}}
                                                            </button>
                                                            </AppPermission>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__footer-detail" v-if="transferActive === entry.id"></tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row ml-auto mr-auto" v-if="entries.length == 0 && !this.loading" id="notFound">
                                <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                    <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                    <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                    <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalTransferAbort ref="ModalTransferAbort"  @inputData="load()"/>
    <ModalMultipleProductsBarcode ref="ModalMultipleProductsBarcode" />
    <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
    <PrintTemplate ref="PrintTemplate" :editorDataClone="this.editorDataClone"/>
    <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
    <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
    <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/> 
    </AppPermission>
</template>

<script>
    import {$alert, $get, $post, clone, setArray, getSearchQuery, removeVietnameseTones} from "@/lib/utils";
    import http from '@/services/api';
    import ModalTransferAbort from './ModalTransferAbort'
    // new import
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import SelectField from "../../components/SelectField.vue";
    import Pagination from "@/components/Pagination";
    import emitter from "@/lib/emitter";
    import TableSortField from "@/components/TableSortField";
    import store from "@/store";
    import router from "@/router/index";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import DateRangePicker from "@/components/DateRangePicker";
    import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
    import SelectSearch from "@/components/SelectSearch.vue";     
    import ModalMultipleProductsBarcode from '../products/tabs/ModalMultipleProductsBarcode';
    import printTransactionMixin from "@/printTransactionMixin";
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';
    import PrintTemplate from '@/components/PrintTemplate';
    import permissionMixin from '@/permissionMixin';
    import AppPermission from '@/components/AppPermission.vue';
    import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
    import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
    import { mapGetters } from "vuex";
    import exportMixin from '@/exportMixin';
    import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
    export default {
        name: "TransferIndex",
        mixins: [printTransactionMixin, permissionMixin, exportMixin],
        components: {
            TableSortField, 
            InputQuickSearch, 
            SelectField, 
            Pagination, 
            InputSelectSearch, 
            DateRangePicker, 
            InputDateRangePicker, 
            SelectSearch,
            ModalTransferAbort, 
            ModalMultipleProductsBarcode,
            ModalSelectPrintTemplate,
            PrintTemplate, AppPermission,
            ModalSelectSendMailAndZaloTemplate,
            ModalSelectSendZaloGroupTemplate,
            ModalSelectOptionExport
        },        
        mounted() {
            document.title = this.$t('message.TransferIndex.title') + ' | OmiPos';
            const fields = localStorage.getItem('transferSelectedFields_' + this.user.id);            
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            const query = clone(this.$route.query);
            if(query['code_transfer'] != null){
                this.loadDetail();
            }else{
                this.clearFilter();
            }
            window.addEventListener('scroll', this.onScroll);
        },
        data() {
            const query = getSearchQuery();
            const globalConfig = store.state.GlobalConfig;
            return {
                exEntries:[],
                filter: {
                    keyword: query.keyword,
                    type: [],
                    status: [],
                    toBranchId: '',
                    fromBranchId: '',
                    statusReceive: '',
                    typeRecord: '',
                    time: null,
                    timePicker: null,
                    transferToBranch: null,
                    requestToBranch: null,
                    code_transfer: '',
                    product_name: '',
                    product_code: '',
                    note: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                numberRecord: 15,
                statusOptions: [],
                fixed: false,
                user: store.state.Auth.user,
                arrayChecked: [],
                loading: false,
                defaultTransfer:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                },
                transferActive: null,
                fieldTransferDetail : [],
                branchId: store.state.CurrentBranch.id,
                branches: globalConfig.branches,
                branchEdit: null,
                sortTime: 0,
                optionSelect: {
                    transferBranch: false,
                    requestBranch: false,
                    deliveryDate: false,
                    receivedDate: false,
                },
                listStatusTransfer: [],
                optionSttGive: [
                    {id: 0, value: 0, label: this.$t('message.common.all')},
                    {id: 1, value: 1, label: this.$t('message.common.joint')},
                    {id: 2, value: 2, label: this.$t('message.common.noJoint')},
                ],
                filterQuery: {
                    status: [],
                    receiveStatus: 0,
                },
                isFilter: false,
            }
        },
        methods: {
            async sendMailAndZalo() {
                this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabTransfers, this.$constant.typeTransferProducts, this.arrayChecked);
            },
            async sendZalo (entry = null) {
                if (entry) {
                    this.arrayChecked = [entry.id];
                }
                const typeSend = entry ? this.$constant.typeSendZaloZNS : this.$constant.typeSendZaloGroup;
                const phone = null;
                this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabTransfers, this.$constant.typeTransferProducts, this.arrayChecked, typeSend, phone);
            },
            async getTemplatePrint(entry) {  
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: this.$constant.TabTransfers});               
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            async updateContent(data){
                this.content = data.content;
                let dataPrint = this.formatproductPrintCommon(data.dataPrint);
                await this.print(dataPrint);          
            },
            async print(entry) { 
                this.editorDataClone =  '';
                entry.typePrint = this.$constant.TypePrintTransfer;
                let dataPrint = this.dataPrint(entry); 
                this.editorDataClone = await this.renderData(dataPrint);
                this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);
            },

        // In tem mã
        // -----------------------------------------------------------------
            getProduct(details){
                
                let productId = [];
                details.forEach(item => {
                    productId.push(item.productId);
                });            
                return productId;
            },
            async showModalMultipleProductsBarcode(obj, entry){  
             
                let transferDetails  = [];
                entry.details.forEach(detail => {
                    let item = {
                        id: detail.product.id,
                        code: detail.product.code,
                        name: detail.product.name,                        
                        quantity: detail.sendQuantity,
                        sendPrice:detail.sendPrice,
                        unitName: detail.product_unit_main.unitName,
                    };
                    transferDetails.push(item);
                });
                this.$refs.ModalMultipleProductsBarcode.show(obj, transferDetails);
            },
            // -----------------------------------------------------------------
            confirmTicket(ticketId) {
                router.push({name: 'ConfirmTransferBranch', params: {id: ticketId}, query: {action: this.$constant.actionConfirmTransfer}});
            },
            createTicket() {
                router.push({name: 'CreateTransferBranch'});
            },
            editTicket(ticketId) {
                router.push({name: 'UpdateTransferBranch', params: {id: ticketId}});
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/transfers/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = null;
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [1,2,3];
                    }else if(key == 'receiveStatus'){
                        this.filterQuery[key] = 0;
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                Object.keys(this.optionSelect).forEach(key => {
                    this.optionSelect[key] = false;
                });
                emitter.emit("clearQuickSearch");
                emitter.emit("clearDateRangePicker");
                emitter.emit("clearSelectSearchValue");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.sortTime = 0;
                this.transferActive = null;
                this.isFilter = false;
                await this.load();
            },
            async filterTime(time = null, option){
                const query = clone(this.filterQuery);
                delete query['time'];
                if(time){
                    query['time'] = time;
                    query['page'] = 1;
                    if(option == 1){
                        emitter.emit("clearDataDateRangePicker");
                        this.sortTime = 1;
                        this.filter.timePicker = time;
                        
                    }else{
                        this.filter.timePicker = null;
                        this.sortTime = 0;
                        this.filter.time = time;
                    }
                }
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            refreshDatePicker(option){
                switch (option) {
                    case 0:
                        this.filterTime(this.filter.time);
                        break;
                    case 1:
                        this.filterTime(this.filter.timePicker, 1);
                        break;
                }
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    // let arr = query['status'];
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                    // this.filterQuery.status = arr;
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                await this.load();


            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/transfers/index', {...this.$route.query, ...this.filterQuery, ...this.optionSelect, record: this.numberRecord});
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }

                //Phục vụ cho export tổng quang không qua phân trang
                this.exEntries = [];  
                
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                // this.paginate = res.result.data.paginate;
                // this.entries = res.result.data.entries;
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                this.listStatusTransfer = res.result.data.listStatus;
                this.fieldTransferDetail = res.result.data.listFieldDetailProduct ?? [];
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            
            },
            async loadDetail() {
                emitter.emit("clearQuickSearch");
                emitter.emit("clearDateRangePicker");
                emitter.emit("clearSelectSearchValue");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.sortTime = 0;
                this.transferActive = null;
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailTransfer(entry.id, entry);
                });
            },
            //Xuất file
            getOSByID(entries){             
                return entries;
            },    
            onScroll() {
                let height = window.scrollY;
                if(height > 120){
                    //Giữ vị trí cho thông báo lỗi    
                    this.fixed = true;
                }else{
                    this.fixed = false;
                }
                emitter.emit("position", 0);
            },
            selectOptionExport(method = 'exOverview', totalRecord = 0, totalRecords = 5000){
                let params = { method: method };
                totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
                this.$refs.ModalSelectOptionExport.show(params, totalRecord, this.fields, totalRecords);
            },
            async exportFiles(params){
                if (params.method) {
                    this[params.method](params.firstRecordIndex);
                }
            },
            async exOverview(firstRecordIndex = 0) {
                emitter.emit("onLoading");
                const res = await http.download('/v1/transfers/exOverview', {
                    fields: this.fields, 
                    firstRecordIndex: firstRecordIndex,
                    ...this.$route.query, 
                    ...this.filterQuery, 
                    ...this.optionSelect,
                });            
                this.returnDataExport(res);
            },    
            async exSelectOverview() {                
                emitter.emit("onLoading");
                const res = await http.download('/v1/transfers/exOverview', {
                    fields: this.fields, 
                    id: this.arrayChecked
                });
                this.returnDataExport(res);
            },  
            async exSelectDetails() {
                emitter.emit("onLoading");
                const res = await http.download('/v1/transfers/exDetails', {
                    fields: this.fields, 
                    id: this.arrayChecked,
                });
                this.returnDataExport(res);
            },
            async exDetails(firstRecordIndex = 0) {
                await $post('/v1/transfers/exDetails', {
                    fields: this.fields,
                    ...this.$route.query,
                    ...this.filterQuery,
                    ...this.optionSelect,
                    firstRecordIndex: firstRecordIndex,
                });
                emitter.emit('showModalExport');

                // emitter.emit("onLoading");
                // const res = await http.download('/v1/transfers/exDetails', {
                //     fields: this.fields, 
                //     ...this.$route.query, 
                //     ...this.filterQuery, 
                //     ...this.optionSelect,
                //     firstRecordIndex: firstRecordIndex,
                // });
                // this.returnDataExport(res);    
            },        
            detailTransfer(id, entry){
                if (id === this.transferActive) {
                    this.transferActive = null;
                } else {
                    this.transferActive = id;
                }
                this.defaultTransfer.filter = this.defaultTransfer.all = entry.details;
                this.setPaginate();
            },
            onInputFilterTransferDetail(field){
                this.paginateDetail.currentPage = 1;
                if(this.defaultTransfer.searchCode.length == 0 && this.defaultTransfer.searchName.length == 0){
                    this.defaultTransfer.filter = this.defaultTransfer.all;
                    this.setPaginate();
                    return;
                }
                let arr = [];
                switch (field) {
                    case 'searchCode':
                        for (let index = 0; index < this.defaultTransfer.all.length; index++) {
                            if (this.defaultTransfer.all[index] != null && removeVietnameseTones(this.defaultTransfer.all[index].productCode.toLowerCase()).indexOf(removeVietnameseTones(this.defaultTransfer.searchCode.toLowerCase())) > -1){
                                arr.push(this.defaultTransfer.all[index]);
                            }
                        }
                        break;
                    case 'searchName':
                        for (let index = 0; index < this.defaultTransfer.all.length; index++) {
                            if (this.defaultTransfer.all[index] != null && removeVietnameseTones(this.defaultTransfer.all[index].productName.toLowerCase()).indexOf(removeVietnameseTones(this.defaultTransfer.searchName.toLowerCase())) > -1){
                                arr.push(this.defaultTransfer.all[index]);
                            }
                        }
                        break;
                }
                this.defaultTransfer.filter = arr;
                this.setPaginate();
            },
            setPaginate(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.defaultTransfer.filter.length / 10);
                this.paginateDetail.totalRecord = this.defaultTransfer.filter.length;
            },
            async exTransfersById(id){
                emitter.emit("onLoading");
                const res = await http.download('/v1/transfers/exById/' + id, {...this.fields});                
                this.returnDataExport(res);
            },
            resetValue(branchId){
                this.branchEdit = branchId;
            },
            async quickSaveTransfer(entry){
                if(entry.fromBranchId == this.branchEdit){
                    const data = {
                        code: 422,
                        message: this.$t('message.common.inValidReceiveBranch'),
                    }
                    $alert(data);
                    return;
                }else{
                    if(entry.sendDescription?.length > 255 && this.branchId == entry.fromBranchId){
                        $alert({
                            code: 422,
                            message: this.$t('message.common.maxLengthTransferBranchNote'),
                        });
                    }else if(entry.receiveDescription?.length > 255 && this.branchId == entry.toBranchId){
                        $alert({
                            code: 422,
                            message: this.$t('message.common.maxLengthReceiveBranchNote'),
                        });
                    }else{
                        const res = await $post('/v1/transfers/quick-save', {
                            id: entry.id, 
                            toBranchId: this.branchEdit != null ? this.branchEdit : entry.toBranchId, 
                            sendDescription: entry.sendDescription, 
                            receiveDescription: entry.receiveDescription
                        });
                        if(res.result.status == true){
                            $alert({
                                code: 200,
                                message: this.$t('message.common.updateTransferSuccess'),
                            });
                            this.load();
                        }
                    }
                    
                }
            },
            changeSelect(obj){
                this.optionSelect[obj] = !this.optionSelect[obj];
                const query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.load();
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            copyTransferById(id){                        
                 router.push({ name: 'CopyTransferBranch', query: { id: id, type: 'copy' }});               
            },  
            ShowModalTransferAbort(id, code){
                this.$refs.ModalTransferAbort.show(id, code);                
            },      
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
        },
        computed: {
            ...mapGetters({
                statusEmail: 'settingStore/email',
            }),
        },
    }
</script>

<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.noteStyle {
    width: 100% !important;
    height: 80px !important;
    border: 0;
    border-radius: 0;
}

.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
</style>
