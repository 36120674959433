const messages = {
    vi: {
        message: {
            // Header + common
            headers: {
                branch: "Chi nhánh",
                setupShop: "Thiết lập cửa hàng",
                print_template_manage: "Quản lý mẫu in",
                branch_manage: "Quản lý chi nhánh",
                promotion_manage: "Quản lý khuyến mại",
                coupon_manage: "Quản lý coupon",
                voucher_manage: "Quản lý voucher",
                role_manage: "Quản lý vai trò",
                permission_manage: "Quản lý phân quyền",
                history: "Lịch sử thao tác",
                email_template_manage: "Quản lý mẫu gửi email",
                logout: "Đăng xuất",
                dashboard: "Tổng quan",
                list: "Danh mục",
                products: "Hàng hóa",
                product_list: "Danh sách hàng hóa",
                categories: "Nhóm hàng hóa",
                units: "Đơn vị hàng hóa",
                customers: "Khách hàng",
                customer_list: "Danh sách khách hàng",
                customer_group: "Nhóm khách hàng",
                suppliers: "Nhà cung cấp",
                supplier_list: "Danh sách nhà cung cấp",
                supplier_group: "Nhóm nhà cung cấp",
                employees: "Nhân viên",
                employee_list: "Danh sách nhân viên",
                departments: "Phòng ban",
                transporters: "Đối tác giao hàng",
                sale_channels: "Kênh bán",
                price_list: "Danh mục bảng giá",
                setting_price: "Thiết lập giá",
                price_list_detail: "Chi tiết bảng giá",
                location_list: "Danh mục vị trí",
                transaction: "Giao dịch",
                purchase_products: "Nhập hàng",
                plan_purchase_order: "Dự trù nhập hàng",
                inventory_plan: "Dự trù chi tiết",
                plan_purchase: "Dự trù nhập mua",
                order_suppliers: "Đặt hàng nhập",
                purchase_orders: "Nhập hàng NCC",
                return_purchase_orders: "Trả hàng nhập",
                settlement_advances: "Tạm ứng quyết toán",
                sales: "Bán hàng",
                buy: "Mua hàng",
                orders: "Đặt hàng bán",
                invoices: "Hóa đơn",
                returns: "Trả hàng bán",
                request_refund: "Đề nghị hoàn hàng",
                warehouse_transfer: "Chuyển kho",
                transfers: "Chuyển kho",
                requests: "Phiếu đặt hàng chuyển",
                locations: "Nghiệp vụ vị trí",
                ticket_import_location: "Xếp sản phẩm vào vị trí",
                ticket_export_location: "Lấy sản phẩm khỏi vị trí",
                ticket_transfer_location: "Điều chuyển vị trí",
                cash_book: "Sổ quỹ",
                cash_book_money: "Sổ quỹ tiền mặt",
                bank_book: "Quỹ ngân hàng",
                reports: "Báo cáo",
                sell_online: "Bán hàng online",
                delivery: "Vận đơn",
                ReportSaleDetails: "Sổ chi tiết bán hàng",
                ReportSaleChannel: "Báo cáo bán hàng theo kênh bán",
                ReportSaleEmployee: "Báo cáo bán hàng theo nhân viên",
                ReportSaleBranch: "Báo cáo bán hàng theo chi nhánh",
                ReportRevenueProduct: "Phân tích chi tiết doanh thu SP/nhóm SP theo thời gian",
                ReportRevenueInBranch: "Phân tích chi tiết doanh thu SP/nhóm SP theo chi nhánh",
                ReportRevenuePriceList: "Phân tích chi tiết doanh thu SP/nhóm SP theo bảng giá",
                ReportRevenueCustomerGroup: "Phân tích chi tiết doanh thu SP/nhóm SP theo nhóm khách hàng",
                ReportPurchaseDetail: "Sổ chi tiết mua hàng",
                ReportStockDetail: "Sổ chi tiết hàng hóa",
                ReportInventories: "Báo cáo nhập xuất tồn",
                ReportInventoriesDetail: "Báo cáo nhập xuất tồn chi tiết",
                ReportValueBranch: "Báo cáo giá trị kho",
                ReportConsignment: "Báo cáo tồn kho theo hạn sử dụng",
                ReportStockLocation: "Báo cáo tồn kho theo vị trí",
                ReportStockLocationConsignment: "Báo cáo tồn kho theo lô date và vị trí",
                manage: "Quản lý"
            },
            button: {
                confirm: "Đồng ý",
                skip: "Bỏ qua",
                cancel: "Hủy bỏ",
                save: "Lưu",
                remove: "Xóa",
                removeFilter: "Xóa lọc",
                action: "Thao tác",
                import:{
                    name: "Import",                    
                    selectFile:"Chọn File dữ liệu",
                    action:"Thực hiện",
                },
                export: {
                    name: "Xuất file",
                },
                copy: {
                    name: "Sao chép",
    
                },
                print: {
                    name: "In phiếu",
                },
                sendMailText: "Gửi email",                
                export_all: "Xuất file danh sách tổng quan",
                export_detail: "Xuất file danh sách chi tiết",
                file_all: "File tổng quan",
                file_detail: "File chi tiết",
                invoiceAdd: "Tạo hóa đơn",
                close: "Đóng",
                choose: "Chọn",
                saveDraft: "Lưu tạm",
                addInvoice: "Thêm mới hóa đơn",
                addOrder: "Thêm mới đặt hàng",
                selectInvoiceForReturn: "Chọn hóa đơn trả hàng",
                returnFast: "Trả nhanh",
                finished: "Xong",
                detail: "Chi tiết",
            },
            common: {
                quantityVoucherNoti: "SL voucher: ",
                quantityPromotionNoti: "SL CTKM: ",
                quantityCouponNoti: "SL coupon: ",
                quantityProductNoti: "SL mặt hàng: ",
                notFound: "Không tồn tại kết quả được tìm kiếm",
                notData: "Chưa có dữ liệu",
                branch: "Chi nhánh",
                numberRecord: "Số bản ghi",
                placeholder: {
                    branch: "Chọn chi nhánh"
                },
                time: "Thời gian",
                status: "Trạng thái",
                created_by: "Người tạo",
                male: "Nam",
                female: "Nữ",
                inValidFormatFile: "File upload phải đúng định dạng xlsx, xls",
                inValidFormatTime: "Giờ không đúng định dạng",
                vnd: "VNĐ",
                percent: "%",
                choose: "Chọn",
                chooseImage: "Chọn ảnh",
                upload: "Tải lên",
                download: "Tải",
                up: "lên",
                uploadFromUrl: "Tải ảnh từ URL",
                chooseFile: "Chọn file từ thư viện",
                manageFile: "Quản lý file",
                enterUrl: "Nhập URL",
                chooseUrl: "Vui lòng chọn url",
                maxThreeImage: "Vui lòng chọn tối đa 3 ảnh",
                chooseImagePlease: "Vui lòng chọn ảnh",
                maxNumberImage: "Vui lòng chọn tối đa {number} ảnh",
                import: "Import",
                export: "Xuất file",
                date: "ngày",
                downloadDefaultFile: "Tải về file mẫu:",
                excelFile: "Excel file",
                have: "Có",
                selected: "được chọn",
                image: "ảnh",
                errorServer: "Lỗi server",
                confirm: "Xác nhận",
                confirmCancelChange: "Bạn có muốn hủy thay đổi?",
                product: "sản phẩm",
                applyFormulaForAll: "Áp dụng công thức cho các sản phẩm đã có giá trong bảng giá?",
                draft: "Phiếu tạm",
                planPurchase: "Dự trù nhập mua",
                planDetail: "Dự trù",
                existProduct: "Hàng hóa đã tồn tại!",
                existPlanDetail: "Phiếu dự trù chi tiết đã tồn tại!",
                searchProduct: "Tìm kiếm theo mã hoặc tên hàng hóa",
                searchPlanDetail: "Tìm kiếm theo mã hoặc tên dự trù chi tiết",
                service: "Dịch vụ",
                combo: "Combo",
                productValue: "Giá trị hàng",
                all: "Tất cả",
                active: "Hoạt động",
                inActive: "Ngừng hoạt động",
                province: "Tỉnh/Thành phố",
                district: "Quận/Huyện",
                ward: "Phường/Xã",
                effect: "Còn hiệu lực",
                expire: "Hết hiệu lực",
                createdAt: "Ngày tạo",
                statusCancel: "Đã hủy",
                statusReturned: "Đã trả hàng",
                unpaid: "Chưa thanh toán",
                paid: "Hoàn thành thanh toán",
                partialPayment: "Thanh toán một phần",
                ticket: "phiếu",
                STT: "STT",
                searchSupplier: "Tìm nhà cung cấp",
                autocompleteCode: "Mã phiếu tự động",
                cash: "Tiền mặt",
                card: "Thẻ",
                transferPayment: "Chuyển khoản",
                none: "Không có",
                received: "Đã nhận",
                notReceived: "Chưa nhận",
                choosePrintTemplate: "Chọn mẫu in",
                receiverDefault: "Người nhận mặc định",
                sendToGroup: "Gửi tới Nhóm",
                chooseZaloTemplate: "Chọn mẫu zalo",
                sendZNS: "Gửi ZNS",
                cc: "CC",
                searchOrderSupplier: "Tìm phiếu đặt hàng nhập",
                createPurchaseOrderSuccess: "Tạo mới phiếu nhập hàng thành công",
                updatePurchaseOrderSuccess: "Cập nhật phiếu nhập hàng thành công",
                emptyBatch: "Sản phẩm {name} chưa được chọn lô date.",
                invalidPurchaseQuantity: "Số lượng sản phẩm {name} không được vượt quá số lượng đặt hàng.",
                invalidQuantity: "Số lượng sản phẩm {name} không được nhỏ hơn hoặc bằng không.",
                isEmptyPO: "Phiếu nhập hàng đang trống",
                havePODraft: "Một phiếu đặt chỉ được tồn tại một phiếu nhập tạm.",
                haveReturnPODraft: "Một phiếu nhập chỉ được tồn tại một phiếu trả hàng tạm.",
                confirmChangePo: "Phiếu hàng nhập này chưa được lưu bạn có muốn thay đổi đơn đặt hàng khác không",
                searchPO: "Tìm kiếm theo mã phiếu nhập",
                invalidReturnPOQuantity: "Số lượng trả hàng của sản phẩm {name} không được lớn hơn số lượng nhập hàng.",
                createReturnPOSuccess: "Tạo mới phiếu trả hàng thành công",
                updateReturnPOSuccess: "Cập nhật phiếu trả hàng thành công",
                saleModeFast: "Bán nhanh",
                saleModeTransfer: "Bán giao hàng",
                returned: "Hóa đơn đã được trả!",
                confirmEndOrder: "Bạn vừa tạo hóa đơn từ đơn đặt hàng {code}. Bạn sẽ không xử lý tiếp với những hàng hóa chưa lấy hết và kết thúc đơn đặt hàng ngay bây giờ?",
                processOrder: "Xử lý đặt hàng",
                orderNote: "Theo ghi chú đặt hàng",
                confirmCloseTab: "Thông tin của {tab} sẽ không được lưu lại. Bạn có chắc chắn muốn đóng không?",
                stamp: "Tem nhãn",
                returnQuantity: "SL trả",
                shortQuantity: "SL",
                orderQuantity: "SL đặt",
                displayHSCode: "Hiển thị HS code",
                displayTax: "Hiển thị Thuế suất",
                promotionInvoice: "Khuyến mại hóa đơn",
                discountProduct: "Giảm giá hàng hóa",
                noProduct: "Không có sản phẩm",
                businessMan: "Nhân viên kinh doanh",
                selectPriceBook: "Chọn bảng giá áp dụng",
                totalCostPrice: "Tổng giá gốc hàng mua",
                KM: "KM",
                couponCode: "mã coupon...",
                voucherCode: "mã voucher...",
                returnCustomer: "Tiền trả khách",
                customerPays: "Khách thanh toán",
                ExcessMoneyPaidByCustomer: "Tiền thừa khách trả",
                ExcessMoneyReturnedToCustomers: "Tiền thừa trả khách",
                RemainingUnpaid: "Còn lại chưa thanh toán",
                ExpectedDelivery: "Dự kiến giao hàng",
                changeInforInvoice: "Thay đổi thông tin xuất hóa đơn điện tử",
                soldByName: "Họ và tên người mua",
                enterSoldByName: "Nhập họ và tên người mua",
                enterUnitName: "Nhập tên đơn vị",
                noteForDriver: "Ghi chú cho tài xế",
                collection: "Thu hộ(COD)",
                cm: "cm",
                searchTransporter: "Tìm đối tác giao hàng",
                normal: "Thường",
                fast: "Nhanh",
                duringTheDay: "Trong ngày",
                linkToGHN: "Cần liên kết với GHN.",
                linkToAhaMove: "Cần liên kết với Ahamove.",
                linkToViettelPost: "Cần liên kết với ViettelPost.",
                loginNow: "Đăng nhập ngay!",
                deliveryPromotion: "Khuyến mãi giao hàng",
                theSenderPaysTheFee: "Người gửi trả phí",
                paymentViaApp: "Thanh toán qua App",
                heavyThings: "Hàng cồng kềnh",
                declarePrice: "Khai giá hàng hóa",
                maximumDeclaredValue: "giá trị tối đa khai giá là",
                million: "triệu",
                sendSMS: "Gửi SMS cho người nhận",
                fragile: "Hàng dễ vỡ",
                atThePostOffice: "Gửi hàng tại bưu cục",
                cod: "COD",
                cannotBeSmaller: "Không được nhỏ hơn",
                cannotBeLarger: "Không được lớn hơn",
                descriptionDetail: "Mô tả chi tiết",
                canSell: "Có thể bán",
                totalCustomerOrder: "Tổng KH đặt",
                listPromotion: "Danh sách khuyến mại",
                promotions: "Chương trình khuyến mại",
                formOfPromotion: "Hình thức khuyến mại",
                selectPromotionItems: "Chọn hàng/quà khuyến mại",
                buy: "Mua",
                selectProductPromotion: "Chọn hàng khuyến mại",
                searchProductPromotion: "Tìm sản phẩm khuyến mại",
                haveNotSelectProduct: "Chưa chọn sản phẩm",
                cannotMergePromotion: "Không cho phép áp dụng gộp chương trình khuyến mại, vui lòng không chọn thêm.",
                selectProductQuantity: "Vui lòng chọn số lượng sản phẩm",
                appliedPromotionOfCustomer: "Khách hàng này đã từng được áp dụng các chương trình khuyến mại sau",
                confirmApplyContinue: "Bạn có muốn áp dụng tiếp các chương trình khuyến mại đó không?",
                loginAhamove: "ĐĂNG NHẬP AHAMOVE",
                vietnam: "VN",
                apiKey: "API Key",
                enterApiKey: "Nhập mã API",
                newAddress: "Địa chỉ mới",
                showProduct: "Cho xem hàng",
                notShowProduct: "Không cho xem hàng",
                notEnoughInventory: "Tồn kho của sản phẩm {name} không đủ.",
                emptyOrder: "Đơn hàng trống .",
                cannotApplyVoucher: "Mã voucher không tồn tại hoặc không đủ điều kiện để áp dụng.",
                usedVoucher: "Voucher đã được sử dụng",
                cannotApplyVouchers: "Không áp dụng nhiều mã voucher trong cùng 1 đợt phát hành.",
                cannotApplyCoupon: "Mã coupon không tồn tại hoặc không đủ điều kiện để áp dụng.",
                confirmPayment: "Bạn chưa nhập thông tin khách hàng. Hệ thống không theo dõi công nợ với khách lẻ. Bạn có chắc chắn muốn thanh toán?",
                notEnoughMoney: "Tiền thanh toán không đủ",
                emptyInvoice: "Phiếu hóa đơn đang trống",
                selectWeightProduct: "Vui lòng chọn đơn vị trọng lượng",
                notExceed30Kg: "Khối lượng không được quá 30kg",
                notExceed10Million: "Số tiền COD không được vượt quá 10.000.000",
                cannotLessThan0: "Số tiền COD không được nhỏ hơn 0",
                notExceed5Million: "Giá trị đơn không được vượt quá 5.000.000",
                notExceed150Cm: "Kích thước không được vượt quá 150cm",
                addInvoiceSuccess: "Tạo hóa đơn thành công",
                emptyReturn: "Phiếu trả hàng đang trống",
                addOrderSuccess: "Đặt hàng thành công",
                updateOrderSuccess: "Cập nhật đặt hàng thành công",
                updateInvoiceSuccess: "Cập nhật hóa đơn thành công",
                confirmReturn: "Bạn chưa nhập thông tin khách hàng. Hệ thống không theo dõi công nợ với khách lẻ. Bạn có chắc chắn muốn trả hàng?",
                quantityReturnGreaterThan0: "Số lượng hàng hóa trả phải lớn hơn 0",
                addReturnSuccess: "Tạo phiếu trả hàng thành công",
                notEnoughInventorySystem: "Số lượng bán vượt quá tồn kho hệ thống!",
                first: "Đầu",
                last: "Cuối",
                netRevenue: "Theo doanh thu thuần",
                accordingToQuantity: "Theo số lượng",
                selectCoupon: "Chọn coupon",
                selectCouponCode: "Chọn mã Coupon",
                usedQuantity: "SL đã sử dụng",
                remainingQuantity: "SL còn lại",
                fromQuantity: "Số lượng từ",
                quantityStartFrom1: "Số lượng phải bắt đầu từ 1",
                inValidQuantity: "Số lượng không hợp lệ",
                orderCode: "Mã đơn hàng",
                orderDate: "Ngày đặt hàng",
                addOrderWhenNotEnoughInventory: "Cho phép đặt hàng khi hết tồn kho",
                sellWhenOrdered: "Bán hàng, Chuyển hàng khi sản phẩm đã được Đặt hàng",
                loginMisaTitle: "Thiết lập kết nối hóa đơn điện tử",
                loginMisa: "ĐĂNG NHẬP MISA",
                inValidInforLogin: "Thông tin đăng nhập không chính xác",
                loginMisaSuccess: "Đăng nhập misa thành công",
                existMisaAccount: "Hệ thống đã tồn tại tài khoản misa",
                level: "Hạn mức",
                maxDebt: "Số tiền tối đa khách được nợ",
                warningWhenTransaction: "Nếu khách hàng nợ quá số tiền quy định, hệ thống sẽ cảnh báo hoặc chặn khi giao dịch",
                numberOfDebtDate: "Số ngày nợ",
                maxNumberOfDebtDate: "Số ngày tối đa được nợ",
                warningWhenTransaction2: "Nếu khách hàng nợ quá ngày quy định, hệ thống sẽ cảnh báo hoặc chặn khi giao dịch",
                dayFrom: "ngày tính từ",
                addDebt: "Cho phép khách hàng nợ thêm nếu vượt quá hạn mức",
                addDebtTooltip: "Khi phát sinh các giao dịch làm cho số tiền nợ của khách vượt quá hạn mức(như tạo Hóa đơn, lập Phiếu chi), hệ thống sẽ chặn không cho thực hiện tiếp",
                addDebtNote: "Lưu ý: Hệ thống không chặn khi đồng bộ giao dịch offline, đồng bộ giao dịch từ sàn thương mại điện tử, hóa đơn có COD, hủy hóa đơn, hủy phiếu thanh toán",
                updatePromotion: "Cập nhật chương trình khuyến mại",
                addPromotion: "Thêm chương trình khuyến mại",
                addConditional: "Thêm điều kiện",
                back: "Quay lại",
                cancelChange: "Hủy thay đổi",
                enterBatch: "Nhập lô, hạn sử dụng",
                addBatch: "Thêm mới lô, hạn sử dụng",
                selectBatch: "Chọn lô",
                addBatchFor: "Thêm lô cho",
                batch: "Lô",
                selectBatchExpire: "Chọn lô, hạn sử dụng",
                searchBatch: "Tìm lô, hạn sử dụng",
                notFoundBatch: "Không tìm thấy Lô phù hợp",
                quantityNotGreaterThan: "Số lượng không được lớn hơn",
                emptyProductBatch: "Bạn chưa nhập lô",
                inValidExpire: "Hạn sử dụng không hợp lệ",
                exprieNotLessThanToday: "Hạn sử dụng không được nhỏ hơn ngày hiện tại",
                existBatch: "Lô này đã tồn tại cho sản phẩm",
                uploadDate: "Ngày tải lên",
                fileName: "Tên file",
                fileExtension: "Định dạng",
                purchaseDate: "Ngày nhập hàng",
                searchInventoryPlan: "Tìm kiếm hàng hóa theo tên hoặc mã dự trù chi tiết",
                searchInvoiceCode: "Tìm kiếm mã hóa đơn",
                selectBranches: "Vui lòng chọn chi nhánh",
                addAttribute: "Tạo biến thể",
                attributeName: "Tên thuộc tính",
                addAttributeSuccess: "Tạo biến thuộc tính thành công",
                addDeliverySuccess: "Tạo vận đơn thành công",
                bank: "Ngân hàng",
                totalRecords: "Tổng số bản ghi",
                numberNewRecords: "Số bản ghi thêm mới",
                numberUpdatedRecords: "Số bản ghi cập nhật",
                numberErrorRecords: "Số bản ghi lỗi",
                noPermission: "Bạn không có quyền truy cập trang này",
                backToHome: "Về trang chủ",
                needPermission: "Bạn cần có quyền sau để truy cập",
                selectCategories: "Chọn nhóm sản phẩm",
                productQuantities: "Số sản phẩm",
                action: "Hành động",
                select: "Lựa chọn",
                addNewGroup: "Tạo nhóm mới",
                searchReturnPO: "Tìm phiếu trả hàng nhập",
                searchBAS: "Tìm phiếu BAS",
                inValidEmail: "Không đúng định dạng email",
                exist: "đã tồn tại",
                max20Attribute: "Giá trị max là 20 thuộc tính",
                removeAllSelected: "Xóa chọn tất cả",
                major: "Nghiệp vụ",
                setting: "Thiết lập",
                printTemplate: "Mẫu in",
                atBranch: "Tại chi nhánh",
                cancelCouponError: "Bạn chỉ hủy được những coupon chưa được phát hành. Các coupon sau không hợp lệ",
                confirmCancelCoupon: "Bạn chắc chắn phát hành danh sách coupon đã chọn?",
                cancelCouponSuccess: "Hủy coupon thành công",
                cancelCouponFailed: "Hủy coupon không thành công",
                addCoupons: "Thêm danh sách coupon",
                quantityCoupon: "Số lượng coupon",
                quantityCouponWillCreate: "Số lượng mã coupon được tạo ra",
                couponCodeLength: "Độ dài mã coupon",
                couponCodeLengthNote: "Độ dài coupon không được nhỏ hơn 8 và lớn hơn 15",
                reduce: "giảm",
                forInvoice: "cho hóa đơn",
                removeCoupon: "Xóa đợt phát hành coupon",
                confirmRemoveCoupon: "Hệ thống sẽ xóa hoàn toàn đợt phát hành coupon này, bạn có muốn thực hiện không?",
                removeCouponSuccess: "Xóa đợt phát hành coupon thành công",
                removeCouponFailed: "Đã có coupon được phát hành hoặc đã sử dụng. Bạn không thể xóa.",
                releaseCouponError: "Bạn chỉ phát hành được những coupon chưa được phát hành. Các coupon sau không hợp lệ",
                confirmReleaseCoupon: "Bạn chắc chắn phát hành danh sách coupon đã chọn?",
                releaseCouponSuccess: "Phát hành coupon thành công",
                releaseCouponFailed: "Phát hành coupon không thành công",
                fullname: "Họ tên",
                addressDetail: "Địa chỉ chi tiết",
                home: "Nhà riêng",
                enterGroupName: "Nhập tên nhóm",
                processingUpload: "Xử lý upload file",
                uploadFile: "Upload file",
                row: "Dòng",
                uploadFileSuccess: "Tải lên thành công",
                cancelDeliverySuccess: "Hủy vận đơn thành công",
                cancelDeliveryFailed: "Trạng thái đơn hàng không được hủy",
                updateBASSuccess: "Phiếu {code} được cập nhật thành công",
                updateBASFailed: "Phiếu {code} được cập nhật không thành công",
                cancelBASFailed: "Hủy phiếu thất bại",
                removeBASSuccess: "Xóa phiếu thành công",
                removeBASFailed: "Xóa phiếu thất bại",
                confirmRemoveBAS: "Bạn có chắc chắn muốn xóa phiếu {code} không ?",
                confirmCancelBAS: "Bạn có chắc chắn muốn hủy phiếu {code} không ?",
                notFoundTicket: "Không tìm thấy phiếu",
                totalQuantityOrder: "SL đặt NCC",
                reportCode: "Mã báo cáo",
                confirmCancelInvoice: "Bạn có chắc chắn muốn hủy phiếu hóa đơn {code} không?",
                cancelInvoiceSuccess: "Hủy phiếu hóa đơn {code} thành công",
                cancelInvoiceFailed: "Hủy phiếu hóa đơn {code} thất bại",
                uploadFileInvoice: "Tạo hóa đơn từ file dữ liệu",
                delivery: "Giao hàng",
                firstCharacterInvoice: "Mã hóa đơn luôn bắt đầu bằng cụm từ 'HD'. Nếu bạn không nhập, hệ thống sẽ tự động thêm vào để đảm bảo nguyên tắc trên.",
                maxLengthInvoiceUpload: "Hệ thống cho phép import tối đa 1.000 dòng mỗi lần.",
                ensureInventoryWhenUpdload: "Đảm bảo tồn kho của những hàng hóa liên quan vẫn đáp ứng đủ.",
                completeOrder: "Hoàn thành phiếu đặt hàng",
                confirmCompleteOrder: "Bạn có muốn hoàn thành phiếu đặt hàng {code} ?",
                completeOrderSuccess: "Hoàn thành phiếu đặt thành công",
                updateInvoiceFailed: "Cập nhật hóa đơn thất bại",
                confirmCancelOrderSupplier: "Bạn có chắc chắn hủy phiếu đặt hàng nhập {code} không?",
                cancelOrderSupplierSuccess: "Hủy phiếu đặt hàng nhập {code} thành công",
                cancelOrderSupplierFailed: "Hủy phiếu đặt hàng nhập {code} thất bại",
                closeOrderSupplier: "Bạn sẽ không thể tiếp tục xử lý phiếu đặt hàng nhập mặc dù vẫn còn hàng chưa nhập hết. Bạn có chắc chắn muốn kết thúc phiếu đặt hàng nhập không?",
                closeOrderSupplierSuccess: "Kết thúc phiếu đặt hàng nhập {code} thành công",
                closeOrderSupplierFailed: "Kết thúc phiếu đặt hàng nhập {code} thất bại",
                emptyUploadFile: "Chưa có bản ghi nào trong file upload",
                selectMethodDiscount: "Chọn phương thức giảm giá",
                priceDiscount: "Giá giảm",
                methodDiscount: "Phương thức giảm",
                confirmCancelOrder: "Bạn có chắc chắn muốn hủy phiếu đặt hàng {code} không?",
                cancelOrderSuccess: "Hủy phiếu đặt hàng {code} thành công",
                cancelOrderFailed: "Hủy phiếu đặt hàng {code} thất bại",
                selectStatus: "Chọn trạng thái",
                maxLengthProcessOrder: "Hệ thống chỉ cho phép xử lý tối đa 10 phiếu", 
                inValidExpectedDateOfTransfer: "Ngày giao dự kiến không được nhỏ hơn ngày hôm nay",
                updateOrderFailed: "Phiếu đặt hàng {code} cập nhật thất bại",
                updateOrderSuccessfully: "Phiếu đặt hàng {code} cập nhật thành công",
                roleName: "Tên vai trò",
                confirmCancelUpdateRole: "Bạn có muốn hủy cập nhật vai trò?",
                confirmCancelAddRole: "Bạn có muốn hủy thêm mới vai trò?",
                emptyPermission: "Bạn chưa chọn quyền",
                permission: "Phân quyền",
                role: "Vai trò",
                searchEmployee: "Theo mã, tên nhân viên, Tên đăng nhập",
                perrmissionUser: "Phân quyền cho người dùng",
                roles: "Danh sách vai trò",
                addRole: "Thêm vai trò mới",
                searchRole: "Tìm kiếm vai trò",
                searchBranch: "Tìm kiếm chi nhánh",
                assignRole: "Gán thêm vai trò",
                confirmLogoutUser: "Nếu người dùng {name} đang sử dụng hệ thống sẽ bị đăng xuất ngày lập tức để cập nhật lại vai trò. Bạn có muốn tiếp tục thực hiện cập nhật không",
                emptyBranchRole: "Vui lòng chọn chi nhánh, vai trò để phần quyền",
                duplicateBranchRole: "Dữ liệu vai trò và chi nhánh bị trùng",
                addPermissionSuccess: "Thêm mới phân quyền thành công",
                addPermissionFailed: "Thêm mới phân quyền không thành công",
                updatePermissionSuccess: "Cập nhật phân quyền thành công",
                updatePermissionFailed: "Cập nhật phân quyền thất bại",
                selectBranchRole: "Dòng {row}: Vui lòng chọn vai trò và chi nhánh",
                selectBranchPermission: "Dòng {row}: Vui lòng chọn chi nhánh",
                selectRolePermission: "Dòng {row}: Vui lòng chọn vai trò",
                confirmCopyProduct: "Bạn chắc chắn muốn sao chép sản phẩm này không?",
                copyProductSuccess: "Sao chép hàng hóa thành công",
                emptyProductSelected: "Chưa có sản phẩm nào được chọn",
                paper3: "Mẫu giấy cuộn 3 nhãn (Khổ giấy in nhãn 104x22mm / 4.2 x 0.9 inch)",
                paper12: "Mẫu giấy 12 nhãn (Khổ giấy in nhãn Tomy 103 202x162mm)",
                paper2: "Mẫu giấy cuộn 2 nhãn (Khổ giấy in nhãn 72x22mm)",
                paper65: "Mẫu giấy 65 nhãn (Khổ giấy in nhãn A4 Tomy 145)",
                paper2_1: "Mẫu giấy cuộn 2 nhãn (Khổ giấy in nhãn 74x22mm)",
                paperJewelry: "Mẫu tem hàng trang sức (Khổ giấy in nhãn 75x10mm / 2.75in x 0.4in)",
                printNote: "Lưu ý: Trong trường hợp Mã vạch được in không đọc được, vui lòng sử dụng Mẫu giấy lớn hơn hoặc rút ngắn mã hàng",
                noPrintPrice: "Không in giá",
                priceWithVND: "Giá kèm VNĐ",
                priceNonVND: "Giá không kèm VNĐ",
                priceWithUnit: "Giá kèm đơn vị tính",
                priceNonUnit: "Giá không kèm đơn vị tính",
                printShopName: "In tên cửa hàng",
                noPrintShopName: "Không in tên cửa hàng",
                inventorySystem: "Tồn kho hệ thống",
                inventory: "Tồn kho thực tế",
                existAttribute: "Thuộc tính đã tồn tại",
                removeVarriant: "Xóa Biến thể",
                removeUnit: "Xóa đơn vị?",
                productInformation: "Thông tin hàng hóa",
                quantityTransfer: "Số lượng chuyển",
                quantityReceive: "Số lượng nhận",
                productSelected: "Sản phẩm được chọn",
                inActiveProduct: "Bạn muốn ngừng kinh doanh hàng hóa mã {code} trên:",
                reActiveProduct: "Bạn muốn cho phép kinh doanh hàng hóa mã {code} trên:",
                keepInforationInventory: "Thông tin tồn kho và lịch sử giao dịch vẫn được giữ",
                discontinued: "Hàng hóa quy đổi liên quan sẽ ngừng kinh doanh",
                customer: "Đối tượng khách hàng",
                applyBranch: "Chi nhánh áp dụng",
                allBraches: "Toàn bộ chi nhánh",
                promotionBy: "Khuyến mại theo",
                selectPromotionType: "Chọn kiểu khuyến mại",
                invoiceWithProduct: "Hóa đơn và hàng hóa",
                giveProduct: "Tặng hàng",
                giveVoucher: "Tặng voucher",
                buyProductDiscountProduct: "Mua hàng giảm giá hàng",
                buyProductGiveProduct: "Mua hàng tặng hàng",
                priceByQuantity: "Giá bán theo số lượng mua",
                buyProductGiveVoucher: "Mua hàng tặng voucher",
                removePromotion: "Xóa khuyến mại",
                startTime: "Thời gian bắt đầu",
                endTime: "Thời gian kết thúc",
                promotionInformation: "Thông tin khuyến mại",
                form: "Hình thức",
                productByQuantityBuy: "Hàng tặng nhân theo số lượng mua",
                productByQuantityBuyNote: "Ví dụ: Mua 2 áo phông nam được tặng 1 đôi tất",
                productByQuantityBuyNote2: "Không nhân theo số lượng mua: nếu mua 4 áo phông vẫn chỉ tặng 1 đôi tất",
                productByQuantityBuyNote3: "Nhân theo số lượng mua: nếu mua 4 áo phông sẽ được tặng 2 đôi tất",
                promotionValue: "Giá trị khuyến mại",
                totalGift: "Tổng tặng",
                giftGroup: "Nhóm hàng tặng",
                giftProduct: "Hàng tặng",
                categoriesAreDiscounted: "Nhóm hàng được giảm giá",
                productsAreDiscounted: "Hàng được giảm giá",
                purchaseProductCategories: "Hàng/nhóm hàng mua",
                discountedProductCategories: "Hàng/nhóm hàng được giảm giá",
                withGiftCategories: "Kèm nhóm hàng mua",
                withGiftProduct: "Kèm hàng mua",
                withPurchaseProductCategories: "Kèm hàng/nhóm hàng mua",
                giftProductCategories: "Hàng/nhóm hàng tặng",
                selectGiftCategories: "Chọn nhóm hàng tặng",
                noteSelectCategories: "Hỗ trợ thêm danh sách hàng hóa bằng cách copy/paste danh sách mã hàng. Các mã hàng phải cách nhau bởi dấu 'Enter' hoặc dấu ','. <br> Ví dụ: <br>SP000123<br>SP000125<br>Hoặc: SP000123, SP000125",
                selectGiftProduct: "Chọn hàng tặng",
                selectDiscountCategories: "Chọn nhóm hàng giảm giá",
                otherOption: "Tùy chọn khác",
                warningWhenUsedPromotion: "Cảnh báo nếu khách hàng đã được hưởng khuyến mại này.",
                warningWhenUsedPromotionNote: "Chương trình khuyến mại sẽ không được áp dụng tự động. Nếu khách hàng đã hưởng khuyến mại trước đó thì lần áp dụng tiếp theo sẽ hiển thị cảnh báo tại màn hình bán hàng.",
                allSeller: "Toàn bộ người bán",
                emptyConditionPromotion: "Hình thức khuyến mại: Bạn chưa thêm điều kiện khuyến mại.",
                addPromotionSuccess: "Thêm chương trình khuyến mại thành công",
                updatePromotionSuccess: "Cập nhật chương trình khuyến mại thành công",
                removePromotions: "Xóa chương trình khuyến mại",
                confirmRemovePromotion: "Bạn có chắc chắn muốn xóa chương trình khuyến mại {code} không?",
                searchPromotion: "Tên mã, tên chương trình",
                historyPromotionOrder: "Lịch sử khuyến mại - Đặt hàng",
                historyPromotionInvoice: "Lịch sử khuyến mại - Hóa đơn",
                totalAmountFrom: "Tổng tiền hàng từ",
                quantityBuy: "SL Mua",
                quantityDiscount: "SL giảm",
                quantityGift: "SL tặng",
                pricePromotion: "Giá bán khuyến mại",
                confirmCancelPO: "Bạn có chắc chắn hủy phiếu nhập hàng {code} không?",
                cancelPOSuccess: "Hủy phiếu nhập hàng {code} thành công",
                cancelPOFailed: "Hủy phiếu nhập hàng {code} thất bại",
                confirmCancelRetunrPO: "Bạn có chắc chắn hủy phiếu trả hàng nhập {code} không?",
                cancelReturnPOSuccess: "Hủy phiếu trả hàng nhập {code} thành công",
                cancelReturnPOFailed: "Hủy phiếu trả hàng nhập {code} thất bại",
                receiveInvoice: "Nhận hóa đơn",
                invoiceDate: "Ngày hóa đơn",
                invoiceAmount: "Số tiền trên hóa đơn",
                nonInvoice: "Không có hóa đơn",
                hasInvoice: "Có hóa đơn",
                enterInvoice: "Nhập hóa đơn",
                enterInvoiceDate: "Nhập ngày hóa đơn",
                enterAmount: "Nhập số tiền",
                addInvoiceForPOSuccess: "Tạo hóa đơn cho phiếu nhập hàng thành công.",
                updatePOSuccess: "Phiếu nhập hàng {code} cập nhật thành công",
                pdf: "PDF",
                excel: "Excel",
                mix: "Tổng hợp",
                confirmCancelTicket: "Bạn có chắc chắn muốn xử lý phiếu này ?",
                approveRequestRefund: "Duyệt phiếu đề nghị hoàn hàng",
                confirmApproveRequestRefund: "Bạn có chắc chắn muốn duyệt phiếu đề nghị {code} này không",
                refuseRequestRefund: "Từ chối duyệt phiếu đề nghị hoàn hàng",
                confirmRefuseRequestRefund: "Bạn có chắc chắn muốn từ chối duyệt phiếu đề nghị {code} này không",
                approveSuccess: "Phê duyệt phiếu đề nghị thành công",
                approveFailed: "Phê duyệt phiếu đề nghị không thành công",
                refuseSuccess: "Từ chối phiếu đề nghị thành công",
                refuseFailed: "Từ chối phiếu đề nghị không thành công",
                cancelRequestRefund: "Hủy phiếu đề nghị hoàn hàng",
                confirmCancelRequestRefund: "Bạn có chắc chắn muốn hủy phiếu đề nghị hoàn hàng {code} không?",
                cancelRequestRefundSuccess: "Hủy phiếu đề nghị hoàn hàng {code} thành công",
                cancelRequestRefundFailed: "Hủy phiếu đề nghị hoàn hàng {code} thất bại",
                searchInvoice: "Theo hóa đơn",
                requestRefundCode: "Mã phiếu DNHH",
                approveTime: "Thời gian duyệt",
                updateRequestRefundSuccess: "Phiếu đề nghị hoàn hàng {code} được cập nhật thành công",
                updateRequestRefundFailed: "Phiếu đề nghị hoàn hàng {code} cập nhật thất bại",
                addNewRole: "Lưu thành vai trò mới",
                removeRole: "Xóa vai trò",
                confirmRemoveRole: "Bạn có chắc chắn muốn xóa vai trò này không?",
                lastUpdateDay: "Ngày cập nhật cuối",
                emailTemplate: "Mẫu mail",
                selectTemplate: "Chọn mẫu",
                templateKeys: "Danh sách token mẫu in",
                importPosition: "Nhập vị trí",
                exportPosition: "Xuất vị trí",
                transferPosition: "Chuyển vị trí",
                bas: "BAS",
                selectOrderSync: "Chọn đơn hàng để đồng bộ",
                onlySyncOrder: "Chỉ đồng bộ các đơn hàng được",
                from: "Kể từ",
                confirmConnectNote: "Lưu ý: Khi các đơn hàng này được đồng bộ về sẽ tạo đơn đặt hàng, hóa đơn tương ứng và thay đổi số lượng đặt hàng, tồn kho của các sản phẩm liên quan.",
                understood: "Đã hiểu",
                fifteenDay: "15 ngày qua",
                afterSetting: "Sau khi thiết lập",
                create: "Tạo",
                createOrUpdate: "Tạo hoặc cập nhật",
                selectBranchBeforeSave: "Vui lòng chọn chi nhánh trước khi lưu!",
                disconnect: "Xóa kết nối",
                confirmDisconnect: "Bạn có chắc chắn muốn XÓA kết nối gian hàng với",
                confirmCancelRequests: "Bạn có chắc chắn muốn hủy phiếu đặt hàng chuyển {code} không?",
                cancelRequestsSuccess: "Hủy phiếu đặt hàng chuyển {code} thành công",
                cancelRequestsFailed: "Hủy phiếu đặt hàng chuyển {code} thất bại",
                confirmCancelTransfer: "Bạn có chắc chắn muốn hủy phiếu chuyển kho {code} không?",
                cancelTransferSuccess: "Hủy phiếu chuyển kho {code} thành công",
                cancelTransferFailed: "Hủy phiếu chuyển kho {code} thất bại",
                selectBranchRequest: "Chọn chi nhánh gọi",
                joint: "Khớp",
                noJoint: "Không khớp",
                deviated: "Lệch",
                notGiven: "Chưa cho",
                requestQuantity: "SL gọi",
                transferQuantity: "SL cho",
                requestPrice: "Giá gọi",
                waitForConfirmation: "Chờ xác nhận",
                totalRequestQuantity: "Tổng số lượng gọi",
                totalTransferQuantity: "Tổng số lượng cho",
                createTransfer: "Tạo phiếu chuyển",
                emptyRequests: "Phiếu đặt hàng chuyển đang trống",
                inValidRequestBranch: "Chi nhánh gọi không hợp lệ",
                inValidTransferQuantityConsignment: "Số lượng chuyển của lô {consignment} sản phẩm {name} không được lớn hơn tồn kho",
                inValidTransferQuantityProduct: "Số lượng chuyển của sản phẩm {name} không được lớn hơn tồn kho",
                inValidTransferQuantityProductForRequest: "Số lượng chuyển của sản phẩm {name} không được lớn hơn số lượng hàng gọi",
                updateRequestSuccess: "Phiếu đặt hàng chuyển được cập nhật thành công",
                updateConfirmRequestSuccess: "Phiếu xác nhận đặt hàng chuyển được cập nhật thành công",
                confirmRequestSuccess: "Xác nhận phiếu gọi thành công! Phiếu chuyển hàng tương ứng đã được tạo mới",
                addRequestSuccess: "Phiếu đặt hàng chuyển được tạo thành công",
                inValidRequestedBranch: "Chi nhánh được gọi không hợp lệ!",
                maxLengthRequestBranchNote: "Ghi chú chi nhánh gọi không thể lớn hơn 255 ký tự",
                maxLengthRequestedBranchNote: "Ghi chú chi nhánh được gọi không thể lớn hơn 255 ký tự",
                transferPrice: "Giá chuyển",
                moving: "Đang chuyển",
                transferBranch: "Chi nhánh gửi",
                totalSendQuantity: "Tổng số lượng chuyển",
                totalReceiveQuantity: "Tổng số lượng nhận",
                receive: "Nhận hàng",
                outOfStock: "Sản phẩm đã hết hàng",
                emptyTransfer: "Phiếu chuyển kho đang trống",
                inValidReceiveBranch: "Chi nhánh nhận không hợp lệ",
                emptyNote: "Ghi chú không được bỏ trống",
                emptyConsignment: "Sản phẩm {name} không được để trống lô",
                inValidReceiveQuantity: "Số lượng hàng nhận của sản phẩm {name} không được lớn hơn số lượng hàng chuyển",
                updateTransferSuccess: "Phiếu chuyển kho được cập nhật thành công",
                confirmTransferSuccess: "Phiếu xác nhận chuyển kho được cập nhật thành công",
                receiveSuccess: "Nhận hàng thành công! Số lượng hàng hóa trong kho sẽ được cập nhật",
                addTransferSuccess: "Phiếu chuyển kho được tạo thành công",
                maxLengthTransferBranchNote: "Ghi chú chi nhánh chuyển không thể lớn hơn 255 ký tự",
                maxLengthReceiveBranchNote: "Ghi chú chi nhánh nhận không thể lớn hơn 255 ký tự",
                removeDebtAdjustmentSuccess: "Xóa điều chỉnh công nợ thành công",
                debtAdjustmentSuccess: "Điều chỉnh công nợ thành công",
                debtAdjustmentFailed: "Điều chỉnh công nợ không thành công",
                updateDeliveryInforSuccess: "Cập nhật thông tin giao hàng",
                sellTime: "Thời gian bán hàng",
                updateHistoryDeliverySuccess: "Cập nhật lịch sử giao hàng thành công",
                addSubmitter: "Thêm người nộp",
                emptyName: "Bạn chưa nhập tên",
                emptyPhone: "Bạn chưa nhập số điện thoại",
                addPartnerSuccess: "Thêm đối tác thành công",
                uploadVoucher: "Nhập voucher từ File dữ liệu",
                maxLengthVoucher: "Hệ thống cho phép nhận tối đa 5.000 voucher mỗi lần từ file",
                cancelVoucherError: "Hệ thống chỉ cho phép hủy những voucher chưa phát hành. Các voucher sau không hợp lệ",
                confirmCancelVoucher: "Bạn có chắc chắn muốn hủy các voucher này không?",
                cancelVoucherSuccess: "Hủy voucher thành công",
                cancelVoucherFailed: "Hủy voucher không thành công",
                releaseVoucherError: "Bạn chỉ phát hành được những voucher chưa được phát hành. Các voucher sau không hợp lệ",
                give: "Tặng",
                releaseVoucherSuccess: "Phát hành voucher thành công",
                releaseVoucherFailed: "Phát hành voucher không thành công",
                confirmReleaseVoucher: "Bạn chưa nhập Tên người mua/nhận. Bạn có chắc chắn muốn phát hành không?",
                inValidVoucherValue: "Giá trị voucher không hợp lệ",
                confirmAddReceiptsForVoucher: "Hệ thống sẽ tạo ra phiếu thu cho các voucher trên. Bạn có chắc chắn muốn phát hành không?",
                returnVoucher: "Trả voucher",
                returnVoucherError: "Hệ thống chỉ cho phép trả lại những voucher đã phát hành. Các voucher sau không hợp lệ",
                returnDate: "Ngày trả",
                returnVoucherSuccess: "Trả voucher thành công",
                returnVoucherFailed: "Trả voucher không thành công",
                inValidReturnBy: "Bạn chưa nhập Tên người trả. Bạn có chắc chắn muốn trả không?",
                confirmReturnVoucher: "Hệ thống sẽ tạo ra phiếu thu cho các voucher trên. Bạn có chắc chắn muốn trả không?",
                enterBAScode: "Nhập mã phiếu BAS",
                searchSuppliers: "Tìm nhà cung cấp theo mã, tên",
                confirmCancelInforSaved: "Bạn có muốn hủy thông tin đã tạo",
                notFoundRecord: "Không tìm thấy bản ghi này",
                existSupplier: "Nhà cung cấp đã tồn tại",
                deliveryDetail: "Chi tiết phiếu giao hàng",
                long: "Dài",
                width: "Rộng",
                height: "Cao",
                pickUpAtTheBranch: "Lấy hàng tại Chi nhánh",
                approve: "Phê duyệt",
                refuse: "Từ chối",
                emptyRequestRefund: "Phiếu đề nghị hoàn hàng đang trống",
                addRequestRefundSuccess: "Tạo phiếu đề nghị hoàn hàng thành công",
                updatedRequestRefundSuccess: "Cập nhật phiếu đề nghị hoàn hàng thành công",
                intoStorage: "Nhập kho",
                takeOutStorage: "Xuất kho",
                existDocumentOrigin: "Chứng từ gốc đã tồn tại",
                poWithReturnPOCode: "Mã PO/THN",
                statusPO: "Trạng thái phiếu",
                totalAdvance: "Tổng tiền tạm ứng",
                totalSettlement: "Số tiền quyết toán",
                totalDebtInvoice: "Số hóa đơn còn nợ",
                updateTicketSuccess: "Cập nhật phiếu thành công",
                addTicketSuccess: "Tạo phiếu thành công",
                searchImportPosition: "Tìm mã phiếu nhập vị trí",
                searchExportPosition: "Tìm mã phiếu xuất vị trí",
                confirmSaveInforSaved: "Bạn có muốn lưu thông tin đã cập nhật",
                outputLocation: "Vị trí xuất",
                entryLocation: "Vị trí nhập",
                locationCode: "Mã vị trí",
                totalQuantityProducts: "Tổng số sản phẩm",
                integrateSalesChannels: "Tích hợp kênh bán",
                linkToECommerce: "Liên kết sàn TMĐT",
                linkToProduct: "Liên kết hàng hóa",
                addStall: "Thêm gian hàng",
                stallName: "Tên gian hàng",
                syncOrder: "Đồng bộ đơn hàng",
                syncQuantity: "Đồng bộ SL bán",
                syncPrice: "Đồng bộ giá bán",
                noLinkStall: "Chưa có Shop nào được liên kết",
                linkToStallSuccess: "Kết nối gian hàng thành công.",
                retry: "Có lỗi xảy ra vui lòng thử lại",
                existStall: "Gian hàng đã được kết nối, thử 1 gian hàng khách",
                linkToStall: "Kết nối gian hàng trên sàn",
                linkProductToOmipos: "Liên kết hàng hóa trên sàn với hàng hóa Omipos",
                settingSync: "Thiết lập tính năng đồng bộ",
                loginStallToOmpos: "Đăng nhập và kết nối gian hàng trên {stall} với Omipos để có thể đồng bộ thông tin hàng hóa, đơn hàng trên Omipos",
                noAccount: "Chưa có tài khoản",
                register: "Đăng ký",
                syncError: "Đồng bộ lỗi",
                enterCodeOrName: "Nhập mã, tên hàng",
                enterIDOrSKU: "Nhập item ID, mã SKU hoặc tên hàng",
                stall: "Gian hàng",
                productOnStall: "Hàng hóa trên sàn",
                id: "ID",
                sku: "SKU",
                branchSync: "Chi nhánh đồng bộ",
                branchSyncDesc: "Lựa chọn chi nhánh để đồng bộ thông tin đơn hàng, tồn kho, giá bán.",
                syncOrderDesc: "Tự đồng bộ đơn hàng từ sàn về Omipos để xử lý & quản lý tập trung doanh thu, lợi nhuận",
                onlySyncOrderWhen: "Chỉ đồng bộ các đơn hàng được",
                syncOrderNote: "Lưu ý: Khi các đơn hàng này được đồng bộ về sẽ tạo đơn đặt hàng, hóa đơn tương ứng sẽ thay đổi số lượng đặt hàng, tồn kho của các sản phẩm liên quan.",
                productNoLink: "Khi đơn hàng có hàng hóa chưa liên kết",
                confirmDelivery: "Xác nhận vận đơn chuyển hoàn",
                confirmDeliveryDesc: "Các vận đơn chuyển hoàn của cửa hàng cần được xác nhận để chuyển qua trạng thái “Đã chuyển hoàn”",
                syncSaleQuantity: "Đồng bộ số lượng bán",
                autoSyncInventory: "Tự đồng bộ tồn kho từ Omipos lên",
                formulaSyncQuantity: "Công thức đồng bộ số lượng bán",
                setValueQuantity: "Đặt giá trị số lượng bán trên",
                fromFormuala: "theo công thức",
                distributionOfSaleQuantity: "Phân bổ số lượng bán của hàng nhiều liên kết",
                ecommerceLevel: "Sàn",
                error: "lỗi",
                syncAll: "Đồng bộ tất cả",
                products: "Sản phẩm",
                removeSyncError: "Xóa khỏi danh sách lỗi",
                sync: "Đồng bộ",
                productSync: "Hàng hóa đồng bộ",
                syncContent: "Nội dung đồng bộ",
                inValidInventoryProduct: "Tồn kho của sản phẩm nhỏ hơn tổng số lượng đã được khách hàng đặt hoặc tham gia chương trình khuyến mại trên",
                reSync: "Đang đồng bộ lại...",
                syncSuccess: "Đồng bộ thành công",
                currentPrice: "Giá hiện tại",
                lastPurchasePrice: "Giá nhập lần cuối",
                month1: "Tháng 1",
                month2: "Tháng 2",
                month3: "Tháng 3",                    
                month4: "Tháng 4",                    
                month5: "Tháng 5",                    
                month6: "Tháng 6",                    
                month7: "Tháng 7",                    
                month8: "Tháng 8",                    
                month9: "Tháng 9",                    
                month10: "Tháng 10",                    
                month11: "Tháng 11",                    
                month12: "Tháng 12",
                day1: "Ngày 1",
                day2: "Ngày 2",
                day3: "Ngày 3",
                day4: "Ngày 4",
                day5: "Ngày 5",
                day6: "Ngày 6",
                day7: "Ngày 7",
                day8: "Ngày 8",
                day9: "Ngày 9",
                day10: "Ngày 10",
                day11: "Ngày 11",
                day12: "Ngày 12",
                day13: "Ngày 13",
                day14: "Ngày 14",
                day15: "Ngày 15",
                day16: "Ngày 16",
                day17: "Ngày 17",
                day18: "Ngày 18",
                day19: "Ngày 19",
                day20: "Ngày 20",
                day21: "Ngày 21",
                day22: "Ngày 22",
                day23: "Ngày 23",
                day24: "Ngày 24",
                day25: "Ngày 25",
                day26: "Ngày 26",
                day27: "Ngày 27",
                day28: "Ngày 28",
                day29: "Ngày 29",
                day30: "Ngày 30",
                day31: "Ngày 31",
                confirmationTime: "Thời gian xác nhận đơn trên sàn",
                manualLink: "Chờ liên kết thủ công",
                inventoryMinusOrdered: "Tồn kho - Đặt hàng",
                productOnLazada: "Hàng trên Lazada",
                productOnShopee: "Hàng trên Shopee",
                productOnTiki: "Hàng trên Tiki",
                productOnTiktok: "Hàng trên Tiktok",
                productOnSendo: "Hàng trên Sendo",
                searchOrderCode: "Theo mã đặt hàng",
                enterOrderCode: "Nhập mã đặt hàng",
                searchCustomerInfor: "Theo mã, tên, sđt khách hàng",
                enterCustomerCodeNamePhone: "Nhập mã, tên, sđt khách hàng",
                searchProductCodeName: "Theo mã, tên hàng hóa",
                enterProductCodeName: "Nhập mã, tên hàng hóa",
                productOnECommerce: "Hàng trên Sàn",
                notRelease: "Chưa phát hành",
                released: "Đã phát hành",
                used: "Đã sử dụng",
                cancelled: "Đã huỷ",
                promotionInvoiceDiscountDesc: "Ví dụ: <br/>- Hóa đơn có Tổng tiền hàng từ 500,000 được chiết khấu 5% <br/> - Hóa đơn có Tổng tiền hàng từ 1,000,000 trở lên được giảm giá hóa đơn 10%",
                promotionInvoiceDonateProductDesc: "Ví dụ: <br/>- Hóa đơn có Tổng tiền hàng từ 500,000 được tặng 1 đôi tất <br/> - Hóa đơn có Tổng tiền hàng từ 1,000,000 được tặng 1 sản phẩm bất kỳ thuộc nhóm Phụ kiện",
                promotionInvoiceDiscountProductDesc: "Ví dụ: <br/>- Hóa đơn có Tổng tiền hàng từ 100,000 được chiết khấu 5% cho áo thun <br/> - Hóa đơn có Tổng tiền hàng từ 200,000 được chiết khấu 10% cho áo thun",
                promotionProductDiscount: "Khi mua đủ số lượng hàng thiết lập, khách hàng sẽ được giảm giá cho những hàng mua tiếp theo.",
                promotionProductDonateProductDesc: "Khi mua đủ số lượng hàng thiết lập, khách hàng sẽ được tặng thêm sản phẩm.",
                promotionProductAllowProductDesc: "Ví dụ: <br/> - Mua từ 3 Áo phông trở lên được hưởng mức giá 250,000 mỗi áo <br/> - Mua từ 5 Áo phông trở lên được giảm giá 50,000 cho mỗi áo.",
                promotionAllInvoiceDiscountDesc: "Ví dụ: <br />- Hóa đơn có tổng tiền hàng từ 2,000,000 (VNĐ) và mua ít nhất 1 sản phẩm Túi mango sẽ được chiết khấu 5%. <br/>-Hóa đơn mua ít nhất 3 sản phẩm Túi Mango sẽ được chiết khấu 5%.",
                promotionAllDonateProductDesc: "Ví dụ: <br />- Hóa đơn có tổng tiền hàng từ 2,000,000 (VNĐ) và mua ít nhất 1 sản phẩm Túi mango sẽ được tặng 2 Phụ kiện nữ. <br/>- Hóa đơn mua ít nhất 3 sản phẩm Túi Mango sẽ được tặng 2 Phụ kiện nữ.",
                promotionAllProductDiscountDesc: "Ví dụ: <br />- Hóa đơn có tổng tiền hàng từ 2,000,000 (VNĐ) và mua ít nhất 1 sản phẩm Túi mango sẽ được giảm giá 50% 2 sản phẩm Phụ kiện nữ. <br/>- Hóa đơn mua ít nhất 3 sản phẩm Túi Mango sẽ được giảm giá 50,000 (VNĐ) 2 sản phẩm Phụ kiện nữ.",
                warning: "Cảnh báo",
                ban: "Chặn",
                stocking: "Còn hàng",
                productOutOfStock: "Hết hàng",
                zeroPercent: "Không chịu thuế (0%)",
                fivePercent: "5%",
                eightPercent: "8%",
                tenPercent: "10%",
                removed: "Đã xóa",
                delivering: "Đang giao hàng",
                waitingForProgressing: "Chờ xử lý",
                selectTypePaper: "Chọn loại giấy in tem mã",
                previewTemplate: "Xem bản in",
                multipleOptions: "Nhiều lựa chọn",
                receiver: "Người nhận hàng",
                accountant: "Kế toán",
                selectProductDiscount: "Chọn hàng giảm giá",
                whenBuy: "Khi mua",
                readyToShip: "Sẵn sàng chuyển hàng",
                process: "Xử lý",
                transport: "Vận chuyển",
                inCancel: "Đang hủy",
                waitingInvoice: "Đang chờ hóa đơn",
                onHold: "Tạm dừng",
                waitingShip: "Chờ giao hàng",
                waitingCollection: "Chờ lấy hàng",
                partiallyShipping: "Giao 1 phần",
                delivered: "Đã giao",
                queueing: "Đang chờ nhà bán xác nhận",
                successfulDelivery: "Giao hàng thành công",
                processing: "Đang xử lý",
                waitingPayment: "Chờ thanh toán",
                handoverToPartner: "Đơn hàng đã giao đối tác giao hàng",
                closed: "Đơn hàng đã đóng",
                packaging: "Đơn hàng đang được đóng gói",
                picking: "Đang lấy hàng",
                holded: "Đơn hàng đang được giữ lại để xử lý các vấn đề phát sinh",
                finishedPacking: "Đơn hàng đã đóng gói",
                printTemplateName: "Tên mẫu in",
                suggestPrintTemplate: "Mẫu in gợi ý",
                previewPrintTemplate: "Xem trước mẫu in",
                titleAddPrintTemplate: "Thêm mới mẫu in",
                titleUpdatePrintTemplate: "Cập nhật mẫu in",
                addPrintTemplateSuccess: "Mẫu in thêm mới thành công",
                addPrintTemplateFailed: "Mẫu in thêm mới không thành công",
                emptyPrintTemplateName: "Vui lòng nhập tên mẫu in",
                maxPrintTemplates: "Không thể thêm được mẫu in này! Hệ thống chỉ hỗ trợ tối đa 3 mẫu in",
                updatePaper: "Chỉnh sửa khổ giấy in",
                addPaper: "Thêm mới khổ giấy in",
                enterPrintTemplateName: "Nhập tên mẫu in",
                emptyPaper: "Vui lòng nhập tên của khổ giấy",
                removePrintTemplate: "Xóa mẫu in",
                confirmRemovePrintTemplate: "Bạn có muốn xóa mẫu in này?",
                branchRequest: "Chi nhánh gọi",
                voucherSelected: "voucher được chọn",
                paymentSchedule: "Lịch thanh toán",
                noSetup: "Chưa thiết lập",
                connected: "Đã kết nối",
                removeLink: "Xóa liên kết",
                uploadImage: "Tải {type} lên",
                sendZaloSuccess: "Gửi zalo thành công",
                sendZaloFailed: "Gửi zalo không thành công",
                numberCouponSelected: "Có {number} coupon được chọn",
                numberVoucherSelected: "Có {number} voucher được chọn",
                numberRecordSelected: "Có {number} bản ghi được chọn",
                discountCouponValue: "Mã coupon {code}: Giảm giá {value}(giảm {percent}% cho hóa đơn)",
                monday: "T2",
                tuesday: "T3",
                wednesday: "T4",
                thursday: "T5",
                friday: "T6",
                saturday: "T7",
                sunday: "CN",
                searchDeliveryStatus: "Tìm kiếm trạng thái giao hàng",
                transactionOccurred: "Đã phát sinh giao dịch",
                noData: "Không có dữ liệu",
                selectCreatorApplied: "Chọn người tạo áp dụng",
                messageImportSuccess: "Tải file thành công",
                selectVouchers: "Chọn đợt phát hành...",
                donateVoucher: "Tặng voucher thuộc đợt phát hành",
                promotionInvoiceDonateVoucherDesc: "Ví dụ: <br/>- Hóa đơn có Tổng tiền hàng từ 500,000 được tặng 1 voucher",
                promotionProductDonateVoucherDesc: "Khi mua đủ số lượng hàng thiết, sẽ được tặng voucher",
                promotionProductDonateVoucherNote: "Số lượng voucher không nhân theo số lượng mua.",
                maxVoucherDetails: "Bạn chỉ được tặng tối đa {number} voucher",
                enterVoucherCode: "Nhập mã voucher",
                giftVoucher: "Voucher tặng",
                noteModalInputEmail: "Lưu ý: File báo cáo cần thời gian để xử lý, vui lòng nhập email để nhận file sau khi hoàn thành. Nếu không thấy email vui lòng check ở thư mục spam",
                processedExportFile: "Đã xử lý export file...",
                errorExportFile: "Lỗi export file...", 
                processingExportFile: "Đang xử lý export file...",
                clickHere: "Click tại đây!",
           
            },
            permissions: {   
                titleUpdate: "Thông báo",
                contentUpdate:"Nếu người dùng {name} đang sử dụng hệ thống sẽ bị đăng xuất ngày lập tức để cập nhật lại vai trò. Bạn có muốn tiếp tục thực hiện cập nhật không",
                messageSuccess: "Xóa phân quyền thành công",
                messageError: "Xóa phân quyền không thành công",
                modal: {
                    titleEditRole: "Sửa vai trò",
                    save: "Lưu",
                    cancel: "Bỏ qua",
                    delete: "Xóa",
                }
            },
            InputQuickSearch:{
                placeholder: {
                    request_refund_code: "Theo mã đề nghị hoàn hàng",
                    settlement_advance_code: "Theo mã phiếu đề nghị tạm ứng",
                    transfer_position_code: "Theo mã phiếu chuyển",
                    import_position_code: "Theo mã nhập vị trí",
                    export_position_code: "Theo mã xuất vị trí",
                    original_documents_code: "Theo chứng từ gốc",
                    original_documents_type: "Theo loại chứng từ",
                    coupon_voucher_code_name: "Theo mã, tên đợt phát hành",
                    coupon_code: "Theo mã coupon",
                    voucher_code: "Theo mã voucher",
                    returns_code_return_search: "Theo mã phiếu trả",
                    returns_code_refund_search: "Theo mã chứng từ trả lại",
                    order_code: "Theo mã phiếu đặt",
                    code_transfer: "Theo mã phiếu chuyển",
                    code_request: "Theo mã phiếu gọi",
                    product_code: "Theo mã hàng",
                    product_name: "Theo tên hàng",
                    code_name_phone: "Theo mã, tên, điện thoại khách hàng",
                    username: "Tên đăng nhập, người dùng",
                    phone: "Theo số điện thoại",
                    email: "Theo email",
                    return_purchase_order: "Theo mã phiếu trả",
                    order_supplier: "Theo mã phiếu đặt hàng nhập",
                    purchase_order: "Theo mã phiếu nhập hàng",
                    product_code_name: "Theo mã, tên hàng",
                    supplier_code_name: "Theo mã, tên NCC",
                    code_invoice: "Theo mã hóa đơn",
                    delivery_code: "Theo mã vận đơn",
                    note: "Theo ghi chú",
                    note_product: "Theo ghi chú hàng hóa",
                    note_description: "Theo ghi chú, mô tả hàng hóa",
                    invoice_supplier_code: "Theo số hóa đơn", 
                    product_omipos: "Nhập mã, tên sản phẩm (OmiPOS)",
                    product_medicine: "Nhập mã, tên thuốc (cục Dược)",
                    control_history: "Theo mã đối soát",
                    note_delivery: "Theo ghi chú giao hàng",
                    inventory_plan_code: "Theo mã, tên dự trù chi tiết",
                    plan_purchase_code: "Theo mã, tên dự trù nhập mua",
                    discount_bas_code: "Theo mã phiếu BAS",
                },
                button: {
                    extend: "Mở rộng",
                    collapse: "Thu gọn",
                    search: "Tìm kiếm"
                },
                title: {
                    code_transfer: "Mã phiếu chuyển",
                    code_request: "Mã phiếu gọi",
                    product_name: "Tên hàng",
                    product_code: "Mã hàng",
                    order_supplier: "Mã đặt hàng nhập",
                    purchase_order: "Mã nhập hàng",
                    return_purchase_order: "Mã phiếu trả",
                    product_code_name: "Hàng hóa",
                    supplier_code_name: "NCC",
                    note: "Ghi chú",
                    note_product: "Ghi chú hàng hóa",
                    username: "Người dùng",
                    phone: "SĐT",
                    email: "Email",
                    note_description: "Ghi chú - mô tả",
                    code_name_phone: "Khách hàng",
                    code_invoice: "Mã hóa đơn",
                    order_code: "Mã đặt hàng",
                    delivery_code: "Mã vận đơn",
                    returns_code: "Mã trả hàng",
                    coupon_voucher_code_name: "Đợt phát hành",
                    coupon_code: "Mã coupon",
                    voucher_code: "Mã voucher",
                    keyword: "Mã",
                    import_position_code: "Mã phiếu nhập VT",
                    export_position_code: "Mã phiếu xuất VT",
                    original_documents_code: "Mã chứng từ gốc",
                    original_documents_type: "Loại chứng từ",
                    transfer_position_code: "Mã phiếu chuyển",
                    request_refund_code: "Mã DNHH",
                    settlement_advance_code: "Mã TUQT",
                    invoice_supplier_code: "Số hóa đơn",
                    cash_book_code: "Mã sổ quỹ",
                    bank_book_code: "Mã sổ quỹ",
                    medicine_name: "Tên thuốc",
                    medicine_number: "Số đăng ký",
                    medicine_code: "Mã thuốc",
                    medicine_content: "Hàm lượng",
                    medicine_country: "NSX",
                    product_omipos: "Tên hàng hóa",
                    product_medicine: "Mã thuốc",
                    transporter_code: "Mã đối tác",
                    control_history: "Mã đối soát",
                    note_delivery: "Ghi chú",
                    inventory_plan_code: "Mã DTCT",
                    plan_purchase_code: "Mã DTNM",
                    discount_bas_code: "Mã BAS"
                }
            },
            LoginScreen: {
                title: "OmiPos Đăng Nhập",
                header: "Đăng nhập",
                remember: "Ghi nhớ",
                submit: "Đăng nhập",
                password: "Mật khẩu",
                username: "Tên đăng nhập"
            },
            Pagination: {
                show: "Hiển thị ",
                total: "Tổng",
                record: "bản ghi",
            },
            SelectDateTime: {
                otherOption: "Lựa chọn khác",
            },
            DateRangePicker: {
                byDay: "Theo ngày",
                byWeek: "Theo tuần",
                byMonth: "Theo tháng",
                byQuarter: "Theo quý",
                byYear: "Theo năm",
                today: "Hôm nay",
                yesterday: "Hôm qua",
                thisWeek: "Tuần này",
                lastWeek: "Tuần trước",
                twoDay: "2 ngày qua",
                threeDay: "3 ngày qua",
                sevenDay: "7 ngày qua",
                month: "Tháng này",
                lastMonth: "Tháng trước",
                thirtyDay: "30 ngày qua",
                quarter: "Quý này",
                lastQuarter: "Quý trước",
                year: "Năm nay",
                lastYear: "Năm trước",
                all: "Toàn thời gian"
            },
            ImportExcel: {
                title: "Thêm sản phẩm từ file excel",
                defaultFile: "Tải về file mẫu:",
                file: "File chi tiết",
                chooseFile: "Chọn file dữ liệu",
                action: "Thực hiện",
                orderSupplierFile: "File đặt hàng nhập",
                purchaseOrderFile: "File nhập hàng",
            },
            ProductUnitSearch: {
                orderFromCustomer: "Khách đặt",
                orderSupplier: "Đặt NCC",
                price: "Giá",
                inventory: "Tồn",
            },

        /* Dashboard */

            DateRangerDashboardPicker: {
                today: 'Hôm nay',
                last_day: 'Hôm qua',
                seven_day: '7 ngày qua',
                month: 'Tháng này',
                last_month: 'Tháng trước',
            },
            RevenueFromBranch: {
                title: 'DOANH THU THUẦN THEO CHI NHÁNH {label}',
            },
            RevenueOriginal: {
                title: 'DOANH THU THUẦN',
                dayOfWeek: 'Theo thứ',
                day: 'Theo ngày',
                hour: 'Theo giờ',
            },
            StatisticalInvoice: {
                title: 'KẾT QUẢ BÁN HÀNG HÔM NAY',
                revenue: 'Doanh thu',
                invoice: 'Hóa đơn',
                promissory: 'Phiếu',
                return: 'Trả hàng',
                compared_to_yesterday: 'So với hôm qua',
                compared_same_period_last_month: 'So với cùng kỳ tháng trước',
            },
            notice: {
                title_notice: "THÔNG BÁO",
                title_action: "CÁC HOẠT ĐỘNG GẦN ĐÂY",
                action_by_user: "vừa tạo phiếu",
                value: "với giá trị",
                content_transfer: 'từ {fromBranch} đến {toBranch}',
                have: "Có",
                expired: "sắp hết hạn",
                three_month: "trong 3 tháng",
                seven_day: "trong tuần này",
                product: "mặt hàng",
                voucher: "đợt phát hành voucher",
                coupon: "đợt phát hành coupon",
                promotion: "chương trình khuyến mại",
                noticeForProduct: 'Có <span class="text-primary">{number} mặt hàng</span> sắp hết hạn <span class="text-primary">trong 3 tháng</span>',
                noticeForCoupon: 'Có <span class="text-primary">{number} đợt phát hành coupon</span> sắp hết hạn',
                noticeForVoucher: 'Có <span class="text-primary">{number} đợt phát hành voucher</span> sắp hết hạn',
                noticeForPromotion: 'Có <span class="text-primary">{number} chương trình khuyến mại</span> sắp hết hạn',
                noticeActionInvoice: '<a class="text-primary text-decoration-none" target="_blank" href="{href}">{employee}</a> vừa tạo phiếu <a class="text-primary text-decoration-none" target="_blank" href="{hrefAction}">{action}</a> <span class="{showBranch}">từ {branchNameSend} đến {branchNameReceive} </span><span class="{showAmount}"> với giá trị <span class="text-bold">{amount}</span></span>',
            },
            noticeDetail: {
                time: "Từ ngày {from} đến ngày {to}",
                branch: "Chi nhánh: {branch}",
                title_product: "DANH SÁCH HÀNG HÓA HẾT HẠN TRONG 3 THÁNG TỚI",
                title_voucher: "DANH SÁCH VOUCHER HẾT HẠN TRONG 7 NGÀY TỚI",
                title_coupon: "DANH SÁCH COUPON HẾT HẠN TRONG 7 NGÀY TỚI",
                title_promotion: "DANH SÁCH CHƯƠNG TRÌNH KHUYẾN MẠI HẾT HẠN TRONG 7 NGÀY TỚI",
                max_value: "tối đa",
                tables: {
                    product: {
                        code: 'Mã hàng',
                        name: 'Tên hàng',
                        consignment: 'Lô',
                        expired: 'Hạn sử dụng',
                        daysLeft: 'Số ngày còn lại',
                    },
                    voucher: {
                        code: 'Mã voucher',
                        release_code: 'Mã đợt phát hành',
                        amount: 'Mệnh giá',
                        release_date: 'Ngày phát hành',
                        expired_date: 'Ngày hết hạn',
                    },
                    coupon: {
                        code: 'Mã coupon',
                        release_code: 'Mã đợt phát hành',
                        amount: 'Giá trị coupon',
                        release_date: 'Ngày phát hành',
                        expired_date: 'Ngày hết hạn',
                    },
                    promotion: {
                        code: 'Mã chương trình',
                        form: 'Hình thức',
                        release_date: 'Ngày phát hành',
                        expired_date: 'Ngày hết hạn',
                    },
                }
            },
            ProductStatistical: {
                title: 'TOP 10 HÀNG HÓA BÁN CHẠY',
            },

        /* Hết dashboard */


        /* Danh mục */

            // Danh sách hàng hóa
            ProductIndex: {
                title: "Hàng hóa",
                document_title: "Hàng hóa | OmiPos",
                product_type: "Loại hàng",
                categories: "Nhóm hàng",
                all: "Tất cả",
                created_at: "Ngày tạo",
                choose_show: "Lựa chọn hiển thị",
                placeholder: {
                    categories: "Tìm kiếm nhóm hàng",
                    search: "Theo mã, tên hàng",
                    branch: "Tìm tên chi nhánh",
                    batch: "Tìm theo lô, hạn sử dụng"
                },
                info: "Thông tin",
                maxInventory: "Định mức tồn",
                stockCard: "Thẻ kho",
                inventory: "Tồn kho",
                batch: "Lô date",
                combo: "Thành phần",
                code: "Mã hàng",
                price: "Giá bán",
                weight: "Trọng lượng (Gram)",
                location: "Vị trí",
                manufacturer: "Hãng sản xuất",
                country: "Nước sản xuất",
                unit: "Quy cách đóng gói",
                description: "Mô tả",
                note: "Ghi chú",
                supplier: "Nhà cung cấp",
                status: "Trạng thái",
                quantity: "Số lượng",
                no_field: "Không có trường hiển thị",
                branch: "Chi nhánh",
                component_product: "Hàng hóa thành phần",
                name: "Tên sản phẩm",
                reason: "Nguyên nhân",
                active: "Hàng kinh doanh",
                inActive: "Hàng ngừng kinh doanh",
                table:{
                    stockCard: {
                        code: "Chứng từ",
                        time: "Thời gian",
                        type: "Loại giao dịch",
                        price: "Giá giao dịch",
                        price_tooltip_1: "Giá giao dịch là giá của hàng hóa trên giao dịch (hóa đơn, trả hàng, nhập hàng và trả hàng nhập). Để xem được giá giao dịch, cần được phân quyền xem giá nhập và giá vốn của hàng hóa.",
                        price_tooltip_2: "Giá giao dịch sẽ được phân bổ lại khi phát sinh chi phí hoặc giảm giá đi theo giao dịch đó.",
                        last_inventory: "Tồn cuối",
                        transporter: "Đối tác"
                    },
                    maxInventory: {
                        on_show_quantity: "SL trưng bày",
                        min_inventory: "Tồn kho Min",
                        max_inventory: "Tồn kho Max",
                        value: "Giá trị",
                    },
                    inventory: {
                        quantity_order: "Đặt NCC",
                        customer_order: "KH đặt",
                        expected_out_of_stock: "Dự kiến hết hàng",
                    },
                    batch: {
                        batch_number: "Số lô",
                        expired: "Hạn sử dụng",
                    },
                    combo: {
                        STT: "STT",
                        component_row_name: "Tên hàng thành phần"
                    }
                },
                button: {
                    save: "Lưu",
                    export_excel: "Xuất excel",
                    export_file: "Xuất file",
                    add: "Thêm",
                    add_product: "Thêm hàng hoá thủ công",
                    upload: "File Upload",
                    option: "Tùy chọn",
                    update: "Cập nhật",
                    copy: "Sao chép",
                    print: "In tem mã",
                    in_active: "Ngừng kinh doanh",
                    active: "Cho phép kinh doanh",
                    remove: "Xóa",
                    skip: "Bỏ qua",
                    fix: "Sửa",
                    purchase_product: "Nhập hàng",
                    order_supplier: "Đặt hàng nhập",
                    add_order: "Thêm đơn hàng",
                    add_invoice: "Thêm hoá đơn",
                    inventory_plan: "Dự trù chi tiết",
                    confirm: "Xác nhận",
                    close: "Đóng",
                    agree: "Đồng ý"
                }, 
                alerts: {
                    valid_max_inventory: "Tồn kho max không được nhỏ hơn hoặc bằng tồn kho min",
                },
                modal: {
                    title_create: "Thêm hàng hoá",
                    title_update: "Sửa hàng hoá",
                    title_update_multiple: "Sửa hàng loạt hàng hoá",
                    title_create_combo: "Thêm hàng hóa thành phần",
                    title_remove: "Xóa hàng hóa",
                    title_remove_multiple: "Xóa nhiều sản phẩm",
                    content_remove_multiple: "Bạn có chắc muốn xóa {number} sản phẩm khỏi Danh sách hàng hóa?",
                    content_remove: "Hệ thống sẽ xóa hoàn toàn hàng hóa {name} trên toàn bộ chi nhánh.",
                    content_remove_confirm: "Bạn có chắc chắn muốn xóa?",
                    product_name: "Hàng hóa {name}",
                    have_transaction: "đã phát sinh nghiệp vụ",
                    note_remove: "(Lưu ý: Hệ thống chỉ xóa những sản phẩm chưa có nghiệp vụ phát sinh)",
                    product_manage: "Quản lý hàng hóa: ",
                    search: "Tìm kiếm",
                    button: {
                        save: "Lưu lại",
                        skip: "Bỏ qua",
                    },
                    confirm_remove_attribute: "Bạn có muốn xóa thuộc tính ?",
                    least_one_unit: "Vui lòng thêm ít nhất 1 đơn vị của hàng hóa",
                    choose_enough_unit: "Vui lòng chọn đủ đơn vị",
                    choose_enter_unit: "Vui lòng nhập đủ đơn vị",
                    enter_price: "Vui lòng nhập giá bán hàng hóa",
                    duplicate_unit: "Đơn vị {name} bị trùng",
                    max_length: "Độ dài giới hạn là 255 ký tự. Vui lòng nhập lại.",
                    confirm_remove_product: "Xóa sản phẩm: {name} ?",
                    processed_quantity: "Số danh mục được xử lý:",
                    removed_quantity: "Số danh mục được xóa thành công:",
                    not_remove_quantity: "Số danh mục chưa được xóa:",
                    categories_have_transaction: "Danh mục đã phát sinh nghiệp vụ",
                    remove_success: "Tất cả sản phẩm được chọn đã xóa",
                    not_remove: ". Bạn không thể xóa sản phẩm này!",
                    reason: "Hệ thống chỉ xóa những sản phẩm chưa phát sinh nghiệp vụ"
                }
            },
            ModalFileUploadProducts:{
                title: "Nhập hàng hoá từ file dữ liệu",
                titleTemplate:"Tải về file mẫu:",
                titleExcel:"Excel file",
                notifyRow1:"Hệ thống cho phép nhập tối đa 5000 mặt hàng mỗi lần từ file.",
                notifyRow2:"Mã hàng không chứa kí tự đặc biệt (@, #, $, *, /, ...) và chữ có dấu",
                titleActionExistCode: "Xử lý trùng mã hàng",
                titleErrorStop: "Báo lỗi và dừng import khi trùng mã hàng",
                titleCreateProductNew: "Thêm mới hàng hóa",
                titleUpdateProduct: "Cập nhật thông tin hàng hóa",
                updateCreateProduct: "Cập nhật và thêm mới hàng hóa",
                titleUpdateInventory: "Cập nhật tồn kho",
                titleUpdateInventoryYes: "Có",
                titleUpdateInventoryNo: "Không",
                titleStatusAppliesAbove: "Trạng thái kinh doanh áp dụng trên",
                notifyRow3:"Trạng thái kinh doanh trong file import chỉ áp dụng cho các chi nhánh được lựa chọn.",
                notifyRow4:"Lưu ý khi thêm mới hàng hóa, các chi nhánh không lựa chọn sẽ có trạng thái ngược lại",
                titleAllBranch: "Toàn hệ thống",
                titleSelectBranch: "Chi nhánh",
                titlePlaceHolderSelectBranch: "Chọn chi nhánh áp dụng",
            },
            ModalProductFormTabMain: {
                code: "Mã hàng",
                name: "Tên hàng",
                category: "Nhóm hàng",
                type: "Loại hàng",
                hs_code: "HS code",
                tax_rate: "Thuế suất",
                image: "Ảnh sản phẩm",
                batch: "Lô, hạn sử dụng",
                medicine: "Thuốc",
                medicine_name: "Tên thuốc",
                medicine_code: "Mã thuốc",
                medicine_short_name: "Tên viết tắt",
                medicine_road_for_use: "Đường dùng",
                ingredient: "Hoạt chất",
                manufacturer: "Hãng sản xuất",
                country: "Nước sản xuất",
                packing: "Quy cách đóng gói",
                description: "Mô tả",
                branch: "Kho kinh doanh",
                register_number: "Số đăng ký",
                content: "Hàm lượng",
                unit: "Đơn vị của sản phẩm",
                auto_price: "Tự tính giá theo giá trị quy đổi",
                attribute: 'Thuộc tính',
                listVariant: 'Danh sách hàng hóa cùng loại',
                lifeTime: 'Số ngày sử dụng',
                table: {
                    stt: "STT",
                    unit_name: "Tên đơn vị",
                    conversion_value: "Giá trị chuyển đổi",
                    conversion_calculation: "Phép tính chuyển đổi",
                    price: "Giá bán",
                    unit_main: "ĐVT chính",
                    unit_conversion: "ĐVT chuyển đổi",
                    placeholder: {
                        unit_name: "Chọn đơn vị",
                        price: "Vui lòng nhập giá bán",
                    }
                },
                button:{
                    remove: "Xóa",
                    add_unit: "Thêm đơn vị",
                    multiplication: "Phép nhân",
                    division: "Phép chia",
                    attribute_plus: 'Thêm thuộc tính',
                },
                placeholder: {
                    code: "Mã hàng tự động",
                    name: "Nhập tên hàng hoá",
                    category: "Nhóm hàng",
                    type: "Chọn loại hàng",
                    hs_code: "Nhập HS Code",
                    tax_rate: "Chọn thuế suất hàng hóa",
                    image: "Ảnh sản phẩm",
                    batch: "Lô, hạn sử dụng",
                    medicine: "Thuốc",
                    medicine_name: "Nhập tên thuốc",
                    medicine_code: "Mã thuốc",
                    medicine_short_name: "Nhập tên viết tắt",
                    medicine_road_for_use: "Chọn đường dùng",
                    ingredient: "Nhập hoạt chất hàng hoá",
                    manufacturer: "Nhập hãng sản xuất hàng hoá",
                    country: "Nhập nước sản xuất hàng hoá",
                    packing: "Nhập quy cách đóng gói hàng hoá",
                    description: "Nhập mô tả hàng hóa",
                    branch: "Chọn kho kinh doanh",
                    attribute: "Chọn thuộc tính",
                    lifeTime: 'Nhập số ngày sử dụng hàng hóa',
                }
            },
            ModalCreateRouteOfUse: {
                title_create: "Thêm đường dùng",
                title_update: "Cập nhật đường dùng",
                name: "Tên đường dùng",
                placeholder: {
                    name: "Nhập tên đường dùng",
                },
                button: {
                    create: "Thêm mới",
                    update: "Cập nhật",
                    skip: "Bỏ qua",
                    remove: "Xóa"
                },
                alert: {
                    delete_success: "Xóa thành công",
                    delete_error: "Xóa thất bại",
    
                    create_success: "Thêm mới thành công",
                    create_error: "Thêm mới thất bại",
                    update_success: "Cập nhật thành công",
                    update_error: "Cập nhật thất bại"
                }
            },

            // Nhóm hàng hóa
            CategoriesIndex: {
                title: "Nhóm hàng hóa",
                document_title: "Danh mục hàng hóa | OmiPos",
                placeholder: {
                    search: 'Tìm kiếm',
                },
                button: {
                    add: "Thêm",
                },
                modal:{
                    title_create: "Thêm nhóm hàng",
                    title_update: "Sửa nhóm hàng",
                    title_remove: "Xóa nhóm hàng",
                    name: "Tên nhóm",
                    parent_category: "Nhóm cha",
                    content_remove: "Bạn có chắc chắn muốn xóa nhóm hàng này?",
                    contentCancelUpdate: "Bạn có muốn hủy cập nhật nhóm hàng hóa?",
                    contentCancelCreate: "Bạn có muốn hủy thêm nhóm hàng hóa?",
                    placeholder: {
                        name: "Nhập tên nhóm hàng",
                        parent_category: "Chọn nhóm cha",
                    },
                    button: {
                        add: "Thêm mới",
                        update: "Cập nhật",
                        skip: "Bỏ qua",
                        remove: "Xóa",
                        confirm: "Đồng ý"
                    }
                }
            },
            ModalFileUploadCategories:{
                title: "Thêm nhóm hàng hóa từ file dữ liệu",
                titleTemplate:"Tải về file mẫu:",
                titleExcel:"Excel file",
                notifyRow1:"Hệ thống cho phép nhập tối đa 5000 mặt hàng mỗi lần từ file.",
                notifyRow2:"Mã hàng không chứa kí tự đặc biệt (@, #, $, *, /, ...) và chữ có dấu",
                titleActionExistCode: "Xử lý nhóm hàng",
                titleErrorStop: "Báo lỗi và dừng import khi có nhóm hàng mới và trùng nhóm hàng",
                titleCreateCategoryNew: "Thêm nhóm hàng mới",
                titleUpdateProduct: "Cập nhật thông tin hàng hóa",
                updateCreateProduct: "Cập nhật và thêm mới hàng hóa",
                titleUpdateInventory: "Cập nhật tồn kho",
                titleUpdateInventoryYes: "Có",
                titleUpdateInventoryNo: "Không",
                titleStatusAppliesAbove: "Trạng thái kinh doanh áp dụng trên",
                notifyRow3:"Trạng thái kinh doanh trong file import chỉ áp dụng cho các chi nhánh được lựa chọn.",
                notifyRow4:"Lưu ý khi thêm mới hàng hóa, các chi nhánh không lựa chọn sẽ có trạng thái ngược lại",
                titleAllBranch: "Toàn hệ thống",
                titleSelectBranch: "Chi nhánh",
                titlePlaceHolderSelectBranch: "Chọn chi nhánh áp dụng",
            },

            // Đơn vị hàng hóa
            UnitIndex: {
                title: "Đơn vị hàng hóa",
                document_title: "Đơn vị hàng hóa | OmiPos",
                placeholder: {
                    search: 'Tìm kiếm',
                },
                button: {
                    add: "Thêm",
                },
                modal:{
                    title_remove: "Xóa đơn vị hàng hóa",
                    content_remove: "Bạn có chắc chắn muốn xóa đơn vị hàng hóa này?",
                    button: {
                        skip: "Bỏ qua",
                        confirm: "Đồng ý"
                    }
                }
            },
            modalCreateUpdateUnit: {                
                title_create: "Thêm đơn vị hàng hóa",
                title_update: "Sửa đơn vị hàng hóa",
                title_confirm: "Xác nhận",
                unit_name: "Tên đơn vị",
                message_confirm_create: "Bạn có muốn hủy thêm đơn vị hàng hóa?",
                message_confirm_update: "Bạn có muốn hủy cập nhật đơn vị hàng hóa?",
                placeholder: {
                    name: "Tên đơn vị"
                },
            },

            // Hàng hóa liên kết
            medicineProductMapping: {
                title: "Hàng hóa liên kết",
                title_document: "Hàng hóa liên kết | OmiPos",
                placeholder: {
                    search: "Theo tên thuốc",
                    chooseCategories: "Chọn nhóm hàng"
                },
                filter: {
                    title: {
                        category: "Nhóm hàng",
                        status: "Trạng thái"
                    }
                },
                message_connect_success: "Kết nối thuốc thành công",
                message_connect_error: "Không tìm thấy hàng hóa liên kết",
                message_disconnect_success: "Ngắt kết nối thành công",
                err: "Lỗi server",
                all: "Tất cả",
                linked: "Đã liên kết",
                noLink: "Chưa liên kết",
                productOmipos: "Hàng trên Omipos",
                medicineCode: "Mã thuốc DQG",
                removeLinked: "Ngắt kết nối",
            },

            // Danh mục thuốc
            medicine: {
                title: "Danh mục thuốc",
                title_index: "Liên kết Danh mục thuốc",
                title_document: "Danh mục thuốc | OmiPos",
                import: "Import",
                export: "Xuất file",
                placeholder: {
                    search: "Theo tên thuốc",
                    number: "Theo số đăng kí",
                    code: "Theo mã thuốc",
                    content: "Theo hoạt chất, hàm lượng",
                    country: "Theo hãng, nước sản xuất"
                },
                modal: {
                    title: "Nhập danh mục thuốc dược quốc gia từ file dữ liệu",
                    button: "Chọn file dữ liệu"
                }
            },
            MedicineSearch: {
                placeholder: {
                    name: "Nhập tên thuốc"
                },
                table: {
                    name: "Tên thuốc",
                    registerNumber: "Số đăng ký",
                    ingredient: "Hoạt chất",
                    content: "Hàm lượng",
                    packing: "Quy cách đóng gói",
                    manufacturer: "Hãng sản xuất",
                }
            },

            // Danh sách khách hàng
            customerIndex: {
                title: "Khách hàng",
                documentTitle: "Quản lý khách hàng | OMIPOS",
                customerGroup: "Nhóm khách hàng",
                createdAt: "Ngày tạo",
                createdBy: "Người tạo",
                birthDate: "Sinh nhật",
                lastTransactionDate: "Ngày giao dịch cuối",
                totalSale: "Tổng bán",
                type: "Loại khách",
                all: "Tất cả",
                individual: "Cá nhân",
                company: "Công ty",
                gender: "Giới tính",
                male: "Nam",
                female: "Nữ",
                deliveryArea: "Khu vực giao hàng",
                status: "Trạng thái",
                active: "Đang hoạt động",
                inActive: "Ngừng hoạt động",
                reActive: "Cho phép hoạt động",
                quantity: "Số lượng",
                discount: "Giảm giá",
                note: "Ghi chú",
                code: "Mã khách hàng",
                name: "Tên khách hàng",
                birthDay: "Ngày sinh",
                companyName: "Tên công ty",
                taxCode: "Mã số thuế",
                email: "Email",
                facebook: "Facebook",
                accountNumber: "Số tài khoản",
                accountOwner: "Chủ tài khoản",
                bankName: "Tên ngân hàng",
                bankAddress: "Chi nhánh ngân hàng",
                phone: "Số điện thoại",
                address: "Địa chỉ",
                ward: "Phường xã",
                debtDate: "Hạn nợ",
                employee: "Nhân viên phụ trách",
                addressName: "Tên địa chỉ",
                receiverName: "Tên người nhận",
                receiverPhone: "SĐT nhận",
                receiverAddress: "Địa chỉ nhận",
                orderCode: "Mã đặt hàng",
                time: "Thời gian",
                sellerName: "Người bán",
                branch: "Chi nhánh",
                total: "Tổng cộng",
                invoiceCode: "Mã hóa đơn",
                confirm: "Xác nhận",
                contentInActive: "Bạn có chắc chắn muốn ngừng hoạt động của khách này. Thông tin giao dịch của khách hàng này sẽ vẫn được giữ.",
                contentReActive: "Bạn có chắc chắn muốn cho phép khách này hoạt động trở lại",
                contentConfirmSkip: "Bạn chắc chắn muốn hủy thay đổi?",
                contentConfirm: "Bạn chắc chắn muốn hủy chỉnh sửa",
                change: "thay đổi",
                branchActive: "Chi nhánh hoạt động",
                button: {
                    add: "Thêm",
                    addCustomer: "Khách hàng",
                    file: "File",
                    import: "Import",
                    export: "Xuất file",
                    update: "Cập nhật",
                    remove: "Xóa",
                    new: "Thêm mới",
                    agree: "Đồng ý",
                    skip: "Bỏ qua",
                    action: "Thực hiện",
                },
                placeholder:{
                    search: "Tìm kiếm",
                    searchCustomer: "Theo mã, tên, điện thoại",
                    chooseArea: "Chọn tỉnh/TP - Quận/Huyện"
                },
                tabs: {
                    info: "Thông tin",
                    address: "Địa chỉ nhận hàng",
                    historyOrder: "Lịch sử đặt hàng",
                    historySale: "Lịch sử bán/ trả hàng"
                },
                values: {
                    info: "info",
                    address: "addressGetOrder",
                    historyOrder: "historyOrder",
                    historySale: "historySale"
                },
                modal: {
                    titleCreate: "Thêm mới khách hàng",
                    titleUpdate: "Cập nhật khách hàng",
                    branch: "Chi nhánh tạo",
                    customerCode: "Mã khách hàng",
                    customerName: "Tên khách hàng",
                    customerPhoneNumber: "Số điện thoại",
                    customerBirthDate: "Ngày sinh",
                    customerGender: "Giới tính",
                    customerAddress: "Địa chỉ",
                    customerAccountNumber: "Số tài khoản",
                    customerAccountName: "Chủ tài khoản",
                    customerBankName: "Tên ngân hàng",
                    customerBankAddress: "Chi nhánh ngân hàng",
                    customerType: "Loại khách",
                    personal: "Cá nhân",
                    company: "Công ty",
                    customerCompanyName: "Tên công ty",
                    customerTaxCode: "Mã số thuế",
                    customerEmail: "Email",
                    customerFacebook: "Facebook",
                    customerGroup: "Nhóm",
                    customerNote: "Ghi chú",
                    customerDebtDate: "Hạn nợ",
                    user: "Nhân viên phụ trách",
                    paymentTerms: "Điều khoản thanh toán",
                    maxDebtNumber: "Số nợ tối đa",
                    isSupplier: "Kiêm nhà cung cấp",
                    button: {
                        save: "Lưu lại",
                        skip: "Bỏ qua"
                    },
                    defaultCode: "Mã mặc định",
                    enterCustomerName: "Nhập tên khách hàng",
                    enterPhone1: "Nhập số điện thoại 1",
                    enterPhone2: "Nhập số điện thoại 2",
                    enterAddress: "Nhập địa chỉ",
                    enterAccountNumber: "Nhập STK",
                    enterAccountOwner: "Nhập tên chủ tài khoản",
                    enterBankName: "Nhập tên ngân hàng",
                    enterBankAddress: "Nhập tên chi nhánh ngân hàng",
                    enterCompanyName: "Nhập tên công ty",
                    enterTaxCode: "Nhập mã số thuế",
                    enterEmail: "Nhập Email",
                    enterFacebook: "Nhập facebook",
                    chooseCustomerGroup: "Chọn nhóm khách hàng",
                    enterNote: "Nhập ghi chú",
                    enterDebtDate: "Nhập số ngày được nợ",
                    choosePaymentDate: "Chọn ngày thanh toán định kì",
                    enterEmployee: "Nhân viên phụ trách",
                    enterPaymentCondition: "Nhập điều khoản thanh toán",
                    enterMaxDebt: "Nhập số nợ tối đa",
                    notFoundInfoTax: "Không tìm thấy dữ liệu trên cục thuế",
                    titleImport: "Nhập khách hàng từ file dữ liệu",
                    downloadDefaultFile: "Tải về file mẫu:",
                    excelFile: "Excel file",
                    allowUpdateWhenDuplicateCode: "Cho phép cập nhật thông tin khi mã khách hàng đã tồn tại trên hệ thống.",
                    allowDuplicateEmail: "Cho phép khách hàng trùng email",
                    chooseFile: "Chọn file dữ liệu",
                    inValidFormatFile: "File upload phải đúng định dạng xlsx, xls",
                    titleRemove: "Xóa khách hàng",
                    contentRemove: "Hệ thống sẽ xóa hoàn toàn khách hàng {name} nhưng vẫn giữ những giao dịch lịch sử nếu có. Bạn có chắc chắn muốn xóa không?",
                    alertRemoveSuccess: "Xóa khách hàng {name} thành công."
                }
            },

            // Nhóm khách hàng
            customerGroupIndex: {
                title: "Nhóm khách hàng",
                documentTitle: "Danh sách nhóm khách hàng",
                quantity: "Số lượng",
                discount: "Giảm giá",
                note: "Ghi chú",
                button: {
                    add: "Thêm",
                    save: "Lưu",
                    skip: "Bỏ qua",
                    remove: "Xóa",
                    agree: "Đồng ý",
                },
                placeholder:{
                    search: "Tìm kiếm",
                },
                titleCreate: "Thêm nhóm khách hàng",
                titleUpdate: "Sửa nhóm khách hàng",
                info: "Thông tin",
                advanceSetting: "Thiết lập nâng cao",
                name: "Tên nhóm",
                enterName: "Nhập tên nhóm khách hàng",
                chooseDiscount: "Chọn giảm giá",
                vnd: "VNĐ",
                percent: "%",
                settingConditional: "Thiết lập điều kiện thêm khách hàng vào nhóm",
                add: "Thêm khách hàng vào nhóm điều kiện.",
                update: "Cập nhật lại danh sách theo điều kiện.",
                notUpdate: "Không cập nhật danh sách khách hàng.",
                confirm: "Xác nhận",
                confirmContent: "Bạn có muốn hủy thêm mới nhóm khách hàng?",
                contentSkip: "Bạn chắc chắn muốn hủy chỉnh sửa nhóm khách hàng này không?",
                alertSuccess: "Thêm mới nhóm khách hàng thành công",
                alertCreateSuccess: "Thêm mới thành công",
                alertUpdateSuccess: "Cập nhật nhóm khách hàng thành công",
                titleRemove: "Xóa nhóm khách hàng",
                contentRemove: "Bạn có chắc muốn xóa nhóm khách hàng này không ?",
                description: "Mô tả",
            },

            // Danh sách nhà cung cấp
            supplierIndex: {
                title: "Nhà cung cấp",
                documentTitle: "Quản lý nhà cung cấp | OMIPOS",
                quantity: "Số lượng",
                discount: "Giảm giá",
                note: "Ghi chú",
                supplierGroup: "Nhóm nhà cung cấp",
                allGroup: "Tất cả các nhóm",
                totalBuy: "Tổng mua",
                status: "Trạng thái",
                all: "Tất cả",
                active: "Hoạt động",
                inActive: "Ngừng hoạt động",
                reActive: "Cho phép hoạt động",
                searchSupplier: "Theo mã, tên, điện thoại",
                code: "Mã nhà cung cấp",
                name: "Tên nhà cung cấp",
                address: "Địa chỉ",
                deliveryArea: "Khu vực giao hàng",
                ward: "Phường xã",
                phone: "Điện thoại",
                email: "Email",
                accountNumber: "Số tài khoản",
                accountOwner: "Chủ tài khoản",
                bankName: "Tên ngân hàng",
                bankAddress: "Chi nhánh ngân hàng",
                company: "Công ty",
                taxCode: "Mã số thuế",
                createdBy: "Người tạo",
                createdAt: "Ngày tạo",
                debtDate: "Hạn nợ",
                employee: "Nhân viên phụ trách",
                numberSKU: "Số SKU nhập NCC",
                amountImport: "Doanh số nhập",
                openingFee: "Phí mở mã NCC",
                paymentDiscount: "Chiết khấu thanh toán",
                confirm: "Xác nhận",
                contentConfirmCancelCreate: "Bạn có muốn hủy thêm nhà cung cấp?",
                contentConfirmCancelUpdate: "Bạn có muốn hủy cập nhật nhà cung cấp?",
                button: {
                    add: "Thêm",
                    addSupplier: "Nhà cung cấp",
                    update: "Cập nhật",
                    remove: "Xóa",
                },
                placeholder:{
                    search: "Tìm kiếm",
                },
                tabs: {
                    info: "Thông tin",
                    paymentForSupplier: "Nợ cần trả nhà cung cấp",
                    historyOrder: "Lịch sử đặt hàng nhập",
                    historyPurchaseReturn: "Lịch sử nhập/trả hàng"
                },
                values: {
                    info: "info",
                    address: "addressGetOrder",
                    historyOrder: "historyOrder",
                },
                table: {
                    code: "Mã phiếu",
                    time: "Thời gian",
                    createBy: "Người tạo",
                    branch: "Chi nhánh",
                    total: "Tổng cộng",
                    status: "Trạng thái"
                },
                modal: {
                    titleCreate: "Thêm nhà cung cấp",
                    titleUpdate: "Sửa nhà cung cấp",
                    branch: "Chi nhánh",
                    supplierCode: "Mã nhà cung cấp",
                    supplierName: "Tên nhà cũng cấp",
                    supplierPhoneNumber: "Số điện thoại",
                    supplierBirthDate: "Ngày sinh",
                    supplierGender: "Giới tính",
                    supplierAddress: "Địa chỉ",
                    supplierProvince: "Tỉnh/Thành phố",
                    supplierDistrict: "Quận/Huyện",
                    supplierWard: "Phường/Xã",
                    supplierAccountNumber: "Số tài khoản",
                    supplierAccountName: "Chủ tài khoản",
                    supplierBankName: "Tên ngân hàng",
                    supplierBankAddress: "Chi nhánh ngân hàng",
                    supplierType: "Loại khách",
                    personal: "Cá nhân",
                    company: "Công ty",
                    supplierCompanyName: "Tên công ty",
                    supplierTaxCode: "Mã số thuế",
                    supplierEmail: "Email",
                    supplierFacebook: "Facebook",
                    supplierGroup: "Nhóm NCC",
                    supplierNote: "Ghi chú",
                    supplierDebtDate: "Hạn nợ",
                    user: "Nhân viên phụ trách",
                    paymentTerms: "Điều khoản thanh toán",
                    maxDebtNumber: "Số nợ tối đa",
                    isCustomer: "Kiêm khách hàng",
                    openingFee: "Phí mở mã NCC",
                    paymentDiscount: "Chiết khấu thanh toán",
                    enterName: "Nhập tên NCC",
                    enterPhone: "Nhập số điện thoại",
                    enterAddress: "Nhập địa chỉ",
                    chooseProvince: "Chọn tỉnh/thành phố",
                    chooseDistrict: "Chọn quận/huyện",
                    chooseWard: "Chọn phường/xã",
                    enterAccountNumber: "Nhập STK",
                    enterAccountOwner: "Nhập tên chủ tài khoản",
                    enterBankName: "Nhập tên ngân hàng",
                    enterBankAddress: "Nhập tên chi nhánh ngân hàng",
                    enterEmail: "Nhập email",
                    enterCompanyName: "Nhập công ty",
                    enterTaxCode: "Nhập mã số thuế",
                    chooseSupplierGroup: "Chọn nhóm NCC",
                    enterNote: "Nhập ghi chú",
                    enterDebtDate: "Nhập số ngày được nợ",
                    choosePaymentDate: "Chọn ngày thanh toán định kì",
                    enterPaymentTerm: "Nhập điều khoản thanh toán",
                    enterMaxDebt: "Nhập số nợ tối đa",
                    tooltip1: "Hệ thống tự động kiểm tra và liên kết trong trường hợp đã tồn tại KH",
                    tooltip2: "Trường hợp không tồn tại khách hàng, hệ thống tự động tạo mới KH",
                    titleUpload: "Nhập nhà cung cấp file dữ liệu",
                    replaceAndUpdate: "Thay thế và cập nhật thông tin khi trùng mã nhà cung cấp",
                    notReplaceAndUpdate: "Không cho phép thay thế và cập nhật thông tin khi trùng mã nhà cung cấp",
                    chooseFile: "Chọn file dữ liệu",
                    titleRemove: "Xóa nhà cung cấp",
                    contentRemove: "Hệ thống sẽ xóa hoàn toàn Nhà cung cấp nhưng vẫn giữ những giao dịch lịch sử nếu có. Bạn có chắc chắn muốn xóa không.",
                    button: {
                        save: "Lưu lại",
                        skip: "Bỏ qua",
                        action: "Thực hiện"
                    }
                }
            },

            // Nhóm nhà cung cấp
            SupplierGroupIndex: {
                title: "Nhóm nhà cung cấp",
                documentTitle: "Danh sách nhóm nhà cung cấp | OMIPOS",
                search: "Tìm kiếm",
                add: "Thêm",
                titleCreate: "Thêm nhóm nhà cung cấp",
                name: "Tên nhóm",
                enterName: "Nhập tên nhóm nhà cung cấp",
                note: "Ghi chú",
                confirm: "Xác nhận",
                confirmCancelChange: "Bạn có muốn hủy thêm nhóm nhà cung cấp?",
                titleCancelEdit: "Hủy chỉnh sửa nhóm nhà cung cấp",
                contentCancelEdit: "Bạn có muốn hủy chỉnh sửa nhóm nhà cung cấp?",
                titleUpdate: "Sửa nhóm nhà cung cấp",
                titleRemove: "Xóa nhóm nhà cung cấp",
                contentRemove: "Bạn có chắc muốn xóa nhóm nhà cung cấp này không ?",
                contentInActive: "Bạn có chắc chắn muốn ngừng hoạt động của nhà cung cấp này?. Thông tin giao dịch của nhà cung cấp này sẽ vẫn được giữ.",
                contentReActive: "Bạn có chắc chắn muốn cho phép nhà cung cấp này hoạt động trở lại?",
                quantity: "Số lượng",
            },

            // Danh sách nhân viên
            employeeIndex: {
                title: "Danh sách nhân viên",
                employee_code: "Mã nhân viên",
                employee_name: "Tên nhân viên",
                employee_birthday: "Ngày sinh",
                employee_gender: "Giới tính",
                employee_department: "Phòng ban",
                employee_job_title: "Chức danh",
                employee_branch_work: "Chi nhánh làm việc",
                employee_user_name: "Tên đăng nhập",
                employee_phone: "Số điện thoại",
                employee_email: "Email",
                employee_status: "Trạng thái",
                employee_created_at: "Thời gian tạo",
                note: "Ghi chú...",
                chooseBranch: "Chọn chi nhánh",
                chooseJobTitle: "Chọn chức danh",
                chooseDepartment: "Chọn phòng ban",
                chooseRole: "Chọn vai trò",
                tab: {
                    info: "Thông tin"
                },
                button: {
                    update: "Cập nhật",
                    inActive: "Ngừng hoạt động",
                    active: "Cho phép hoạt động",
                    remove: "Xóa nhân viên",
                    add: "Nhân viên",
                    file: "File",
                    import: "Import",
                    export: "Xuất file",
                    skip: "Bỏ qua",
                    agree: "Đồng ý",
                },
                input:{
                    filter: "Theo mã, tên, số điện thoại",
                },
                titlRemove: "Xóa nhân viên",
                contentRemove: "Hệ thống sẽ xóa hoàn toàn nhân viên này nhưng vẫn giữ các phiếu lương nếu có. Bạn có chắc chắn muốn xóa?",
                alertRemoveSuccess: "Xóa nhân viên {name} thành công.",                
                alertRemoveError: "Xóa nhân viên {name} không thành công.",
                confirmReActive: "Bạn có muốn hoạt động trở lại nhân viên này không ?",
                confirmInActive: "Bạn có chắc chắn muốn ngừng hoạt động của nhân viên này.",
                alertUpdateSuccess: "Cập nhật người dùng thành công",                
            },
            modalCreateEmployee: {
                title_create: "Thêm mới nhân viên",
                title_update: "Cập nhật nhân viên",
                code: "Mã nhân viên",
                name: "Tên nhân viên",
                birth_date: "Ngày sinh",
                gender: "Giới tính",
                male: "Nam",
                female: "Nữ",
                phone_number: "Số điện thoại",
                email: "Email",
                department: "Phòng ban",
                job_title: "Chức danh",
                can_login: "Là nhân viên thao tác trên omipos",
                branch: "Chi nhánh làm việc",
                username: "Tên đăng nhập",
                password: "Mật khẩu",
                re_password: "Nhập lại mật khẩu",
                note: "Ghi chú",
                placeholder: {
                    code: "Nhập mã nhân viên",
                    name: "Nhập tên nhân viên",
                    birth_date: "Nhập ngày sinh",
                    phone_number: "Nhập số điện thoại",
                    email: "Nhập email",
                    department: "Chọn phòng ban",
                    job_title: "Chọn chức danh",
                    branch: "Chọn chi nhánh",
                    username: "Nhập tên đăng nhập",
                    password: "Nhập mật khẩu",
                    note: "Nhập ghi chú"
                },
                button: {
                    save: "Lưu lại",
                    skip: "Bỏ qua",
                    quickSave: "Lưu nhanh và phân quyền"
                },
                confirmCancelChange: "Bạn chắc chắn muốn hủy thay đổi?",
                confirm: "Xác nhận",
                emptyName: "Bạn chưa nhập Tên nhân viên",
                emptyDepartment: "Bạn chưa chọn Phòng ban",
                emptyJobTitle: "Bạn chưa chọn Chức danh",
                emptyWorkingBranch: "Bạn chưa chọn chi nhánh làm việc",
                emptyPassword: "Bạn chưa nhập mật khẩu",
                emptyRePassword: "Bạn chưa nhập Nhập lại mật khẩu",
                regexPassword: "Mật khẩu tối thiểu 8 ký tự, có chữ hoa, chữ thường, số, ký tự đặc biệt",
                invalidPassword: "Hai mật khẩu chưa trùng khớp",
                alertCreateSuccess: "Thêm mới nhân viên thành công",
                alertUpdateSuccess: "Cập nhật nhân viên thành công",
                alertCreateError: "Thêm mới nhân viên không thành công"
            },
            ModalImportEmployees:{
                headerTitle: "Nhập nhân viên từ file dữ liệu",
                templateTitle: "Tải về file mẫu:",
                urlTitle: "Excel file",
                contentTitle: "Xử lý trùng mã nhân viên, khác thông tin nhân viên:",
                errStopTitle: "Báo lỗi và dừng import",
                changeUpdateTitle: "Thay thế thông tin nhân viên cũ bằng thông tin nhân viên mới"
            },

            // Phòng ban
            DepartmentIndex: {
                title: "Phòng ban",
                document_title: "Phòng ban | OmiPos",
                placeholder: {
                    search: "Tìm kiếm",
                },
                button: {
                    create: "Thêm",
                },
                table: {
                    name: "Tên phòng ban",
                    quantity: "Số lượng",
                    description: "Mô tả",
                    status: "Trạng thái",
                    code: "Mã phòng ban",
                },
                status: {
                    active: "Hoạt động",
                    in_active: "Ngừng hoạt động",
                },
                modal: {
                    createOrUpdate: {
                        title_create: "Thêm mới phòng ban", 
                        title_update: "Cập nhật phòng ban", 
                        name: "Tên phòng ban",
                        description: "Mô tả",
                        status: "Trạng thái",
                        active: "Hoạt động",
                        in_active: "Không hoạt động",
                        placeholder: {
                            name: "Nhập tên phòng ban",
                            code: "Nhập mã phòng ban",
                        },
                        button: {
                            save: "Lưu",
                            skip: "Bỏ qua",
                            remove: "Xóa"
                        },
                        title_confirm: "Xác nhận",
                        content_confirm_create: "Bạn có muốn hủy thêm phòng ban?",
                        content_confirm_update: "phòng ban này không?",
                        alerts: {
                            create_success: "Thêm mới phòng ban thành công",
                            update_success: "Cập nhật phòng ban thành công",
                        }
                    },
                    modalRemove: {
                        title: "Xác nhận",
                        content: "Hệ thống sẽ xóa hoàn toàn phòng ban này. Bạn có chắc chắn?",
                        button: {
                            confirm: "Đồng ý",
                            skip: "Bỏ qua"
                        }
                    }
                }
            },

            // Chức danh
            JobTitle: {
                titleCreate: "Thêm mới chức danh",
                titleUpdate: "Cập nhật chức danh",
                name: "Tên chức danh",
                description: "Mô tả",
                status: "Trạng thái",
                active: "Hoạt động",
                inActive: "Không hoạt động",
                save: "Lưu",
                skip: "Bỏ qua",
                remove: "Xóa",
                confirm: "Xác nhận",
                agree: "Đồng ý",
                cancelUpdate: "Bạn có muốn hủy cập nhật chức danh?",
                cancelCreate: "Bạn có muốn hủy thêm chức danh?",
                contentRemove: "Hệ thống sẽ xóa hoàn toàn chức danh này. Bạn có chắc chắn?",
            },

            // Đối tác giao hàng
            TransporterIndex: {
                personal: "Cá nhân",
                company: "Công ty",
                title: "Đối tác giao hàng",
                title_document: "Đối tác giao hàng | OmiPos",
                integration: "Tích hợp",
                other: "Khác",
                transporter_group: "Nhóm đối tác giao hàng",                
                total_delivery_fee: "Tổng phí giao hàng",
                fee_from: "Từ",
                fee_to: "Tới",
                current_debt: "Nợ hiện tại",
                select_show: "Lựa chọn hiển thị",
                type: "Loại đối tác",
                code: "Mã đối tác",
                name: "Tên đối tác",
                address: "Địa chỉ",
                phone: "Điện thoại",
                email: "Email",
                area_delivery: "Khu vực giao hàng",
                ward: "Phường xã",
                transporter_group_name: "Nhóm đối tác",
                note: "Ghi chú",
                feeAdjustments: "Điều chỉnh",
                invoice_code: "Mã hóa đơn",
                delivery_code: "Mã vận đơn",
                time: "Thời gian",
                invoice_value: "Giá trị HĐ",
                cod: "Còn cần thu (COD)",
                fee: "Phí trả ĐTGH",
                status: "Trạng thái",
                tiket_code: "Mã phiếu",
                type_fee: "Loại",
                value: "Giá trị",
                debt: "Nợ cần trả ĐT",
                transporterCode: "Mã đối tác giao hàng",
                placeholder: {
                    search: "Theo mã, tên, điện thoại",
                    transporter_group: "Chọn nhóm đối tác",
                    value: "Giá trị",
                    code: "Mã mặc định",
                    name: "Nhập tên đối tác",
                    phone: "Nhập số điện thoại",
                    email: "Nhập email",
                    group: "Chọn nhóm đối tác",
                    address: "Nhập địa chỉ",
                    groupName:"Nhập tên nhóm đối tác giao hàng"
                    
                },
                button: {
                    feeAdjustments: "Điều chỉnh",
                    create: "Đối tác giao hàng",
                    import: "Import",
                    export: "Xuất file",
                    update: "Cập nhật",
                    in_active: "Ngừng hoạt động",
                    remove: "Xóa",
                    active: "Cho phép hoạt động",
                    update_delivery: "Cập nhật giao hàng",
                    payment: "Thanh toán",
                },
                tabs: {
                    info: "Thông tin",
                    history: "Lịch sử giao hàng",
                    return_fee: "Phí cần trả đối tác giao hàng",
                    control_history: "Phí cần trả đối tác giao hàng",
                },
                modal: {
                    // Modal xóa transport
                    modalRemove: {
                        title: "Xác nhận",
                        content: "Hệ thống chỉ cho phép xóa những đối tác giao hàng chưa phát sinh giao dịch. Bạn có chắc chắn muốn xóa?",
                        button: {
                            confirm: "Đồng ý",
                            skip: "Bỏ qua"
                        }
                    },
                    // Modal ngừng hoạt động
                    modalStop: {
                        title: "Xác nhận",
                        content: "Bạn có chắc chắn muốn Ngừng hoạt động đối tác giao hàng này?",
                        button: {
                            confirm: "Đồng ý",
                            skip: "Bỏ qua"
                        }
                    },
                    modalAddTransporters: {
                        name: "Tên nhóm",
                        namePlaceholder: "Nhập tên nhóm hàng",
                        note: "Ghi chú",
                        notePlaceholder: "  Ghi chú",
                        buttonAddUpdate: {
                            confirm: "Lưu",
                            skip: "Bỏ qua"
                        },
                        buttonRemove: "Xóa",
                        add: {
                            title: "Thêm mới đối tác giao hàng",
                            button: {
                                confirm: "Lưu",
                                skip: "Bỏ qua"
                            },  
                            msgSuccess:"Thêm đối tác giao hàng mới thành công",
                            msgError:"Thêm đối tác giao hàng mới không thành công",                      
                        },
                        update: {
                            title: "Cập nhật đối tác giao hàng", 
                            button: {
                                confirm: "Lưu",
                                skip: "Bỏ qua"
                            },
                            msgSuccess:"Cập nhật đối tác giao hàng mới thành công",
                            msgError:"Cập nhật đối tác giao hàng mới không thành công",  
                        },
                        remove: {
                            title: "Xác nhận", 
                            content: "Bạn chắc chắn muốn xóa nhóm đối tác giao hàng {name} này không?",
                            button: {
                                confirm: "Đồng ý",
                                skip: "Bỏ qua"
                            },
                            msgSuccess:"Xóa nhóm đối tác giao hàng mới thành công",
                            msgError:"Xóa nhóm đối tác giao hàng mới không thành công",  
                        },
                    },
                    modalAddTransporterGroups: {
                        name: "Tên nhóm",
                        namePlaceholder: "Nhập tên nhóm hàng",
                        note: "Ghi chú",
                        notePlaceholder: "  Ghi chú",
                        buttonAddUpdate: {
                            confirm: "Lưu",
                            skip: "Bỏ qua"
                        },
                        buttonRemove: "Xóa",
                        add: {
                            title: "Thêm mới nhóm đối tác giao hàng",
                            button: {
                                confirm: "Lưu",
                                skip: "Bỏ qua"
                            },  
                            msgSuccess:"Thêm nhóm đối tác giao hàng mới thành công",
                            msgError:"Thêm nhóm đối tác giao hàng mới không thành công",                      
                        },
                        update: {
                            title: "Cập nhật nhóm đối tác giao hàng", 
                            button: {
                                confirm: "Lưu",
                                skip: "Bỏ qua"
                            },
                            msgSuccess:"Cập nhật nhóm đối tác giao hàng mới thành công",
                            msgError:"Cập nhật nhóm đối tác giao hàng mới không thành công",  
                        },
                        remove: {
                            title: "Xác nhận", 
                            content: "Bạn chắc chắn muốn xóa nhóm đối tác giao hàng {name} này không?",
                            button: {
                                confirm: "Đồng ý",
                                skip: "Bỏ qua"
                            },
                            msgSuccess:"Xóa nhóm đối tác giao hàng mới thành công",
                            msgError:"Xóa nhóm đối tác giao hàng mới không thành công",  
                        },
                    },
                    modelSelectInvoicePayment: {
                        title: "Thanh toán với đối tác giao hàng",
                        search: "Tìm kiếm",
                        placeholder: {
                            invoice: "Theo mã hóa đơn",
                            delivery: "Theo mã vận đơn",
                            control: "Theo mã đối soát",
                        },
                        branch: "Chi nhánh",
                        status_delivery: "Trạng thái giao hàng",
                        time: "Thời gian",
                        start_date: "Từ ngày",
                        end_date: "Đến ngày",
                        invoice_code: "Mã hóa đơn",
                        delivery_code: "Mã vận đơn",
                        control_code: "Mã đối soát",
                        customer: "Khách hàng",
                        transporter: "Đối tác giao hàng",
                        invoice_value: "Giá trị HĐ",
                        cod: "Cần thu hộ",
                        receive_cod: "Thu tiền hộ",
                        fee_payment: "Phí trả ĐTGH",
                        debt_fee_payment: "Nợ phí GH",
                        cod_fee_payment: "COD - Phí trả ĐTGH",
                        button: {
                            payment: "Thanh toán",
                            skip: "Bỏ qua",
                        }
                    }
                }
            },

            // Kênh bán
            SaleChannelIndex: {
                title: "Kênh bán",
                status: "Trạng thái",
                search: "Tìm kiếm",
                add: "Thêm",
                documentTitle: "Danh sách kênh bán | OMIPOS",
                all: "Tất cả",
                active: "Đang hoạt động",
                inActive: "Ngừng hoạt động",
                titleCreate: "Thêm kênh bán",
                titleUpdate: "Sửa kênh bán",
                name: "Tên kênh bán",
                description: "Mô tả",
                confirm: "Xác nhận",
                contentConfirmCancelCreate: "Bạn có muốn hủy thêm chức danh?",
                contentConfirmCancelUpdate: "Bạn có muốn hủy cập nhật chức danh?",
                titleRemove: "Xóa kênh bán",
                contentRemove: "Kênh bán chỉ được xóa khi không có giao dịch nào. bạn có chắc chắn muốn xóa kênh {name} không?",
                alertRemoveSuccess: "Xóa kênh bán {name} thành công.",
            },

            // Danh mục bảng giá
            PriceListsIndex: {
                title: "Bảng giá chung",
                document_title: "Bảng giá chung | OmiPos",
                time: "Thời gian",
                branch: "Chi nhánh",
                status: "Trạng thái",
                effect: "Hiệu lực",
                placeholder: {
                    search: "Nhập tên bảng giá",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    create: "Thêm bảng giá",
                    file: "File",
                    import: "Import",
                    export: "Xuất file",
                },
                modal: {
                    title: "Chọn danh mục bảng giá từ file excel",
                    title_import_detail: "Chọn danh sách sản phẩm từ file excel",
                    button: "Chọn file dữ liệu",
                    download_file: "Tải về file mẫu",
                    button_action: "Thực hiện",
                    alert_error_validate: "File upload phải đúng định dạng xlsx, xls",
                    alert_error_status: "Báo lỗi và dừng import",
                    update_status: "Thay thế và cập nhật thông tin bảng giá",
                    titleCreate: "Thêm bảng giá",
                    titleUpdate: "Sửa bảng giá",
                    info: "Thông tin",
                    applyArea: "Phạm vi áp dụng",
                    name: "Tên bảng giá",
                    startDate: "Hiệu lực từ ngày",
                    to: "đến",
                    apply: "Áp dụng",
                    notApply: "Chưa áp dụng",
                    allBranch: "Toàn hệ thống",
                    branch: "Chi nhánh",
                    chooseBranch: "Chọn chi nhánh áp dụng",
                    allUser: "Toàn bộ người giao dịch",
                    createdBy: "Người tạo giao dịch",
                    chooseUser: "Chọn người bán áp dụng",
                    allCustomer: "Toàn bộ khách hàng",
                    customerGroup: "Nhóm khách hàng",
                    chooseCustomerGroup: "Chọn nhóm khách hàng áp dụng",
                    guest: "Khách lẻ",
                    chooseCustomer: "Chọn khách hàng áp dụng",
                    price: "Giá bán",
                    chooseProduct: "Chọn hàng hóa trên màn hình bán hàng",
                    chooseProductDifferentPrice: "Cho phép chọn hàng hóa khác bảng giá",
                    chooseProductAndWarning: "Cho phép chọn hàng hóa khác bảng giá kèm cảnh báo",
                    notChooseProductDifferentPrice: "Không cho phép chọn hàng hóa khác bảng giá",
                    confirm: "Xác nhận",
                    contentCancelCreate: "Bạn có muốn hủy thêm mới bảng giá?",
                    contentCancelUpdate: "Bạn có muốn hủy sửa bảng giá?",
                    alertCreateSuccess: "Thêm bảng giá mới thành công",
                    alertUpdateSuccess: "Cập nhật bảng giá thành công",
                    choosePriceList: "--Chọn bảng giá--",
                    contentRemove: "Hệ thống sẽ xóa hoàn toàn bảng giá {name}. Bạn có chắc chắn?",
                    alertRemoveSuccess: "Xóa bảng giá {name} thành công",
                    alertRemoveError: "Xóa bảng giá {name} không thành công",
                    time: "Thời gian hiệu lực"
                },
                details: {
                    code: 'Mã phiếu',
                    time: 'Thời gian',
                    seller: 'Người bán',
                    customer: 'Khách hàng',
                    sum: 'Tổng cộng',
                    revenue: 'Doanh thu',
                    recipientPays: 'Người nhận trả',
                    returnedGuests: 'Đã trả khách',
                }
            },

            // Chi tiết bảng giá
            PriceListDetailIndex: {
                title: "Chi tiết bảng giá",
                priceList: "Bảng giá",
                choosePriceList: "Chọn bảng giá",
                categories: "Nhóm hàng",
                searchCategories: "Tìm kiếm nhóm hàng",
                all: "Tất cả",
                search: "Nhập mã hàng hoặc tên hàng",
                documentTitle: "Thiết lập giá | OMIPOS",
                productCode: "Mã hàng",
                productName: "Tên hàng",
                unitName: "Đơn vị",
                inventories: "Tồn kho",
                lastEntryPrice: "Giá nhập cuối",
                price: "Giá chung",
                comparePrice: "-- Giá so sánh --",
                costPrice: "Giá vốn",
                alertUpdateSuccess: "Cập nhật thành công",
                contentConfirmClone: "Bạn có muốn thêm các sản phẩm còn lại vào bảng giá {name} không?",
                alertCreateSuccess: "Thêm bảng giá thành công",
                alertCreateError: "Thêm bảng giá thất bại",
                salePrice: "Giá bán",

            },

            // Danh mục vị trí
            LocationIndex: {
                title: "Vị trí hàng hóa",
                documentTitle: "Danh mục vị trí | OMIPOS",
                title_location: "Danh sách vị trí",
                title_confirm: "Xác nhận",
                content_confirm: "Bạn chắc chắn muốn xóa danh mục vị trí?",
                document_title: "Danh mục vị trí | OMIPOS",
                button: {
                    filter: "Lọc",
                    create: "Thêm mới",
                    create_location_category: "Thêm danh mục",
                    create_fast_location_category: "Thêm nhanh vị trí",
                    action: "Thao tác",
                    export: "Xuất excel",
                },
                table_location_category: {
                    code: "Mã",
                    name: "Tên",
                    number_location: "Số vị trí",
                    created_at: "Ngày tạo",
                    button: {
                        update: "Sửa",
                        add_location: "Thêm vị trí",
                        remove: "Xóa",
                    }
                },
                table_location: {
                    barcode: "Mã vạch",
                    name: "Tên",
                    code: "Mã",
                    created_at: "Ngày tạo",
                    button: {
                        update: "Sửa",
                        remove: "Xóa"
                    }
                },
                titleCreate: "Thêm danh mục",
                info: "Thông tin",
                branch: "Chi nhánh",
                chooseBranch: "- Chọn chi nhánh -",
                parentCategory: "Danh mục cha",
                categories: "Danh mục",
                categoriesName: "Tên danh mục",
                enterCategoriesName: "Nhập tên danh mục",
                categoriesCode: "Mã danh mục",
                enterCategoriesCode: "Nhập mã danh mục",
                addRow: "Thêm dòng",
                confirmCancelCreate: "Bạn chắc chắn muốn hủy thêm mới danh mục vị trí?",
                maxRow: "Chỉ cho phép thêm tối đa 20 dòng.",
                existCategoriesName: "Danh mục vị trí {name} đã tồn tại",
                existCategoriesCode: "Mã danh mục vị trí {name} đã tồn tại",
                emptyCategoriesName: "Vui lòng nhập tên danh mục vị trí",
                emptyCategoriesCode: "Vui lòng nhập mã danh mục vị trí",
                titleQuickAdd: "Thêm nhanh danh mục vị trí",
                categoriesLevel1: "Danh mục cấp 1",
                examLevel1: "VD: Tầng 1",
                categoriesLevel2: "Danh mục cấp 2",
                examLevel2: "VD: Khu [1-3]",
                categoriesLevel3: "Danh mục cấp 3",
                examLevel3: "VD: Kệ [1-3]",
                categoriesLevel4: "Danh mục cấp 4",
                examLevel4: "VD: Tầng [1-3]",
                locations: "Các vị trí",
                examLocations: "VD: Ô [1-4], Ô tài liệu",
                confirmCancelQuickAdd: "Bạn chắc chắn muốn hủy thêm nhanh danh mục vị trí?",
                emptyCategoriesLevel1: "Vui lòng nhập tên danh mục cấp 1",
                titleCreateLocation: "Thêm mới vị trí trong kho",
                addInfo: "Thêm thông tin",
                note: "Lưu ý",
                contentNote: "Mã vạch vị trí được tổ hợp [Mã danh mục 1] - [Mã danh mục 2] .... - [Mã vị trí]",
                addLocationName: "Thêm tên vị trí",
                addLocationCode: "Thêm mã vị trí",
                addBarcode: "Thêm mã vạch",
                addMaxQuantity: "Thêm số lượng tối đa",
                enterLocationName: "Nhập tên vị trí",
                enterLocationCode: "Nhập mã vị trí",
                enterBarcode: "Nhập mã vạch",
                enterMaxQuantity: "Số lượng SP tối đa",
                locationInFolder: "Các vị trí trong khu vực",
                barcode: "Mã vạch",
                name: "Tên",
                code: "Mã",
                totalProducts: "Số mặt hàng",
                totalQuantity: "Số lượng SP",
                maxTotalQuantity: "SL SP có thể chứa",
                confirmCancelCreateLocation: "Bạn chắc chắn muốn hủy thêm danh sách vị trí?",
                existLocationName: "Tên vị trí đã tồn tại",
                duplicateLocationName: "Tên vị trí bị trùng",
                existLocationCode: "Mã vị trí sản phẩm đã tồn tại",
                duplicateLocationCode: "Mã vị trí sản phẩm bị trùng",
                row: "Dòng",
                emptyLocationName: "Vui lòng nhập tên vị trí",
                emptyLocationCode: "Vui lòng nhập mã vị trí sản phẩm",
                emptyLocationBarCode: "Vui lòng nhập mã vạch",
                emptyData: "Vui lòng nhập dữ liệu",
                titleChooseParentCate: "Chọn danh mục cha",
                titleUpdateLocation: "Sửa vị trí",
                total: "Tổng",
                confirmCancelUpdateLocation: "Bạn chắc chắn muốn hủy cập nhật thông tin vị trí?",
                alertUpdateSuccess: "Cập nhật vị trí thành công",
                alertUpdateError: "Cập nhật vị trí không thành công",
                confirmRemoveLocation: "Bạn chắc chắn muốn xóa vị trí?",
                noteRemoveLocation: "Lưu ý: Bạn không thể xóa những vị trí đã chứa sản phẩm",
                alertRemoveSuccess: "Xóa vị trí thành công",
                alertRemoveError: "Vị trí này đang chứa vị trí có sản phẩm",
                titleUpdateLocationCategories: "Sửa danh mục vị trí",
                cancelUpdateLocationCategories: "Bạn chắc chắn muốn hủy cập nhật danh mục vị trí?"
            },

        /* Hết danh mục */
        
        /* Giao dịch */
            // Dự trù chi tiết
            PlanIndex: {
                title: "Dự trù chi tiết",
                record: "Số bản ghi",
                branch: "Chi nhánh",
                time: "Thời gian",
                createdBy: "Người tạo",
                status: "Trạng thái",
                plansAdd: "Dự trù mới",
                total_products: "Tổng số mã hàng dự trù",
                total_quantity_products: "Tổng số sản phẩm dự trù",
                chooseBranch: "Chọn chi nhánh",
                chooseCreatedBy: "Chọn người tạo",
                search: "Theo mã, tên báo cáo dự trù",
                documentTitle: "Dự trù chi tiết | OMIPOS",
                note: "Ghi chú",
                autoCreateCode: "Mã báo cáo tự động",
                name: "Tên báo cáo",
                chooseUnit: "Chọn đơn vị",
                detail: {
                    code: "Mã dự trù",
                    name: "Tên dự trù",
                    branch: "Chi nhánh dự trù",
                    time: "Thời gian để tính lượng bán trung bình/ngày",
                    estimate: "Số ngày bán dự kiến",
                    status: "Trạng thái",
                    createdBy: "Người tạo",
                    description: "Ghi chú",
                    plan_purchase: "Dự trù nhập mua",
                },
                button: {
                    save: "Lưu",
                    open: "Mở phiếu",
                    process: "Xử lý phiếu",
                    print: "In",
                    export: "Xuất file",
                    delete: "Xóa",
                    cancel: "Hủy bỏ",
                    create: "Tạo dự trù nhập mua",
                    exportOverview: "Xuất file tổng quan",
                    exportDetail: "Xuất file chi tiết",
                    fileOverview: "File tổng quan",
                    fileDetails: "File chi tiết",
                    new: "Dự trù mới"
                },
                modal: {
                    title: "Xác nhận",
                    content: "Bạn có chắc chắn muốn hủy phiếu dự trù",
                    content_multiple: "Hệ thống chỉ cho phép hủy những phiếu ở trạng thái Phiếu tạm và chưa thuộc bảng dự trù nhập mua nào. Bạn có chắc chắn hủy phiếu dự trù chi tiết không?",
                    content_delete_multiple: "Hệ thống chỉ cho phép xóa những phiếu ở trạng thái Phiếu tạm và chưa thuộc bảng dự trù nhập mua nào. Bạn có chắc chắn xóa phiếu dự trù chi tiết không?",
                    content_delete: "Bạn có chắc chắn muốn xóa phiếu dự trù",
                    nextStep: "Bỏ qua",
                    confirm:  "Đồng ý",
                    success: "Hủy bỏ phiếu dự trù thành công",
                    success_delete: "Xóa phiếu dự trù thành công"
                },
                alerts: {
                    update_error: "Cập nhật phiếu dự trù thất bại",
                    update_success: "Cập nhật dữ liệu thành công",
                }
            },

            // Dự trù nhập mua
            PlanPurchaseIndex: {
                title: "Dự trù nhập mua",
                record: "Số bản ghi",
                filter_time: "Thời gian",
                filter_user: "Người tạo",
                total_products: "Tổng số mã hàng dự trù",
                total_quantity_products: "Tổng số sản phẩm dự trù",
                total_supplier: "Tổng số nhà cung cấp",
                chooseBranch: "Chọn chi nhánh",
                chooseCreatedBy: "Chọn người tạo",
                search: "Theo mã, tên báo cáo dự trù",
                documentTitle: "Dự trù hàng mua | OMIPOS",
                detail: {
                    branchCreate: "Chi nhánh tạo dự trù",
                    branch: "Chi nhánh được dự trù",
                    plan: "Dự trù chi tiết",
                },
                modal: {
                    content_cancel: "Bạn có chắc chắn hủy phiếu dự trù nhập mua",
                    content_delete: "Hệ thống chỉ cho phép xóa những phiếu ở trạng thái Phiếu tạm. Bạn có chắc chắn xóa phiếu dự trù nhập mua",
                    content_cancel_multiple: "Bạn có chắc chắn hủy nhiều phiếu dự trù nhập mua không?",
                    content_delete_multiple: "Hệ thống chỉ cho phép xóa những phiếu ở trạng thái Phiếu tạm. Bạn có chắc chắn xóa nhiều dự trù nhập mua không?"
                },
                alerts: {
                    error: "Phiếu không được để trống",
                    create_success: "Tạo phiếu dự trù nhập mua thành công",
                    create_error: "Tạo phiếu dự trù nhập mua thất bại",
                    update_success: "Cập nhật phiếu dự trù nhập mua thành công",
                    update_error: "Cập nhật phiếu dự trù nhập mua thất bại",
                    create_order: 'Tạo phiếu đặt hàng nhập thành công'
                },
                button: {
                    process: "Xử lý phiếu",
                    create_po: "Tạo ĐHN",
                }
            },
            planCreateUpdate: {
                title: "Dự trù chi tiết",
                title_plan_purchase: "Dự trù nhập mua",
                table: {
                    stt: "STT",
                    code: "Mã hàng",
                    name: "Tên hàng",
                    unit: "ĐVT",
                    min_inventory: "SL tồn min",
                    max_inventory: "SL tồn max",
                    estimate_number: "SL dự báo biến động",
                    avg_number_of_sale: "SL trung bình bán 1 ngày",
                    sys_estimate_number: "SL hệ thống dự báo bán",
                    available_inventory: "Tồn có thể bán",
                    total_quantity_plan: "Dự trù đặt",
                    total_quantity_order: "Tổng dự trù đặt",
                    total_quantity_real_order: "Số lượng thực đặt",
                    price: "Đơn giá",
                    supplier: "Nhà cung cấp",
                },
                created_by: "Người tạo",
                plan_code: "Mã dự trù",
                plan_name: "Tên dự trù",
                plan_branch: "Chi nhánh dự trù",
                plan_time: "Thời gian để tính lượng bán trung bình/ngày",
                to: "đến",
                plan_estimate: "Số ngày bán dự kiến",
                plan_status: "Trạng thái",
                note: "Ghi chú...",
                total_product: "Tổng số mã hàng dự trù",
                total_quantity_product: "Tổng số sản phẩm dự trù",
                total_supplier: "Tổng nhà cung cấp",
                button: {
                    success: "Hoàn thành",
                    draft: "Lưu tạm",
                    create_order: "Tạo phiếu đặt hàng nhập",
                },
                status_draft: "Phiếu tạm",
                alerts: {
                    error: "Phiếu không được để trống",
                    create_success: "Tạo phiếu dự trù chi tiết thành công",
                    update_success: "Cập nhật phiếu dự trù chi tiết thành công",
                }
            },

            // Đặt hàng nhập
            OrderSupplierIndex: {
                title: "Phiếu đặt hàng nhập",
                document_title: "Đặt hàng nhập | OmiPOS",
                filter: {
                    branch: 'Chi nhánh',
                    time: 'Thời gian',
                    status: 'Trạng thái',
                    status_payment: 'Trạng thái thanh toán',
                    created_by: 'Người tạo',
                    order_by: 'Người nhận đặt',
                    placeholder: {
                        branch: 'Chọn chi nhánh',
                        created_by: 'Chọn người tạo',
                        order_by: 'Chọn người nhận đặt',
                        search: 'Theo mã phiếu đặt hàng nhập',
                    }
                },
                code: "Mã đặt hàng nhập",
                time: "Thời gian",
                supplier: "Nhà cung cấp",
                customer: "Khách hàng",
                price_list: "Bảng giá",
                purchase_code: "Mã nhập hàng",
                status: "Trang thái",
                status_complete: "Hoàn thành",
                status_cancel: "Đã hủy",
                status_purchase_a_part: "Đã nhập một phần",
                status_draft: "Phiếu tạm",
                statusConfirmed: "Đã xác nhận NCC",
                branch: "Chi nhánh",
                employee: "Nhân viên KD",
                created_by: "Người tạo",
                order_by: "Người đặt",
                sale_channel: "Kênh bán",
                note: "Ghi chú...",
                method: "Phương thức thanh toán",
                total_quantity: "Tổng số lượng",
                total_products: "Tổng số mặt hàng",
                total_origin: "Tổng tiền hàng",
                discount: "Giảm giá",
                total: "Tổng cộng",
                payment_supplier: "Cần trả NCC",
                total_payment: "Tiền đã trả NCC",
                expected_date: "Dự kiến nhập hàng",
                tabs:{
                    info: "Thông tin",
                    history_purchase: "Lịch sử nhập hàng",
                    history_payment: "Lịch sử thanh toán",
                    history_delivery: "Lịch sử giao hàng",
                    table:{
                        history_payment: {
                            code: "Mã phiếu",
                            time: "Thời gian",
                            created_by: "Người tạo",
                            method: "Phương thức",
                            status: "Trạng thái",
                            total_price: "Tiền chi",
                        },
                        history_purchase: {
                            code: "Mã phiếu",
                            time: "Thời gian",
                            create_by: "Người tạo",
                            status: "Trạng thái",
                        }
                    }
                },
                button: {
                    add: "Đặt hàng nhập",
                    action: "Thao tác",
                    export: "Xuất file",
                    export_all: "Xuất file danh sách tổng quan",
                    export_detail: "Xuất file danh sách chi tiết",
                    import: "Import File",
                    file: "File",
                    file_all: "File tổng quan",
                    file_detail: "File chi tiết",
                    cancel: "Hủy bỏ",
                    print: "In phiếu",
                    print_barcode: "In tem mã",
                    show: "Mở phiếu",
                    save: "Lưu",
                    update: "Cập nhật",
                    create_purchase: "Tạo phiếu nhập",
                    copy: "Sao chép",
                    import_invoice_supplier: "Nhận hóa đơn NCC",
                    process_ticket: "Xử lý phiếu",
                    close: "Kết thúc",
                },
                titleCreate: "Phiếu đặt hàng nhập",
                paymentForSupplier: "Đã trả nhà cung cấp",
                needToPaySupplier: "Cần trả nhà cung cấp",
                paidSupplier: "Tiền trả nhà cung cấp",
                supplierReturn: "Nhà cung cấp trả lại",
                estimatedImportDate: "Dự kiến ngày nhập hàng",
                emptyOrder: "Phiếu hàng đang trống",
                invalidETImportDate: "Ngày nhập dự kiến phải lớn hơn ngày tạo",
                alertCreateSuccess: "Phiếu đặt hàng nhập được tạo thành công",
                alertUpdateSuccess: "Phiếu đặt hàng nhập được cập nhật thành công",
            },
            DetailSupplierCard: {
                title: "Phiếu đặt hàng nhập",
                orderCode: "Mã đặt hàng nhập",
                orderDate: "Thời gian",
                supllierName: "Nhà cung cấp",
                createdBy: "Người tạo",
                statusName: "Trạng thái",
                branchName: "Chi nhánh",
                orderBy: "Người đặt",
                expectedDateOfImport: "Dự kiến ngày nhập",
                note: "Ghi chú",
                prodCode: "Mã hàng",
                prodName: "Tên hàng",
                prodQuantity: "Số lượng",
                prodPrice: "Đơn giá",
                prodDiscount: "Giảm giá",
                prodImportPrice: "Giá nhập",
                totalPrice: "Thành tiền",
                totalQuantity: "Tổng số lượng",
                totalProduct: "Tổng số mặt hàng",
                total: "Tổng tiền hàng",
                discount: "Giảm giá",
                sumTotal:"Tổng cộng",
                paymentForSupplier: "Tiền đã trả NCC"
            },
            ReportPayToDebt: {
                placeholder: {
                    order_import: "Nhập mã đặt hàng nhập",
                    import: "Nhập mã nhập hàng",
                    supplier: "Theo tên, mã nhà cung cấp"
                },
                title: {
                    supplier: "Nhà cung cấp",
                    order_import: "Số phiếu đặt hàng nhập",
                    import: "Nhập mã nhập hàng"
                }
            },

            // Nhập hàng
            PurchaseOrderIndex: {
                title: "Phiếu nhập hàng",
                document_title: "Nhập hàng | OmiPos",
                filter: {
                    branch: 'Chi nhánh',
                    time: 'Thời gian',
                    status: 'Trạng thái',
                    status_invoice: 'Trạng thái hóa đơn',
                    created_by: 'Người tạo',
                    import_by: 'Người nhập',
                    priceList: 'Bảng giá',
                    placeholder: {
                        branch: 'Chọn chi nhánh',
                        created_by: 'Chọn người tạo',
                        import_by: 'Chọn người nhập',
                        priceList: 'Chọn bảng giá',
                        search: 'Theo mã nhập hàng',
                        sold_by: 'Chọn người bán',
                    }
                },
                code: "Mã nhập hàng",
                time: "Thời gian",
                supplier: "Nhà cung cấp",
                customer: "Khách hàng",
                price_list: "Bảng giá",
                order_code: "Mã đặt hàng nhập",
                status: "Trang thái",
                statusPurchased: "Đã nhập hàng",
                branch: "Chi nhánh",
                employee: "Nhân viên KD",
                created_by: "Người tạo",
                import_by: "Người nhập",
                sale_channel: "Kênh bán",
                note: "Ghi chú...",
                method: "Phương thức thanh toán",
                total_quantity: "Tổng số lượng",
                total_products: "Tổng số mặt hàng",
                total_origin: "Tổng tiền hàng",
                discount: "Giảm giá",
                total: "Tổng cộng",
                total_payment: "Tiền đã trả NCC",
                tabs:{
                    info: "Thông tin",
                    history_return: "Lịch sử trả hàng",
                    history_payment: "Lịch sử thanh toán",
                    table:{
                        history_payment: {
                            code: "Mã phiếu",
                            time: "Thời gian",
                            created_by: "Người tạo",
                            method: "Phương thức",
                            status: "Trạng thái",
                            total_price: "Tiền chi",
                        },
                        history_return: {
                            code: "Mã trả hàng nhập",
                            time: "Thời gian",
                            receive_by: "Người trả",
                            total_price: "Tổng cộng",
                            status: "Trạng thái",
                        }
                    }
                },
                button: {
                    add: "Nhập hàng",
                    action: "Thao tác",
                    export: "Xuất file",
                    export_all: "Xuất file danh sách tổng quan",
                    export_detail: "Xuất file danh sách chi tiết",
                    import: "Import File",
                    file: "File",
                    file_all: "File tổng quan",
                    file_detail: "File chi tiết",
                    cancel: "Hủy bỏ",
                    print: "In phiếu",
                    print_barcode: "In tem mã",
                    show: "Mở phiếu",
                    save: "Lưu",
                    update: "Cập nhật",
                    return: "Trả hàng nhập",
                    copy: "Sao chép",
                    import_invoice_supplier: "Nhận hóa đơn NCC",
                    process_ticket: "Xử lý phiếu",
                    sendZalo: "Gửi zalo"
                },
            },

            // Trả hàng nhập (0)
            ReturnPurchaseOrder: {
                title: "Phiếu trả hàng nhập",
                selectReturnBy: "Chọn người trả",
                totalAmountReturn: "Tổng tiền hàng trả",
                supplierNeedToPay: "NCC cần trả",
                supplierPaid: "NCC đã trả",
                returnPrice: "Giá trả lại",
                returnDiscount: "Giảm giá trả lại",
                updateSuccess: "Phiếu trả hàng nhập {code} được cập nhật thành công",
                includedInDebt: "Tính vào công nợ",
                advanceRefund: "Hoàn lại tạm ứng",
                amountSupplierPaid: "Tiền nhà cung cấp trả",
                amountReturnSupplier: "Tiền trả lại nhà cung cấp"
            },

            // Tạm ứng quyết toán
            SettlementAdvanceIndex: {
                title: "Tạm ứng quyết toán",
                document_title: "Đề nghị tạm ứng | OmiPos",
                branch: "Chi nhánh",
                time: "Thời gian",
                created_by: "Người tạo",
                status: "Trạng thái",
                code: "Mã TUQT",
                created_at: "Ngày tạo",
                note: "Ghi chú",
                po_code: "Mã PO",
                po_status: "Trạng thái PO",
                payment_date: "Lịch TT theo hợp đồng",
                supplier_code: "Mã NCC",
                supplier_name: "Tên NCC",
                payment_method: "Hình thức thanh toán",
                payment_info: "Thông tin thanh toán",
                cash: "Số tiền",
                advance_info: "Thông tin quyết toán",
                account_number: "Số tài khoản",
                account_owner: "Chủ tài khoản",
                bank_address: "Ngân hàng + Chi nhánh",
                settlement: "Tạm ứng",
                advances: "Quyết toán",
                compare: "Chênh lệch",
                import_number: "Số phiếu nhập",
                invoice_number: "Số hóa đơn",
                invoice_info: "Thông tin hóa đơn",
                invoice_cash: "Số tiền trên HĐ",
                invoice_status: "Trạng thái hóa đơn",
                placeholder: {
                    branch: "Chọn chi nhánh",
                    created_by: "Chọn người tạo",
                    search: "Theo mã phiếu đề nghị tạm ứng"
                },
                button: {
                    add: "Thêm mới",
                    export: "Xuất file",
                    print: "In",
                    save: "Lưu",
                    update: "Cập nhật",
                    cancel: "Hủy bỏ"
                },
                tabs: {
                    info: "Thông tin"
                },
                alertUpdateSuccess: "Phiếu tạm ứng quyết toán {code} được cập nhật thành công",
                alertUpdateError: "Phiếu tạm ứng quyết toán {code} cập nhật không thành công",
                alertCancelSuccess: "Hủy phiếu thành công",
                confirmCancel: "Bạn có chắc chắn muốn hủy phiếu tạm ứng {code} không ?",
            },

            // BAS
            DiscountsBAS: {
                title: "Các khoản chiết khấu sau BAS",
                document_title: "Các khoản chiết khấu sau BAS | OmiPos",
                branch: "Chi nhánh",
                time: "Thời gian tạo",
                created_by: "Người tạo",
                status: "Trạng thái",
                code: "Mã phiếu",
                supplierCode: "Mã NCC",
                supplierName: "Tên NCC",
                created_at: "Thời gian tạo",
                updated_at: "Thời gian cập nhật",
                note: "Ghi chú",
                totalPrice: "Tổng số tiền",
                button: {
                    add: "Thêm mới",
                    export: "Xuất file",
                    file_all: "File tổng quan",
                    file_detail: "File chi tiết",
                    export_all: "Xuất file danh sách tổng quan",
                    export_detail: "Xuất file danh sách chi tiết",
                    print: "In",
                    save: "Lưu",
                    update: "Cập nhật",
                    open: "Mở phiếu",
                    cancel: "Hủy bỏ",
                    delete: "Xóa",
                    success: "Hoàn thành",
                    draft: "Lưu tạm",
                    action: "Thao tác",
                },
                alerts: {
                    error: "Vui lòng chọn nhà cung cấp",
                    errorMount: "Vui lòng nhập số tiền chiết khấu",
                    create_success: "Tạo phiếu dự trù nhập mua thành công",
                    update_discount_bas_error:'Cập nhật phiếu BAS thất bại',
                    update_discount_bas_success: "Cập nhật phiếu BAS thành công",
                    create_discount_bas_error: 'Thêm mới phiếu BAS không thành công',
                    create_discount_bas_success: 'Thêm mới phiếu BAS thành công'
                    
                },
                tabs: {
                    info: "Thông tin"
                },
                table: {
                    STT: "STT",
                    supplierId: "Mã NCC",
                    supplierName: "Tên nhà cung cấp",
                    content: "Nội dung",
                    amount: "Số tiền",                    
                    deductionLimit: "Hạn cần trừ",
                },
                placeholder: {
                    search: "Theo mã phiếu BAS",
                    created_by: "Chọn người tạo",
                    content: "Nhập nội dung", 
                    deductionLimit: "Nhập hạn cần trừ",
                    branch: "Chọn chi nhánh"
                }
            },

            // Đặt hàng bán
            OrderIndex: {
                title: "Phiếu đặt hàng",
                document_title: "Đặt hàng bán | OmiPos",
                filter: {
                    branch: 'Chi nhánh',
                    time: 'Thời gian',
                    status: 'Trạng thái',
                    created_by: 'Người tạo',
                    ordered_by: 'Người nhận đặt',
                    saleChannel: 'Kênh bán',
                    placeholder: {
                        branch: 'Chọn chi nhánh',
                        created_by: 'Chọn người tạo',
                        ordered_by: 'Chọn người nhận đặt',
                        saleChannel: 'Chọn kênh bán',
                        search: 'Theo mã phiếu đặt hàng',
                    }
                },
                code: "Mã đặt hàng",
                time: "Thời gian",
                customer: "Khách hàng",
                price_list: "Bảng giá",
                expected_date_of_transfer: "Ngày giao dự kiến",
                invoice_code: "Mã hóa đơn",
                status: "Trang thái",
                branch: "Chi nhánh",
                created_by: 'Người tạo',
                ordered_by: 'Người nhận đặt',
                status_on_ecommerce: "Trạng thái trên sàn",
                sale_channel: "Kênh bán",
                note: "Ghi chú",
                total_quantity: "Tổng số lượng",
                total_origin: "Tổng tiền hàng",
                discount: "Giảm giá phiếu đặt",
                surcharge: "Thu khác",
                total: "Tổng cộng",
                total_payment: "Khách đã trả",
                transaction_fee: "Phí giao dịch",
                shipping_fee_collected_by_customer: "Phí ship thực thu KH",
                fees_paid_to_delivery_partners: "Phí trả đối tác giao hàng",
                tabs:{
                    info: "Thông tin",
                    history_invoice: "Lịch sử hóa đơn",
                    history_payment: "Lịch sử thanh toán",
                    table:{
                        history_payment: {
                            code: "Mã phiếu",
                            time: "Thời gian",
                            created_by: "Người tạo",
                            method: "Phương thức",
                            status: "Trạng thái",
                            total_price: "Tiền thu",
                        },
                        history_invoice: {
                            code: "Mã hóa đơn",
                            time: "Thời gian",
                            created_by: "Người tạo",
                            value: "Giá trị",
                            status: "Trạng thái",
                        },
                    },
                },
                button: {
                    order: "Đặt hàng",
                    export: "Xuất file",
                    file_all: "File tổng quan",
                    file_detail: "File chi tiết",
                    end: "Kết thúc",
                    save: "Lưu",
                    process_order: "Xử lý đơn hàng",
                    print: "In",
                    copy: "Sao chép",
                    cancel: "Hủy bỏ",
                },
            },
            modalEndOrder: {
                title: "Kết thúc phiếu đặt hàng",
                content: "Bạn sẽ không thể xử lý đơn hàng mặc dù hàng hóa chưa giao hết.Bạn có chắc chắn kết thúc và chuyển trạng thái đơn hàng sang hoàn thành không?",
                messageSuccess: "Kết thúc phiếu đặt hàng thành công.",
                messageError: "Kết thúc phiếu đặt hàng không thành công"
            },
            ModalCreateInvoiceMultiple:{
                status_draft: "Phiếu tạm",
                status_confirm: "Đã xác nhận",
                content: "Hệ thống chỉ xử lý những phiếu đặt hàng bán ở trạng thái",
                add_success: "Số phiếu thêm thành công:",
                add_errors: "Số phiếu thêm không thành công:",
                continue: "Tiếp tục",
                inogre: "Bỏ qua",
            },
            DetailOrdersCard: {
                title: "Phiếu đặt hàng",
                orderCode: "Mã đặt hàng",
                orderDate: "Thời gian",
                customerName: "Khách hàng",
                priceLists: "Bảng giá",
                createdBy: "Người tạo",
                statusName: "Trạng thái",
                branchName: "Chi nhánh xử lý",
                branchNameCreate: "Chi nhánh tạo",
                orderBy: "Người nhận đặt",                
                expectedDateOfImport: "Ngày giao dự kiến",
                note: "Ghi chú",
                prodCode: "Mã hàng",
                prodName: "Tên hàng",
                prodQuantity: "Số lượng",
                prodUnit: "Đơn vị",
                prodPrice: "Đơn giá",
                prodDiscount: "Giảm giá",
                prodImportPrice: "Giá nhập",
                totalPrice: "Thành tiền",
                totalQuantity: "Tổng số lượng",
                totalProduct: "Tổng số mặt hàng",
                total: "Tổng tiền hàng",
                discount: "Giảm giá phiếu",
                sumTotal:"Tổng cộng",
                paymentForSupplier: "Khách đã trả",
                SaleChannelName: "Kênh bán",
                notFound: "Không tìm thấy kết quả nào phù hợp",
                btnOpen: "Mở phiếu",
                promotion: "Khuyến mại",
                codeDiscount: "Mã giảm giá",
                attribute: "Thuộc tính",
                searchProductCode: "Tìm mã hàng",
                searchProductName: "Tìm kiếm tên hàng",
                dvt: "ĐVT",
                chooseUnit: "Chọn đơn vị",
                chooseAttribute: "Chọn thuộc tính",
            },

            // Hóa đơn
            InvoiceIndex: {
                title: "Hóa đơn",
                document_title: "Hóa đơn | OmiPos",
                filter: {
                    branch: 'Chi nhánh',
                    time: 'Thời gian',
                    status: 'Trạng thái',
                    saleChannel: 'Kênh bán',
                    method: 'Phương thức',
                    priceList: 'Bảng giá',
                    placeholder: {
                        branch: 'Chọn chi nhánh',
                        saleChannel: 'Chọn kênh bán',
                        method: 'Chọn phương thức thanh toán',
                        priceList: 'Chọn bảng giá',
                        search: 'Theo mã hóa đơn',
                        sold_by: 'Chọn người bán',
                    }
                },
                code: "Mã hóa đơn",
                time: "Thời gian",
                customer: "Khách hàng",
                price_list: "Bảng giá",
                order_code: "Mã đặt hàng",
                status: "Trang thái",
                branch: "Chi nhánh",
                employee: "Nhân viên KD",
                created_by: "Người tạo",
                sale_channel: "Kênh bán",
                note: "Ghi chú",
                method: "Phương thức thanh toán",
                total_quantity: "Tổng số lượng",
                total_origin: "Tổng tiền hàng",
                discount: "Giảm giá hóa đơn",
                surcharge: "Thu khác",
                total: "Khách cần trả",
                total_payment: "Khách đã trả",
                shipping_fee_collected_by_customer: "Phí ship thực thu KH",
                fees_paid_to_delivery_partners: "Phí trả đối tác giao hàng",
                tabs:{
                    info: "Thông tin",
                    history_return: "Lịch sử trả hàng",
                    history_payment: "Lịch sử thanh toán",
                    history_delivery: "Lịch sử giao hàng",
                    detail_delivery: "Chi tiết giao hàng",
                    table:{
                        history_payment: {
                            code: "Mã phiếu",
                            time: "Thời gian",
                            created_by: "Người tạo",
                            method: "Phương thức",
                            status: "Trạng thái",
                            total_price: "Tiền thu/chi",
                            value: "Giá trị phiếu",
                            type_payment: "Loại thu chi"
                        },
                        history_return: {
                            code: "Mã trả hàng",
                            time: "Thời gian",
                            receive_by: "Người nhận trả",
                            total_price: "Tổng cộng",
                            status: "Trạng thái",
                        }
                    },
                    delivery: {
                        title: "Thông tin giao hàng",
                        address_receive: "Địa chỉ lấy hàng",
                        receive_by: "Người nhận",
                        paid_by: "Người gửi",
                        contact_number: "Điện thoại",
                        address: "Địa chỉ",
                        area: "Khu vực",
                        ward: "Phường xã",
                        note: "Ghi chú giao",
                        delivery_code: "Mã vận đơn",
                        weight: "Trọng lượng",
                        size: "Kích thước",
                        price_declaration: "Khai giá",
                        exchange: "Mang ra quy đổi",
                        conversion_exchange: "Tỉ lệ quy đổi",
                        take_to_office: "Mang ra bưu cục",
                        pay_fee_by: "Bên trả phí",
                        service: "Dịch vụ",
                        delivery_by: "Người giao",
                        using_price_cod: "Thu hộ tiền (COD)",
                        using_price_cod_still: "Còn cần thu (COD)",
                        price: "Phí trả ĐTGH",
                        price_still: "Còn cần trả ĐTGH",
                        delivery_time: "Thời gian giao hàng",
                        delivery_status: "Trạng thái giao",
                        note_cancel: 'Lý do hủy',
                        note_delivery: "Lưu ý",
                        discount_code: "Mã giảm giá",
                        estimate_delivery: "Dự kiến giao",
                        control_code: "Mã đối soát",
                        total_fee: "Tổng cước phí",
                        total_receive: "Tổng thu người nhận",
                        titleCreate: 'Tạo vận đơn khác',
                        paid_by_receiver_value: "Người nhận trả phí",
                    }
                },
                button: {
                    invoice: "Hóa đơn",
                    add: "Thêm mới",
                    action: "Thao tác",
                    export: "Xuất file",
                    export_all: "Xuất file danh sách tổng quan",
                    export_detail: "Xuất file danh sách chi tiết",
                    import: "Import File",
                    file: "File",
                    file_all: "File tổng quan",
                    file_detail: "File chi tiết",
                    cancel: "Hủy bỏ",
                    print: "In phiếu",
                    save: "Lưu",
                    update: "Cập nhật",
                    refund: "Đề nghị hoàn hàng",
                    return: "Trả hàng",
                    copy: "Sao chép",
                    print_delivery: "In phiếu giao hàng",
                    cancel_delivery: "Hủy vận đơn",
                    create_delivery: "Tạo vận đơn",
                    payment_with_transporter: "Thanh toán với đối tác giao hàng",
                },
            },

            // Trả hàng bán (0)
            ReturnIndex: {
                selectReturnBy: "Chọn người nhận trả",
                returnTotal: "Tổng tiền hàng trả",
                returnDiscount: "Giảm giá phiếu trả",
                returnFee: "Phí trả hàng",
                returnCustomer: "Cần trả khách",
                totalPayment: "Đã trả khách",
                reImportPrice: "Giá nhập lại",
                alertUpdateSuccess: "Phiếu trả hàng {code} được cập nhật thành công",
                alertUpdateError: "Phiếu trả hàng {code} cập nhật không thành công",
            },

            // Đề nghị hoàn hàng (0)

            // Chuyển hàng đi
            TransferIndex: {
                title: "Chuyển kho",
                record: "Số bản ghi",
                filter_time: "Thời gian",
                transfer_date: "Ngày chuyển",
                receive_date: "Ngày nhận",
                filter_user: "Người tạo",
                type: "Loại phiếu",
                type_transfer: "Chuyển đi",
                type_receive: "Nhập về",
                from_branch: "Từ chi nhánh",
                to_branch: "Tới chi nhánh",
                status: "Trạng thái",
                status_receive: "Tình trạng nhận hàng",
                placeholder: {
                    search: "Theo mã phiếu chuyển"
                },
                button: {
                    action: "Thao tác",
                    export_all: "Xuất file danh sách tổng quan",
                    export_detail: "Xuất file danh sách chi tiết",
                    create: "Chuyển kho",
                    export: "Xuất file",
                    file_all: "File tổng quan",
                    file_detail: "File chi tiết",
                    save: "Lưu",
                    open: "Mở phiếu",
                    process: "Xử lý phiếu",
                    print: "In",
                    print_barcode: "In tem mã",
                    copy: "Sao chép",
                    cancel: "Hủy bỏ"
                },
                tabs: {
                    info: "Thông tin",
                    detail_receive: "Chi tiết nhận hàng"
                },
                transfer_code: "Mã chuyển kho",
                request_code: "Mã đặt hàng chuyển",
                from_branch_note: "Ghi chú chi nhánh chuyển...",
                to_branch_note: "Ghi chú chi nhánh nhận...",
                total_products: "Tổng số mặt hàng",
                total_send_quantity: "Tổng SL chuyển",
                total_send_price: "Tổng giá trị chuyển",
                total_receive_quantity: "Tổng SL nhận",
                total_receive_price: "Tổng giá trị nhận",
            },

            // Gọi hàng về
            RequestIndex: {
                title: "Phiếu đặt hàng chuyển",
                record: "Số bản ghi",
                filter_time: "Thời gian",
                request_date: "Ngày gọi",
                receive_date: "Ngày nhận",
                filter_user: "Người tạo",
                type: "Loại phiếu",
                type_transfer: "Chuyển đi",
                type_receive: "Nhập về",
                status: "Trạng thái",
                status_confirm: "Tình trạng đã xác nhận",
                placeholder: {
                    search: "Theo mã phiếu gọi",
                    from_branch: "Từ chi nhánh",
                    to_branch: "Tới chi nhánh",
                },
                button: {
                    action: "Thao tác",
                    export_all: "Xuất file danh sách tổng quan",
                    export_detail: "Xuất file danh sách chi tiết",
                    create: "Gọi hàng",
                    export: "Xuất file",
                    file_all: "File tổng quan",
                    file_detail: "File chi tiết",
                    save: "Lưu",
                    open: "Mở phiếu",
                    process: "Xử lý phiếu",
                    print: "In",
                    print_barcode: "In tem mã",
                    copy: "Sao chép",
                    cancel: "Hủy bỏ"
                },
                tabs: {
                    info: "Thông tin",
                    detail: "Chi tiết phiếu gọi"
                },
                request_code: "Mã đặt hàng chuyển",
                from_branch: "CN tạo phiếu gọi",
                to_branch: "Chi nhánh được gọi",
                transfer_code: "Phiếu chuyển kho",
                from_branch_note: "Ghi chú chi nhánh gọi...",
                to_branch_note: "Ghi chú chi nhánh được gọi...",
                total_products: "Tổng số mặt hàng",
                total_request_quantity: "Tổng SL gọi",
                total_request_price: "Tổng giá trị gọi",
                total_transfer_quantity: "Tổng SL cho:",
                total_confirm_quantity: "Tổng SL đã xác nhận:",
                total_transfer_price: "Tổng giá trị cho:",
                total_confirm_price: "Tổng giá trị đã xác nhận:",
                sendQuantity: "Số lượng cho",
                sendAmount: "Thành tiền cho",
                requestPriceOrSendPrice: "Giá gọi/cho",
            },

            // Xếp sản phẩm vào vị trí
            ImportPositionIndex: {
                title: "Xếp sản phẩm vào vị trí",
                title_confirm: "Xác nhận",
                content_confirm: "Bạn có chắc chắn muốn hủy phiếu nhập kho {code} không ?",
                document_title: "Xếp sản phẩm vào vị trí | OmiPos",
                time: "Thời gian",
                status: "Trạng thái",
                code: "Mã phiếu nhập VT",
                created_at: "Thời gian tạo",
                created_by: "Người tạo",
                original_document: "Chứng từ gốc",
                note: "Ghi chú nhập vị trí hàng hóa",
                totalProducts: "Tổng số lượng",
                totalTypeProducts: "Tổng số mặt hàng",
                status_draft: "Phiếu tạm",
                status_confirm: "Đã xác nhận",
                placeholder: {
                    search: "Theo mã phiếu nhập vị trí",
                },
                tabs: {
                    info: "Thông tin",
                },
                button: {
                    save: "Lưu",
                    process: "Xử lý phiếu",
                    update: "Cập nhật",
                    print: "In",
                    cancel: "Hủy bỏ",
                },
                table: {
                    code: "Mã hàng",
                    name: "Tên hàng",
                    unit: "Đơn vị tính",
                    attribute:"Thuộc tính",
                    quantity: "Số lượng",
                    import_position: "Vị trí nhập"
                },
                alerts: {
                    validate_note: "Ghi chú không thể lớn hơn 255 ký tự",
                    update_success: "Phiếu {code} được cập nhật thành công",
                    update_error: "Phiếu {code} cập nhật không thành công",
                    cancel_success: "Hủy phiếu thành công",
                    error_server: "Lỗi server"
                }
            },

            // Lấy sản phẩm khỏi vị trí
            ExportPositionIndex: {
                title: "Lấy sản phẩm khỏi vị trí",
                title_confirm: "Xác nhận",
                content_confirm: "Bạn có chắc chắn muốn hủy phiếu xuất kho {code} không ?",
                document_title: "Lấy sản phẩm khỏi vị trí | OmiPos",
                time: "Thời gian",
                status: "Trạng thái",
                code: "Mã phiếu xuất VT",
                created_at: "Thời gian tạo",
                created_by: "Người tạo",
                original_document: "Chứng từ gốc",
                note: "Ghi chú phiếu xuất vị trí",
                totalProducts: "Tổng số lượng",
                totalTypeProducts: "Tổng số mặt hàng",
                status_draft: "Phiếu tạm",
                status_confirm: "Đã xác nhận",
                placeholder: {
                    search: "Theo mã phiếu xuất vị trí",
                },
                tabs: {
                    info: "Thông tin",
                },
                button: {
                    save: "Lưu",
                    process: "Xử lý phiếu",
                    update: "Cập nhật",
                    print: "In",
                    cancel: "Hủy bỏ",
                },
                table: {
                    code: "Mã hàng",
                    name: "Tên hàng",
                    unit: "Đơn vị tính",
                    quantity: "Số lượng",
                    attribute:"Thuộc tính",
                    export_position: "Vị trí xuẩt"
                },
                alerts: {
                    validate_note: "Ghi chú không thể lớn hơn 255 ký tự",
                    update_success: "Phiếu {code} được cập nhật thành công",
                    update_error: "Phiếu {code} cập nhật không thành công",
                    cancel_success: "Hủy phiếu thành công",
                    error_server: "Lỗi server"
                }
            },
            ExportPositionProduct: {
                button: {
                    export: "Xuất file"
                }
            },

            // Điều chuyển vị trí
            TransferPositionIndex: {
                title: "Điều chuyển vị trí",
                title_confirm: "Xác nhận",
                content_confirm: "Bạn có chắc chắn muốn hủy phiếu chuyển vị trí {code} không ?",
                document_title: "Điều chuyển vị trí | OmiPos",
                time: "Thời gian",
                status: "Trạng thái",
                code: "Mã phiếu chuyển VT",
                created_at: "Thời gian tạo",
                created_by: "Người tạo",
                note: "Ghi chú phiếu chuyển",
                totalProducts: "Tổng số lượng",
                totalTypeProducts: "Tổng số mặt hàng",
                status_draft: "Phiếu tạm",
                status_confirm: "Đã xác nhận",
                placeholder: {
                    search: "Theo mã phiếu chuyển",
                },
                tabs: {
                    info: "Thông tin",
                },
                button: {
                    save: "Lưu",
                    open: "Mở phiếu",
                    create: "Thêm mới",
                    update: "Cập nhật",
                    print: "In",
                    cancel: "Hủy bỏ",
                    copy: "Sao chép"
                },
                table: {
                    code: "Mã hàng",
                    name: "Tên hàng",
                    unit: "Đơn vị tính",
                    quantity: "Số lượng",
                    from_location: "Vị trí chuyển đi",
                    attribute:"Thuộc tính",
                    to_location: "Vị trí chuyển đến"
                },
                alerts: {
                    validate_note: "Ghi chú không thể lớn hơn 255 ký tự",
                    update_success: "Phiếu {code} được cập nhật thành công",
                    update_error: "Phiếu {code} cập nhật không thành công",
                    cancel_success: "Hủy phiếu thành công",
                    error_server: "Lỗi server"
                }
            },
            print_template_convert: {
                ticket_import: {
                    title: "NHẬP VỊ TRÍ HÀNG HÓA"
                },
                ticket_export: {
                    title: "XUẤT VỊ TRÍ HÀNG HÓA"
                },
                ticket_transfer: {
                    title: "CHUYỂN VỊ TRÍ HÀNG HÓA"
                }
            },

            // Sổ quỹ tiền mặt
            CashBookIndex: {
                title: "Sổ quỹ tiền mặt",
                document_title: "Sổ quỹ - tiền mặt | OmiPos",
                filter: {
                    branch: 'Chi nhánh',
                    time: 'Thời gian',
                    document_type: 'Loại chứng từ',
                    payment_receipt_type: 'Loại thu chi',
                    status: 'Trạng thái',
                    created_by: 'Người tạo',
                    user: 'Nhân viên',
                    object_payment_receipt: 'Đối tượng nộp nhận',
                    placeholder: {
                        all: 'Tất cả',
                        user: 'Chọn nhân viên',
                        created_by: 'Chọn người tạo',
                        payment_receipt_type: 'Loại thu chi',
                        branch: 'Chọn chi nhánh',
                        name: 'Tên, mã người nộp/nhận',
                        phone: 'Điện thoại',
                    }
                },
                tooltip:{
                    totalAmountReserve: "Tồn quỹ = Quỹ đầu kỳ + Tổng thu - Tổng chi"
                },
                code: "Mã phiếu",
                earlyTermFund: "Quỹ đầu kỳ",
                totalAmountRevenue: "Tổng thu",
                totalAmountSpending: "Tổng chi",
                totalAmountReserve: "Tồn quỹ",
                created_at: "Thời gian tạo",
                value: "Giá trị",
                receipt_by: "Người nhận",
                payment_by: "Người nộp",
                phone_number: "Số điện thoại",
                address: "Địa chỉ",
                branch: "Chi nhánh",
                type_receipt_payment: "Loại thu chi",
                status: "Trạng thái",
                created_by: "Người tạo",
                user: "Nhân viên",
                object_payment: "Đối tượng nộp",
                object_receipt: "Đối tượng nhận",
                supplier: "Nhà cung cấp",
                customer: "Khách hàng",
                guest: "Khách lẻ",
                note: "Ghi chú",
                method: "Phương thức",
                cash: "Tiền mặt",
                transporter: "Đối tác giao hàng",
                tabs:{
                    info: "Thông tin",
                },
                button: {
                    add_receipt_ticket: "Lập phiếu thu",
                    add_payment_ticket: "Lập phiếu chi",
                    action: "Thao tác",
                    export: "Xuất file",
                    show: "Mở phiếu",
                    cancel: "Hủy phiếu",
                    print: "In",
                    save: "Lưu",
                    update: "Cập nhật"
                },
                placeholder:{
                    search: "Theo mã phiếu",
                },
                alerts: {
                    update_success: "Phiếu thu chi {code} được cập nhật thành công",
                    update_error: "Phiếu thu chi {code} cập nhật không thành công",
                    validate_error_note: "Ghi chú không thể lớn hơn 255 ký tự",
                },
                modal: {
                    showDetail: {
                        title_receipt: "Phiếu thu",
                        title_payment: "Phiếu chi",
                        code_receipt: "Mã phiếu thu",
                        code_payment: "Mã phiếu chi",
                        table: {
                            order_code: "Mã đặt hàng bán",
                            invoice_code: "Mã hóa đơn",
                            return_code: "Mã trả hàng bán",
                            order_supplier_code: "Mã đặt hàng nhập",
                            purchase_code: "Mã nhập hàng",
                            return_purchase_code: "Mã trả hàng nhập",
                            time: "Thời gian",
                            value: "Giá trị phiếu",
                            receipted: "Đã thu trước",
                            paymented: "Đã chi trước",
                            amount_receipt_payment: "Tiền thu/chi",
                            status: "Trạng thái",
                        }
                    }
                },
                toLink:{
                    invoice_receipt: "Phiếu thu tự động được tạo gắn với hóa đơn",
                    order_receipt: "Phiếu thu tự động được tạo gắn với phiếu đặt hàng bán",
                    return_receipt: "Phiếu thu tự động được tạo gắn với phiếu trả hàng",
                    order_for_supplier_receipt: "Phiếu thu tự động được tạo gắn với phiếu đặt hàng nhập",
                    import_for_supplier_receipt: "Phiếu thu tự động được tạo gắn với phiếu nhập hàng",
                    return_import_for_supplier_receipt: "Phiếu thu tự động được tạo gắn với phiếu trả hàng nhập",
                    delivery_receipt: "Phiếu thu tự động được tạo gắn với phiếu vận đơn",
                    invoice_payment: "Phiếu chi tự động được tạo gắn với hóa đơn",
                    order_payment: "Phiếu chi tự động được tạo gắn với phiếu đặt hàng bán",
                    return_payment: "Phiếu chi tự động được tạo gắn với phiếu trả hàng",
                    order_for_supplier_payment: "Phiếu chi tự động được tạo gắn với phiếu đặt hàng nhập",
                    import_for_supplier_payment: "Phiếu chi tự động được tạo gắn với phiếu nhập hàng",
                    return_import_for_supplier_payment: "Phiếu chi tự động được tạo gắn với phiếu trả hàng nhập",
                    delivery_payment: "Phiếu chi tự động được tạo gắn với phiếu vận đơn",
                }
            },
            modalCreateTicketForCash:{
                title_create_receipt: "Lập phiếu thu",
                title_update_receipt: "Cập nhật phiếu thu",
                title_create_payment: "Lập phiếu chi",
                title_update_payment: "Cập nhật phiếu chi",
                title_confirm_close: "Xác nhận",
                content_confirm_close_receipt: "Bạn chắc chắn hủy thêm mới phiếu thu?",
                content_confirm_close_update_receipt: "Bạn chắc chắn hủy cập nhật phiếu thu?",
                content_confirm_close_payment: "Bạn chắc chắn hủy thêm mới phiếu chi?",
                content_confirm_close_update_payment: "Bạn chắc chắn hủy cập nhật phiếu chi?",
                info_common: 'Thông tin chung',
                document: 'Chứng từ',
                objects: 'Đối tượng',
                amount: 'Số tiền',
                reason_receipt: 'Lý do nộp',
                reason_payment: 'Lý do chi',
                user_receipt: 'Nhân viên thu',
                user_payment: 'Nhân viên',
                note: 'Ghi chú',
                planning_day: 'Ngày hoạch toán',
                document_number: 'Số chứng từ',
                placeholder: {
                    amount_receipt: "Nhập số tiền thu",
                    amount_payment: "Nhập số tiền chi",
                    reason: "Nhập lý do",
                    note: "Nhập ghi chú",
                    document_number: "Nhập số chứng từ",
                    user: "Chọn nhân viên",
                    objects: "Chọn đối tượng",
                },
                button: {
                    save: "Lưu",
                    saveAndPrint: "Lưu & in",
                    skip: "Bỏ qua",
                    cancel: "Hủy bỏ",
                },
                alert: {
                    create_receipt_success: "Thêm mới phiếu thu thành công",
                    update_receipt_success: "Cập nhật phiếu thu thành công",
                    create_payment_success: "Thêm mới phiếu chi thành công",
                    update_payment_success: "Cập nhật phiếu chi thành công",
    
                    create_receipt_error: "Thêm mới phiếu thu thất bại",
                    update_receipt_error: "Cập nhật phiếu thu thất bại",
                    create_payment_error: "Thêm mới phiếu chi thất bại",
                    update_payment_error: "Cập nhật phiếu chi thất bại",
                }
            },
            modalCashCancel:{
                title: "Hủy phiếu",
                content: "Bạn chắc chắn muốn hủy phiếu {code} không?",
                btnSubmit: "Đồng ý",
                btnAbort: "Hủy bỏ",
                messageReceiveSuccess: "Phiếu thu {code} đã được hủy",
                messagePaymentSuccess: "Phiếu chi {code} đã được hủy",
                messageReceiveError: "Phiếu thu {code} hủy không thành công",
                messagePaymentError: "Phiếu thu {code} hủy không thành công"
            },

            // Sổ quỹ ngân hàng
            BankBookIndex: {
                title: "Sổ quỹ ngân hàng",
                document_title: "Sổ quỹ - ngân hàng | OmiPos",
                numberRecord: "Số bản ghi",
                note: "Ghi chú",
                totalAmountRevenue: "Tổng thu",
                supplier: "Nhà cung cấp",
                customer: "Khách hàng",
                transporter: "Đối tác giao hàng",
                filter: {
                    payment_type: "Loại thanh toán",
                    branch: "Chi nhánh",
                    time: "Thời gian",
                    created_by: "Người tạo",
                    user: 'Nhân viên',
                    object_payment_receive: 'Đối tượng nhận',
                    placeholder: {
                        all: 'Tất cả',
                        user: 'Chọn nhân viên',
                        created_by: 'Chọn người tạo',
                        payment_receipt_type: 'Loại thu chi',
                        branch: 'Chọn chi nhánh',
                        name: 'Tên, mã người nộp/nhận',
                        phone: 'Điện thoại',
                    }
                },
                placeholder:{
                    search: "Theo mã phiếu",
                },
                method: {
                    title: "Phương thức",
                    value: {
                        card: "Thẻ",
                        banking: "Chuyển khoản"
                    }
                },
                button: {
                    clearFilter: "Xóa lọc",
                    operation: "Thao tác",
                    receipts: "Lập phiếu thu",
                    export: "Xuất file",
                    save: "Lưu",
                },
                detail: {
                    code: "Mã phiếu",
                    time: "Thời gian tạo",
                    value: "Giá trị",
                    method: "Phương thức",
                    payer: "Người nộp",
                    phone: "Số điện thoại",
                    address: "Địa chỉ",
                    branch: "Chi nhánh",
                    created_by: "Người tạo",
                    user: "Nhân viên",
                    object_payer: "Đối tượng nộp"
                },
                alerts: {
                    update_success: "Phiếu thu {code} được cập nhật thành công",
                    update_error: "Phiếu thu {code} cập nhật không thành công",
                    validate_error_note: "Ghi chú không thể lớn hơn 255 ký tự",
                },
                toLink:{
                    invoice_receipt: "Phiếu thu tự động được tạo gắn với hóa đơn",
                    order_receipt: "Phiếu thu tự động được tạo gắn với phiếu đặt hàng bán",
                    return_import_for_supplier_receipt: "Phiếu thu tự động được tạo gắn với phiếu trả hàng nhập",
                    delivery_receipt: "Phiếu thu tự động được tạo gắn với phiếu vận đơn",
                }
            },
            modalBankBook: {
                title: "Lập phiếu thu:",
                titleContentLeft: "Thông tin chung:",
                titleContentRight: "Chứng từ:",
                applicableObject: "Đối tượng",
                amount: "Số tiền",
                reasonForPayment: "Lý do nộp:",
                employee: "Nhân viên thu",
                method: "Phương thức",
                note: "Ghi chú:",
                planningDay: "Ngày hạch toán:",
                documentNumber: "Số chứng từ:",
                planning: "Hạch toán vào kết quả kinh doanh",
                //placeholder
                placeholder: {
                    object: "Chọn đối tượng",
                    amount: "Nhập số tiền thu",
                    reason: "Lý do nộp",
                    employee: "Chọn nhân viên",
                    method: "Chọn phương thức nộp",
                    description: "Nhập ghi chú",
                    planningDay: "Nhập ngày hoạch toán",
                    documentNumber: "Nhập số chứng từ"
                }
            },

            // Vận đơn
            DeliveryIndex: {
                title: "Vận đơn",
                title_document: "Vận đơn | OmiPos",
                created_at: "Thời gian tạo",
                completed_at: "Thời gian hoàn thành",
                status: "Trạng thái giao hàng",
                transporter: "Đối tác giao hàng",
                area_delivery: "Khu vực giao hàng",
                cod: "Thu tiền hộ (COD)",
                receiver_info: "Thông tin người nhận",
                invoice_info: "Thông tin hóa đơn",
                delivery_info: "Thông tin vận chuyển",
                receiver_name: "Người nhận",
                receiver_phone: "Điện thoại",
                receiver_address: "Địa chỉ",
                receiver_area: "Khu vực",
                receiver_ward: "Phường xã",
                invoice_code: "Mã hóa đơn",
                branch: "Chi nhánh",
                customer_name: "Khách hàng",
                total_products: "Số lượng hàng",
                values: "Giá trị",
                note: "Ghi chú",
                delivery_code: "Mã vận đơn",
                created_by: "Người tạo",
                weight: "Trọng lượng",
                size: "Kích thước",
                delivery_by: "Người giao",
                service: "Dịch vụ",
                delivery_time: "Thời gian giao",
                cod_still: "Còn cần thu (COD)",
                price: "Phí trả ĐTGH",
                price_still: "Còn cần trả ĐTGH",
                column_status: "Trạng thái",
                control_code: "Mã đối soát",
                conversion_rate: "Tỉ lệ quy đổi",
                expected_delivery: "Dự kiến giao",
                price_declaration: "Khai giá",
                take_to_post_office: "Tự mang ra bưu cục",
                paying_by: "Bên trả phí",
                attention_when_delivering: "Lưu ý khi phát",
                discount_code: "Mã giảm giá",
                total_fare: "Tổng cước phí",
                receipt_paid_the_fee: "Người nhận trả phí",
                total_revenue_of_the_recipient: "Tổng thu người nhận",
                infoTitle: "Hiển thị danh sách những phiếu thanh toán\nTiền thu hộ, Phí qua Đối tác giao hàng",
                position: "Vị trí",
                infoUpdate: "Thông tin cập nhật",
                placeholder: {
                    search: "Theo mã vận đơn",
                    transporter: "Chọn người giao",
                    area_delivery: "Chọn Tỉnh/Thành phố - Quận huyện",
                    value: "Giá trị",
                },
                button: {
                    create: "Đối tác giao hàng",
                    import: "Import",
                    export: "Xuất file",
                    update: "Cập nhật",
                    in_active: "Ngừng hoạt động",
                    remove: "Xóa",
                    print: "In",
                    payment: "Thanh toán",
                    save: "Lưu",
                    support: "Gửi yêu cầu hỗ trợ",
                    cancel: "Hủy bỏ"
                },
                tabs: {
                    info: "Thông tin",
                    history: "Lịch sử giao hàng",
                    paymentTrans: "Lịch sử thanh toán ĐTGH"
                },
                modal: {
                    modalCancel: {
                        title: "Hủy vận đơn",
                        content: "Bạn có chắc chắn muốn hủy vận đơn này không?",
                        button: {
                            confirm: "Đồng ý",
                            skip: "Bỏ qua"
                        }
                    },
                    modelPaymentAndCreateRefund: {
                        title: "Thanh toán với đối tác giao hàng",
                        invoice_code: "Mã hóa đơn",
                        delivery_code: "Mã vận đơn",
                        transporter: "Đối tác giao hàng",
                        total_fee: "Tổng (COD - Phí trả ĐTGH)",
                        cod: "Tiền thu hộ (COD)",
                        fee_ship: "Phí trả ĐTGH",
                        time: "Thời gian",
                        method: "Phương thức",
                        button: {
                            type_receipt: 'Tạo phiếu thu',
                            type_payment: 'Tạo phiếu chi',
                            type_all: 'Tạo phiếu thu, chi',
                            skip: "Bỏ qua"
                        },
                        message_success: "Thanh toán cho hóa đơn {code} thành công",
                        message_create_multiple_success: "Thanh toán cho hóa đơn thành công",
                        message_error: "Thanh toán cho hóa đơn {code} thất bại",
                        message_validate_time_error: "Thời gian phiếu thanh toán vượt quá thời gian hiện tại.",
                        message_validate_created_at: "Thời gian phiếu thanh toán vượt quá thời gian hóa đơn {code}.",
                    }
                },
                alert: {
                    update_success: "Cập nhật dữ liệu thành công",
                    payment_error: "Vận đơn đã được thanh toán hoặc không phải thanh toán"
                }
            },

        /* Hết giao dịch */

        /* Báo cáo */
            // Báo cáo chi tiết bán hàng
            ReportSaleDetails: {
                title: "Bán hàng",
                title_document: "Báo cáo bán hàng | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                type_currency: "Loại tiền",
                invoice: "Số hóa đơn",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                    price_list: "Chọn bảng giá",
                    invoice: "Theo mã hóa đơn"
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Báo cáo bán hàng theo kênh bán
            ReportBySaleChannel: {
                title: "Bán hàng",
                title_document: "Báo cáo bán hàng | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Báo cáo bán hàng theo nhân viên
            SaleReportByEmployee: {
                title: "Bán hàng",
                title_document: "Báo cáo bán hàng | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Báo cáo bán hàng theo chi nhánh
            SaleReportByBranch: {
                title: "Bán hàng",
                title_document: "Báo cáo bán hàng | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Phân tích chi tiết danh thu theo thời gian
            ReportRevenuesTime: {
                title: 'Bán hàng',
                title_document: 'Phân tích doanh thu theo thời gian'
            },

            // Phân tích chi tiết danh thu theo chi nhánh
            ReportRevenuesBranch: {
                title: 'Bán hàng',
                title_document: 'Phân tích doanh thu theo chi nhánh'
            },

            // Phân tích chi tiết danh thu theo bảng giá
            SaleReportRevenuePriceList: {
                title: "Bán hàng",
                title_document: "Báo cáo bán hàng | OmiPos",
                time: "Thời gian",
                options: "Tùy chọn hiển thị",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                priceList: "Bảng giá",
                productUnit: "Đơn vị tính: Đơn vị tính chính",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Phân tích chi tiết danh thu theo nhóm khách hàng
            SaleReportRevenueCustomerGroup: {
                title: "Bán hàng",
                title_document: "Báo cáo bán hàng | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                productUnit: "Đơn vị tính: Đơn vị tính chính",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },                
                button: {
                    export: "Xuất file"
                },
                table: {
                    STT: "STT",
                    productCode: "Mã sản phẩm",
                    productName: "Tên sản phẩm",
                    productUnit: "ĐVT",
                    regionNorth: "MIỀN BẮC",
                    regionCenter: "MIỀN TRUNG",
                    regionSouth: "MIỀN NAM",
                    quantity: "Số lượng",
                    price: "Số tiền",
                    total: "Tổng"
                }
            },

            // Sổ mua hàng chi tiết
            ReportPurchaseDetails: {
                title: "Mua hàng",
                title_document: "Báo cáo mua hàng | OmiPos",
                time: "Thời gian",
                purchase_order: "Phiếu nhập hàng",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                supplier_group: "Nhóm nhà cung cấp",
                supplier: "Nhà cung cấp",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                type_currency: "Loại tiền",
                invoice: "Số hóa đơn",
                placeholder: {
                    purchase_order: "Theo số phiếu nhập",
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    supplier_group: "Tìm kiếm nhóm nhà cung cấp",
                    supplier: "Tìm kiếm nhà cung cấp",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                    price_list: "Chọn bảng giá",
                    invoice: "Theo mã hóa đơn"
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Sổ chi tiết hàng hóa
            ReportStockDetails: {
                title: "Hàng hóa",
                title_document: "Báo cáo hàng hóa | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                invoice: "Số hóa đơn",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                    price_list: "Chọn bảng giá",
                    invoice: "Theo mã hóa đơn"
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Báo cáo nhập xuất tồn
            ReportInventoriesIndex: {
                titleHeader: 'Báo cáo hàng hóa',
                title: 'Báo cáo nhập xuất tồn | OmiPos'
            },

            // Báo cáo nhập xuất tồn chi tiết
            ReportInventoriesIndexDetail: {
                titleHeader: 'Báo cáo hàng hóa',
                title: 'Báo cáo nhập xuất tồn chi tiết | OmiPos'
            },

            // Báo cáo giá trị kho
            ReportValueBranch: {
                title: "Hàng hóa",
                title_document: "Báo cáo bán hàng | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Báo cáo tồn kho theo hạn sử dụng
            SaleReportByConsignment: {
                title: "Hàng hóa",
                title_document: "Báo cáo hàng hóa | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Báo cáo tồn kho theo vị trí
            ReportByLocation: {
                title: "Hàng hóa",
                title_document: "Báo cáo hàng hóa | OmiPos",
                time: "Thời gian",
                product: "Hàng hóa",
                category: "Nhóm hàng",
                customer: "Khách hàng",
                customer_group: "Nhóm khách hàng",
                sale_channel: "Kênh bán",
                branch: "Chi nhánh",
                created_date: "Ngày lập",
                from_date: "Từ ngày",
                to_date: "đến ngày",
                price_list: "Bảng giá",
                placeholder: {
                    product: "Theo mã, tên hàng",
                    category: "Tìm kiếm nhóm hàng",
                    customer: "Theo mã, tên khách hàng",
                    customer_group: "Tìm kiếm nhóm khách hàng",
                    sale_channel: "Chọn kênh bán",
                    branch: "Chọn chi nhánh",
                },
                button: {
                    export: "Xuất file"
                }
            },

            // Báo cáo tồn kho theo lô date và vị trí

        /* Hết báo cáo */

        /* Thiết lập */
            // Thiết lập cửa hàng
            SettingPage: {
                title_left_top: 'Thiết lập quản lý',
                title_left_bot: 'Thiết lập cửa hàng',
                success: 'Thiết lập được cập nhật thành công',
                product: {
                    title: 'Hàng hóa',
                },
                partner: {
                    title: 'Đối tác',
                    modal: {
                        debt: {
                            title: 'Thiết lập cảnh báo công nợ khách hàng'
                        },
                        transporter: {
                            title: 'Kết nối Đối tác giao vận'
                        }
                    }
                    
                },
                transaction: {
                    title: 'Giao dịch',
                    modal: {
                        setting_time_return: {
                            title: 'Thiết lập thời gian trả hàng',
                            title_value_time_return: 'Trả hàng sau khi mua hàng',
                            date: 'ngày',
                            note_value_time_return: 'Hệ thống chỉ cho phép trả hàng trong vòng số ngày thiết lập kể từ ngày mua hàng',
                            process_option: 'Xử lý khi trả hàng quá hạn:',
                            note_warning: 'Nếu quá hạn trả hàng, hệ thống sẽ hiển thị cảnh báo nhưng vẫn cho phép người dùng trả hàng',
                            button_save: 'Lưu',
                            button_skip: 'Bỏ qua',
                        },
                    }
                },
                store: {
                    title: 'Thông tin cửa hàng'
                },
                security: {
                    title: 'Thiết lập bảo mật'
                },
                webhook: {
                    title: 'Đồng bộ Kiotviet'
                }
            },

            // Quản lý mẫu in
            

            // Quản lý chi nhánh
            branchIndex: {
                title: "Chi nhánh",
                branch_status: "Trạng thái",
                branch_name: "Tên chi nhánh",
                branch_phone: "Điện thoại",
                branch_email: "Email",
                branch_work_day: "Ngày làm việc",
                branch_address: "Địa chỉ",
                branch_area_delivery: "Khu vực giao hàng",
                branch_ward: "Phường xã",
                tabs:{
                    info: "Thông tin",
                    user: "Người dùng",
                    address_get_order: "Địa chỉ lấy hàng",
                },
                button: {
                    add: "Chi nhánh",
                    update: "Cập nhật",
                    inActive: "Ngừng hoạt động",
                    active: "Cho phép hoạt động",
                    remove: "Xóa",
                },
                status:{
                    all: "Tất cả",
                    active: "Đang hoạt động",
                    inActive: "Ngừng hoạt động",
                },
                placeholder:{
                    search: "Tên chi nhánh",
                    province: "Chọn tỉnh/thành phố",
                    district: "Chọn quận/huyện",
                    ward: "Chọn phường/xã",
                    address: "Nhập địa chỉ",
                    email: "Nhập email",
                    phone: "Nhập số điện thoại",
                    name: "Nhập tên chi nhánh",
                },
                modal: {
                    title_create: "Thêm mới chi nhánh",
                    title_update: "Cập nhật chi nhánh",
                    title_confirm: "Xác nhận",
                    branch_province: "Tỉnh/Thành phố",
                    branch_district: "Quận/Huyện",
                    branch_ward: "Phường/Xã",
                    button: {
                        save: "Lưu lại",
                        skip: "Bỏ qua",
                        confirm: "Đồng ý",
                    },
                    alerts: {
                        confirm_in_active: "Bạn có chắc chắn muốn ngừng hoạt động của chi nhánh này.",
                        confirm_active: "Bạn có muốn hoạt động trở lại chi nhánh này không ?",
                        success_create: "Thêm mới chi nhánh thành công",
                        success_update: "Cập nhật chi nhánh thành công",
                        confirm_skip_create: "Bạn có muốn hủy thêm chi nhánh?",
                        confirm_skip_update: "Bạn có muốn hủy cập nhật chi nhánh?",
                        success_remove: "Xóa chi nhánh {name} thành công"
                    },
                    title_remove: "Xác nhận",
                    content_remove: "Hệ thống sẽ xóa hoàn toàn chi nhánh này",
                }
            },

            // Quản lý khuyến mại

            // Quản lý coupon
            CouponIndex: {
                title: "Coupon",
                document_title: "Coupon | OmiPOS",
                status: "Trạng thái",
                code: "Mã đợt phát hành",
                time: "Thời gian",
                categories: "Nhóm hàng mua",
                group: "Nhóm",
                all_categories: "Tất cả nhóm hàng",
                product: "Hàng mua",
                name: "Tên chương trình",
                value: "Giá trị coupon",
                max: "Tối đa",
                min_total_price: "Tổng tiền hàng tối thiểu",
                note: "Ghi chú",
                placeholder: {
                    search: 'Theo mã, tên đợt phát hành',
                },
                button: {
                    add: "Thêm đợt phát hành mới",
                    update: "Cập nhật",
                    copy: "Sao chép",
                    remove: "Xóa",
                },
                tabs:{
                    info: "Thông tin",
                    list_coupon: "Danh sách coupon",
                    history: "Lịch sử sử dụng",
                },
                table: {
                    list_coupon: {
                        code: "Coupon code",
                        release_date: "Ngày phát hành",
                        use_date: "Ngày sử dụng",
                        number_coupon: "Số lượng coupon hiển thị trên trang",
                        placeholder: {
                            search: "Tìm kiếm mã coupon"
                        },
                        button: {
                            export: "Xuất file"
                        }
                    },
                    history: {
                        code: "Mã phiếu",
                        time: "Thời gian",
                        sold_by: "Người bán",
                        customer: "Khách hàng",
                        branch: "Chi nhánh",
                        revenue: "Doanh thu",
                        value_coupon: "Giá trị coupon",
                        placeholder: {
                            search: "Tìm kiếm mã coupon"
                        },
                        button: {
                            export: "Xuất file"
                        }
                    }
                },
                modal:{
                    create_or_update: {
                        title_create: "Thêm đợt phát hành coupon",
                        title_update: "Cập nhật đợt phát hành coupon",
                        code: "Mã đợt phát hành",
                        name: "Tên đợt phát hành",
                        value_coupon: "Giá trị coupon",
                        max: "Tối đa",
                        currency_vnd: "VNĐ",
                        currency_percent: "%",
                        effect: "Hiệu lực",
                        to: "Đến",
                        status: "Trạng thái",
                        status_active: "Kích hoạt",
                        status_inactive: "Chưa áp dụng",
                        categories: "Nhóm hàng mua",
                        choose_categories: "Chọn nhóm hàng mua",
                        group: "Nhóm",
                        all_categories: "Tất cả nhóm hàng",
                        products: "Hàng mua",
                        total_price: "Tổng tiền hàng",
                        tooltip: {
                            code: "Mã đợt phát hành là thông tin duy nhất",
                            categories: "Thiết lập điều kiện áp dụng coupon là hóa đơn phải mua hàng hoặc nhóm hàng cụ thể",
                            total_price: "Khi hóa đơn có tổng tiền hàng bằng tổng tiền tối thiểu này sẽ được thanh toán bằng coupon",
                        },
                        placeholder: {
                            code: "Mã tự động",
                            name: "Tên chương trình khuyến mại",
                            products: "Chọn hàng mua",
                            note: "Ghi chú",
                        },
                        tabs: {
                            info: "Thông tin",
                            list_coupon: "Danh sách coupon",
                        },
                        button:{
                            save: "Lưu",
                            skip: "Bỏ qua",
                            action: "Thao tác",
                            release_coupon: "Phát hành coupon",
                            cancel_coupon: "Hủy coupon",
                            add: "Danh sách coupon"
                        },
                        table: {
                            code: "Coupon code",
                            release_date: "Ngày phát hành",
                            use_date: "Ngày sử dụng",
                            status: "Trạng thái",
                            number_coupon: "Số lượng coupon hiển thị trên trang",
                            placeholder: {
                                search: "Tìm kiếm mã coupon",
                                choose_method: "Chọn hình thức"
                            },
                            button: {
                                export: "Xuất file"
                            }
                        },
                        alert: {
                            title_confirm: "Thêm đợt phát hành coupon",
                            content_confirm: "Bạn cần phải lưu đợt phát hành coupon trước khi tạo danh sách coupon. Bạn có muốn lưu không?",
                            create_list_coupon_success: "Thêm danh sách coupon thành công",
                            create_success: "Thêm mới đợt phát hành thành công",
                            update_success: "Cập nhật đợt phát hành thành công",
                        }
                    }
                }
            },

            // Quản lý voucher
            VoucherIndex: {
                title: "Voucher",
                document_title: "Voucher | OmiPOS",
                branch: "Chi nhánh",
                status: "Trạng thái",
                code: "Mã đợt phát hành",
                time: "Thời gian",
                categories: "Nhóm hàng mua",
                group: "Nhóm",
                all_categories: "Tất cả nhóm hàng",
                product: "Hàng mua",
                name: "Tên đợt phát hành",
                value: "Mệnh giá",
                min_total_price: "Tổng tiền hàng tối thiểu",
                note: "Ghi chú",
                placeholder: {
                    search: 'Theo mã, tên đợt phát hành',
                    branch: 'Chọn chi nhánh',
                },
                button: {
                    add: "Thêm đợt phát hành mới",
                    update: "Cập nhật",
                    copy: "Sao chép",
                    remove: "Xóa",
                },
                tabs:{
                    info: "Thông tin",
                    list_voucher: "Danh sách voucher",
                    history: "Lịch sử sử dụng",
                },
                table: {
                    list_voucher: {
                        code: "Voucher code",
                        release_date: "Ngày phát hành",
                        end_date: "Ngày hết hạn",
                        number_voucher: "Số lượng voucher hiển thị trên trang",
                        placeholder: {
                            search: "Tìm kiếm mã voucher"
                        },
                        button: {
                            export: "Xuất file"
                        }
                    },
                    history: {
                        code: "Mã phiếu",
                        time: "Thời gian",
                        sold_by: "Người bán",
                        customer: "Khách hàng",
                        branch: "Chi nhánh",
                        revenue: "Doanh thu",
                        value_voucher: "Giá trị voucher",
                        placeholder: {
                            search: "Tìm kiếm mã voucher"
                        },
                        button: {
                            export: "Xuất file"
                        }
                    }
                },
                modal:{
                    create_or_update: {
                        title_create: "Thêm đợt phát hành voucher",
                        title_update: "Cập nhật đợt phát hành voucher",
                        code: "Mã đợt phát hành",
                        name: "Tên đợt phát hành",
                        value_voucher: "Mệnh giá",
                        max: "Tối đa",
                        effect: "Hiệu lực từ",
                        to: "Đến",
                        status: "Tình trạng",
                        status_active: "Kích hoạt",
                        status_inactive: "Chưa áp dụng",
                        categories: "Nhóm hàng mua",
                        choose_categories: "Chọn nhóm hàng mua",
                        group: "Nhóm",
                        all_categories: "Tất cả nhóm hàng",
                        products: "Hàng mua",
                        total_price: "Tổng tiền hàng",
                        apply_multiple_voucher: "Áp dụng gộp nhiều voucher trên một hoá đơn",
                        apply_time: "Thời gian áp dụng",
                        apply_area: "Phạm vi áp dụng",
                        all_system: "Toàn hệ thống",
                        branch: "Chi nhánh",
                        all_customer: "Toàn bộ khách hàng",
                        customer_group: "Nhóm khách hàng",
                        all_created_by: "Toàn bộ người tạo",
                        created_by: "Người tạo",
                        tooltip: {
                            code: "Mã đợt phát hành là thông tin duy nhất",
                            value: "Là giá trị để khách hàng thanh toán khi mua hàng",
                            categories: "Hóa đơn có chứa các nhóm hàng hóa này sẽ được thanh toán bằng voucher",
                            products: "Hóa đơn có chứa các hàng hóa này sẽ được thanh toán bằng voucher",
                            total_price: "Tổng tiền hàng tối thiểu để có thể thanh toán bằng voucher",
                            apply_multiple_voucher: "Cho phép/Không cho phép sử dụng nhiều voucher để thanh toán một hoá đơn.",
                        },
                        placeholder: {
                            code: "Mã tự động",
                            name: "Tên đợt phát hành",
                            products: "Chọn hàng mua",
                            note: "Ghi chú",
                        },
                        tabs: {
                            info: "Thông tin",
                            apply: "Áp dụng",
                            list_voucher: "Danh sách voucher",
                        },
                        button:{
                            save: "Lưu",
                            skip: "Bỏ qua",
                            action: "Thao tác",
                            release_voucher: "Phát hành voucher",
                            cancel_voucher: "Hủy voucher",
                            add: "Danh sách voucher",
                            voucher: "Voucher",
                            import: "Import",
                        },
                        table: {
                            code: "voucher code",
                            release_date: "Ngày phát hành",
                            end_date: "Ngày hết hạn",
                            use_date: "Ngày sử dụng",
                            status: "Trạng thái",
                            number_voucher: "Số lượng voucher hiển thị trên trang",
                            placeholder: {
                                search: "Tìm kiếm mã voucher",
                                choose_method: "Chọn hình thức"
                            },
                            button: {
                                export: "Xuất file"
                            }
                        },
                        alert: {
                            title_confirm: "Thêm đợt phát hành voucher",
                            content_confirm: "Bạn cần phải lưu đợt phát hành voucher trước khi tạo danh sách voucher. Bạn có muốn lưu không?",
                            create_list_voucher_success: "Thêm danh sách voucher thành công",
                            create_success: "Thêm mới đợt phát hành thành công",
                            update_success: "Cập nhật đợt phát hành thành công",
                        }
                    },
                    create_list_voucher: {
                        title: "Thêm danh sách voucher",
                        number_voucher: "Số lượng voucher",
                        voucher_code_length: "Độ dài mã voucher",
                        first_character: "Ký tự bắt đầu",
                        last_character: "Ký tự kết thúc",
                        tooltip: {
                            number_voucher: "Số lượng voucher sẽ được thêm mới",
                            voucher_code_length: "Lưu ý độ dài mã voucher khi trừ đi kí tự bắt đầu và kí tự kết thúc phải lớn hơn hoặc bằng 6 để đảm bảo độ dài tối thiểu và tính duy nhất của voucher",
                            first_character: "Mã các voucher tạo ra sẽ bắt đầu bằng các ký tự này",
                            last_character: "Mã các voucher tạo ra sẽ kết thúc bằng các ký tự này"
                        },
                        button: {
                            save: "Lưu",
                            skip: "Bỏ qua",
                        },
                        alerts: {
                            validate_number_voucher: "Bạn chưa nhập số lượng voucher",
                            validate_voucher_code_length: "Độ dài mã voucher khi trừ đi kí tự bắt đầu và kí tự kết thúc phải lớn hơn hoặc bằng 6",
                            success: "Tạo mới mã voucher thành công"
                        }
                    },
                    create_voucher_code: {
                        title: "Thêm voucher",
                        voucher_code: "Mã voucher",
                        button: {
                            add: "Thêm voucher",
                            save: "Lưu",
                            skip: "Bỏ qua",
                        },
                        alerts: {
                            validate_voucher_code: "Mã voucher không được để trống",
                            validate_voucher_code_is_exist: "Mã voucher {code} đã tồn tại",
                            success: "Thêm mới voucher thành công"
                        }
                    },
                    remove: {
                        title: "Xác nhận",
                        content: "Hệ thống sẽ xóa hoàn toàn đợt phát hành voucher này, bạn có muốn thực hiện không?",
                        button: {
                            confirm: "Đồng ý",
                            skip: "Bỏ qua",
                        },
                        alerts:{
                            success: "Xóa đợt phát hành voucher thành công",
                            error: "Đã có voucher được phát hành hoặc đã sử dụng. Bạn không thể xóa."
                        }
                    }
                }
            },

            // Quản lý vai trò

            // Quản lý phân quyền

            // Lịch sử thao tác
            HistoryIndex: {
                title: "Lịch sử thao tác",
                user: "Nhân viên",
                function: "Chức năng",
                branch: "Chi nhánh",
                action: "Thao tác",
                time: "Thời gian",
                document_title: "Lịch sử thao tác | OmiPos",
                today: "Hôm nay",
                week: "Tuần này",
                month: "Tháng này",
                three_month_ago: "3 tháng qua",
                other: "Lựa chọn khác",
                from_date: "Từ ngày",
                to_date: "Đến ngày",
                placeholder: {
                    search: "Nhập nội dung",
                    branch: "Chọn chi nhánh",
                    user: "Chọn nhân viên",
                    function: "Chọn chức năng",
                },
                button: {
                    save: "Lưu",
                    process: "Xử lý phiếu",
                    update: "Cập nhật",
                    print: "In",
                    cancel: "Hủy bỏ",
                    export: "Xuất file",
                },
                tabs: {
                    content: "Nội dung chi tiết"
                }
            },

            // Quản lý mẫu email/zalo
            SendMailZaloIndex: {
                title: "Quản lý mẫu email/zalo",
                title_document: "Mẫu email/zalo | OmiPos",
                modal: {
                    title_send: "Chọn mẫu gửi email",
                    title_create: "Thêm mới mẫu email/zalo",
                    title_update: "Cập nhật mẫu email/zalo",
                    title_remove: "Xóa mẫu email/zalo",
                    remove_content: "Bạn có muốn xóa mẫu email/zalo này?",
                    template_name: "Tên mẫu email/zalo:",
                    file: "File đính kèm:",
                    placeholder: {
                        file: "Chọn file đính kèm"
                    },
                    read_before: "Xem trước mẫu email/zalo",
                    button: {
                        save: "Lưu",
                        skip: "Bỏ qua",
                        remove: "Xóa",
                        confirm: "Đồng ý",
                        send: "Gửi",
                    },
                    alerts:{
                        success_create: "Thêm mới mẫu email/zalo thành công",
                        success_update: "Cập nhật mẫu email/zalo thành công",
                        success_remove: "Xóa mẫu email/zalo thành công",
                        error_create: "Thêm mới mẫu email/zalo không thành công",
                        error_update: "Cập nhật mẫu email/zalo không thành công",
                        error_remove: "Xóa mẫu email/zalo không thành công",
                    }
                }
            },

            // Danh sách đối tác zalo
            zaloGroups: {
                name: "Nhóm zalo",
                templateTitle: "Mẫu zalo",
                zaloTitle: "Danh sách đối tác zalo",
                zalo: "Danh sách nhóm zalo",
                numberRecord: "Số bản ghi: ",
                logOut: "Đăng xuất",
                placeholder: "Theo tên nhóm "
            },
            ModalLoginZalo: {
                title: "ĐĂNG NHẬP ZALO OA",
                refresh_token: "Mã Refresh Token",
                app_id: "ID ứng dụng",
                app_secret: "Mã bí mật",
                webhook_secret: "Mã webhook",
                placeholder: {
                    refresh_token: "Nhập mã refresh token",
                    app_id: "Nhập ID ứng dụng",
                    app_secret: "Nhập mã bí mật từ quản trị viên",
                    webhook_secret: "Nhập mã webhook từ quản trị viên",
                },
                button: {
                    login: "Đăng nhập",
                    skip: "Bỏ qua"
                },
                message: {
                    login_success: "Đăng nhập thành công",
                    login_failed: "Đăng nhập thất bại",
                }
            },
            ModalLogoutZalo: {
                title: "Xác nhận",
                content: "Bạn chắc chắn muốn đăng xuất tài khoản Zalo OA khỏi hệ thống?",
                button: {
                    logout: "Đăng xuất",
                    skip: "Bỏ qua"
                },
                message: {
                    logout_success: "Đăng xuất thành công",
                    logout_failed: "Đăng xuất thất bại",
                }
            },
            ModalSendZalo: {
                title: 'Chọn mẫu gửi zalo'
    
            },


        /* Hết thiết lập */

        /* Modal */
            modalConfirm: {
                title: "Xác nhận",
                titleCancelOrderSupplier: "Hủy phiếu đặt hàng nhập",
                titleCancelOrder: "Hủy phiếu đặt hàng",
                titleCancelInvoice: "Hủy hóa đơn",
                titleCancelTicketPayment: "Hủy phiếu thanh toán",
                content: "Bạn có muốn hủy thay đổi?",
                contentCancelHistoryPaymentOrderSupplier: "Bạn có muốn hủy đặt cọc liên quan?",
                contentCancelTicketPayment: "Bạn có muốn hủy những phiếu thanh toán liên quan?",
                button: {
                    confirm: "Đồng ý",
                    skip: "Bỏ qua",
                },
            },
            modalPaymentSupplier: {
                title_order_for_supplier: "Thanh toán trước cho phiếu đặt hàng nhập {code}",
                title_import_for_supplier: "Thanh toán trước cho phiếu nhập hàng {code}",
                code: "Mã phiếu chi",
                created_at: "Thời gian",
                amount: "Tiền chi",
                method: "Phương thức",
            },
            modalCustomerPaid: {
                title_order: "Thanh toán trước cho đơn hàng {code}",
                code: "Mã phiếu thu",
                created_at: "Thời gian",
                amount: "Tiền thu",
                method: "Phương thức",
            },
            ModalConnectVTP: {
                title: "ĐĂNG NHẬP VIETTEL POST",
                username: "Tài khoản",
                password: "Mật khẩu",
                placeholder: {
                    username: "Tài khoản",
                    password: "Mật khẩu",
                },
                button: {
                    login: "Đăng nhập"
                }
            },
            ModalCreateUpdatePickup: {
                title: "Thêm mới địa chỉ lấy hàng",
                title_update: "Cập nhật địa chỉ lấy hàng",
                address: "Địa chỉ lấy hàng",
                province: "Tỉnh/Thành phố",
                district: "Quận/Huyện",
                ward: "Phường/Xã",
                phone: "Số điện thoại",
                placeholder_address: "Số nhà, tòa nhà, ngõ, đường",
                placeholder_province: "Chọn Tỉnh/Thành phố",
                placeholder_district: "Chọn Quận/Huyện",
                placeholder_ward: "Chọn Phường/Xã",
                placeholder_phone: "Nhập số điện thoại",
                message_success: "Tạo mới địa chỉ lấy hàng thành công",
                message_success_update: "Cập nhật địa chỉ lấy hàng thành công",
                confirm_title: "Xác nhận",
                confirm_content: "Bạn có chắc chắn muốn xóa địa chỉ lấy hàng không?",
                message_success_delete: "Xóa địa chỉ lấy hàng thành công?"
            },
            ModalCreateUpdateDelivery: {
                title: "Thêm địa chỉ nhận mới",
                title_update: "Cập nhật địa chỉ nhận",
                name_address: "Tên địa chỉ",
                name: "Tên người nhận",
                phone: "Số điện thoại",
                address_value: "Địa chỉ nhận",
                provinceId: "Tỉnh/Thành phố",
                district: "Quận/Huyện",
                ward: "Phường/Xã",
                message_success: "Tạo mới địa chỉ nhận hàng thành công",
                confirm_title: "Xác nhận",
                confirm_content: "Bạn có chắc chắn muốn xóa địa chỉ nhận hàng không?",
            },
            ModalFeeAdjustment: {
                title: "Điều chỉnh",
                current: "Nợ cần trả hiện tại",
                updatedAt: "Ngày điều chỉnh",
                adjustedValue: "Giá trị nợ điều chỉnh",
                description: "Ghi chú",
                createdBy: "Người tạo",
                cancel: "Hủy bỏ",
                update: "Cập nhật",
                content: "Bạn có chắc chắn muốn xóa điều chỉnh"
            },
            ModalcreateDelivery: {
                titleCreate: 'Tạo vận đơn khác',
                description: "Ghi chú",
            },
            
            ModalSelectOptionExport: {
                title: "Tùy chọn xuất file",
                content: "Tùy chọn trang (Tổng số {totalNumbers} bản ghi)",
                option: "Xuất file các bản ghi từ {from} - {to}",
                export: "Xuất file",
                skip: "Bỏ qua"
            },
            ModalConnectGHN: {
                title: "ĐĂNG NHẬP GIAO HÀNG NHANH",
                token: "Mã Token",
                IDShop: "ID Shop",
                name: "Tên Shop",
                placeholder: {
                    token: "Nhập mã token",
                    IDShop: "Nhập ID cửa hàng",
                    name: "Nhập tên cửa hàng",
                },
                button: {
                    login: "Đăng nhập"
                }
            },

        /* Hết modal */

        /* Bán hàng */
            orderFromTab: {
                message_create_address : "Thêm địa chỉ giao hàng để tra cước vận chuyển",
                service_type: "Loại dịch vụ",
                shipping_fee: "Phí giao hàng",
                delivery_code: "Mã vận đơn",
                delivery_time: "Thời gian giao hàng",
                delivery_status: "Trạng thái giao hàng",
                placeholder: {
                    delivery_code: "Mã vận đơn",
                }

            },

        /* Hết bán hàng */

            sendMail: {
                success: "Gửi mail thành công!",
                failing: "Gửi mail không thành công!"
            },

        /** Modal gọi hàng */
            ModalCreateTemporaryConfirm: {
                title: "Số lượng gọi hiện đang lớn hơn số lượng tồn tại chi nhánh",
                confirm: "Bạn có muốn Lưu tạm phiếu gọi hàng này không?"
            },

            /**
             * Modal import department
             */
            ModalImportDepartment: {
                title: "Nhập phòng ban từ file dữ liệu"
            }
        }
    }
};

export default messages;