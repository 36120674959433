<template>
    <div class='modal-dialog modal-md' :class="isShow ? 'd-block' : 'd-none'" ref="ModalExport" id='ModalExport'>
        <div class='modal-content'>
            <div class='modal-header border-bottom-0'>
                <h5 class='modal-title'>{{ $t('message.ModalSelectOptionExport.export') }}</h5>
                <button type='button' class='close' @click="hide">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class='modal-body pt-0'>
                <div class='row mb-3'>
                    <div class='col-lg-12 col-md-12 col-sm-12' v-if="!this.fileNameExport">
                        <i class="fas fa-spinner fa-spin"></i>
                        {{ $t('message.common.processingExportFile') }}
                    </div>
                    <div class='col-lg-12 col-md-12 col-sm-12' v-else>
                        <a v-if="isUploadDriver" target="_blank" class="text-primary text-decoration-none" :href="this.fileNameExport" @click="hide">
                            <i class="fa fa-hand-point-right"></i>
                            {{ $t('message.common.clickHere') }}
                        </a>
                        <a v-else href="javascript:void(0)" @click="download">
                            <i class="fa fa-download"></i>
                            {{ this.fileNameExport }}
                        </a>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-lg-12 col-md-12 col-sm-12'>
                        <ul class="error-label" v-if="errors && errors.length">
                            <li v-for="error in errors" :key="error" v-text="error"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emitter from "@/lib/emitter";
import http from '@/services/api';
export default {
    name: "ModalExport",
    props: ['title', 'content', 'button', 'errors'],
    data(){
        return {
            fileNameExport: null,
            isShow: false,
            isUploadDriver: false,
        }
    },
    methods: {
        hide(){
            this.isShow = false;
            this.fileNameExport = null;
            this.isUploadDriver = false;
        },
        show(){
            this.isShow = true;
        },
        async download(){
            if(this.fileNameExport){
                emitter.emit("onLoading");
                const res = await http.download('/v1/download-file', {fileName: this.fileNameExport});
                const data = res?.data ?? null;
                if(data){
                    var _blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(_blob);
                    link.setAttribute('download', this.fileNameExport);
                    link.click();
                    this.hide();
                }
                emitter.emit("offLoading");
            }
        },
    },
    watch: {
        fileNameExport: {
            handler: function(newData) {
                if(newData){
                    this.isShow = true;
                }
            },
            deep: true
        },
    },
    created(){
        emitter.on('showModalExport',() => {
            this.isShow = true;
            this.fileNameExport = null;
        });
        emitter.on('hideModalExport',() => {
            this.isShow = false
        });
        emitter.on('changeFileExport',(name) => {
            if(name){
                this.isShow = true;
            }
            this.fileNameExport = name ?? null
        });
        emitter.on('changeUploadDriver',() => {
            this.isUploadDriver = true;
        });
    },
    beforeUnmount(){
        this.isShow = false;
    },
    unmounted(){
        this.isShow = false;
    },

}
</script>

<style scoped lang="scss">
#ModalExport{
    position: fixed;
    bottom: 0px;
    right: 15px;
    min-width: 400px;
    z-index: 999;

    .error-label {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        color: red;
    }
    
    .error-input,
    .error-input:hover,
    .error-input:focus,
    .error-input:active {
        border: 1px solid red !important;
        outline: 0;
        box-shadow: unset;
    }
}
</style>
